import { memo, useEffect, useMemo, useState } from "react";
import React from "react";

// MUI Components
import {
  TextField,
  Box,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

// Icons
import { ExpandMore, SwapHoriz, HelpOutlineRounded } from "@mui/icons-material";
import MinMaxInput from "../customInputComponents/minMax";

const MinMaxFilterComponent = memo(
  ({ title, selector, tooltipInfo, setDataMethod, data, clearIt }) => {
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");

    useMemo(() => {
      if (data) {
        if (data.min || data.max) {
          setMinValue(data.min.toString());
          setMaxValue(data.max.toString());
        }
      }
      console.log("incoming data memo running");
    }, [data]);

    // const handleMinChange = (event) => {
    // const newMinValue = event.target.value;
    // if (newMinValue === '') {
    //     setMinValue('');
    // } else {
    //     const parsedValue = parseInt(newMinValue, 10);
    //     if (isNaN(parsedValue)) {
    //         setMinValue('');

    //     } else if (/^\d+$/.test(parsedValue)) {
    //         setMinValue(parsedValue);
    //     }
    // }
    // };

    // const handleMaxChange = (event) => {
    // const newMaxValue = event.target.value;
    // if (newMaxValue === '') {
    //     setMaxValue('');
    // } else {
    //     const parsedValue = parseInt(newMaxValue, 10);
    //     if (isNaN(parsedValue)) {
    //         setMaxValue('');
    //     } else if (/^\d+$/.test(parsedValue)) {
    //         setMaxValue(parsedValue);
    //     }
    // }
    // };

    useEffect(() => {
      console.log("minValue: ", typeof minValue, "maxValue: ", typeof maxValue);
      if (minValue !== "" || maxValue !== "") {
        setDataMethod({
          min: minValue.toString().replace(/'/g, '"'),
          max: maxValue.toString().replace(/'/g, '"'),
        });
      } else {
        setDataMethod(null);
      }
    }, [minValue, maxValue]);

    const clearMinMax = () => {
      setMinValue("");
      setMaxValue("");
    };

    const swapMinMax = () => {
      if (minValue !== "" || maxValue !== "") {
        setMinValue(maxValue);
        setMaxValue(minValue);
      }
    };

    useEffect(() => {
      clearIt(() => clearMinMax);
    }, [clearIt]);

    // useEffect(() => {
    //     if (minValue !== '' && maxValue !== '') {
    //         setDataMethod({ min: minValue, max: maxValue });
    //     } else {
    //         setDataMethod(null);
    //     }
    // }, [minValue, maxValue]);

    return (
      <Accordion sx={{ boxShadow: "none" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <IconButton style={{ color: "#3DBE72" }}>
              <Tooltip title={tooltipInfo}>
                <HelpOutlineRounded
                  style={{ fontSize: 14, padding: 0, margin: 0 }}
                />
              </Tooltip>
            </IconButton>
            <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0, textAlign: "start" }}>
          <MinMaxInput
            minValue={minValue}
            maxValue={maxValue}
            onMinChange={setMinValue}
            onMaxChange={setMaxValue}
            // Optional props
            minPlaceholder="min"
            maxPlaceholder="max"
            disabled={false}
            size="small"
            error={false}
            fullWidth={false}
          />
        </AccordionDetails>
      </Accordion>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default MinMaxFilterComponent;
