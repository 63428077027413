import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

const TotalSelectedInfluencers = ({ selectedRowArr }) => {
  const [data, setData] = React.useState([]);

  useEffect(() => {
    const influencerCount = selectedRowArr.length;

    const totalReach = selectedRowArr.reduce((acc, curr) => {
      return acc + parseInt(curr.subscriber, 10);
    }, 0);

    const totalER = selectedRowArr.reduce((acc, curr) => {
      return acc + curr.engagement_rate;
    }, 0);

    const headers = [
      { name: "Avg. Reach", value: (totalReach / influencerCount).toFixed(0) },
      { name: "Avg. ER", value: (totalER / influencerCount).toFixed(2) },
      { name: "Total Reach", value: totalReach },
      { name: "Total Eng.", value: totalER.toFixed(2) },
      { name: "Total", value: influencerCount },
    ];

    setData(headers);
  }, [selectedRowArr]);

  return (
    <Box
      sx={{
        display: "flex",
        padding: "0.7rem 1rem",
        gap: "1rem",
      }}
    >
      {data?.map((header, index) => (
        <Box key={index}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "light",
                color: "gray",
                fontSize: "0.9rem",
              }}
            >
              {header.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "0.9rem",
              }}
            >
              {header.value}
            </Typography>
          </Box>
          {/* <Box>{selectedRowArr.length}</Box> */}
        </Box>
      ))}
    </Box>
  );
};

export default TotalSelectedInfluencers;
