import * as React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import {
  BrowserRouter,
  Route,
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Box,
  Modal,
  Tooltip,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  MoveToInbox as InboxIcon,
  Mail as MailIcon,
  HomeRounded as HomeRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  LightbulbRounded as LightbulbRoundedIcon,
  GroupsRounded as GroupsRoundedIcon,
  TextSnippetRounded as TextSnippetRoundedIcon,
  ManageAccountsRounded as ManageAccountsRoundedIcon,
  SettingsRounded as SettingsRoundedIcon,
  PersonRounded as PersonRoundedIcon,
  ArrowBackIosNewRounded as ArrowBackIosNewRoundedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import Header from "./subcomponents/miniDrawer/Header";
import DrawerList from "./subcomponents/miniDrawer/DrawerList";
import MiniDrawerRoute from "./subcomponents/miniDrawer/MiniDrawerRoute";
import "../styles/navlink.module.css";
import { setIsAuthorized } from "../../features/authorization/AuthorizationSlice";
import { setIsOpen } from "../../features/drawerStatus/SharedStateSlice";
import { persistor } from "../../app/store";
import useLogout from "../../hooks/logoutHook";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#1F2933",
  color: "#FFFFFF",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#1F2933",
  color: "#FFFFFF",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: theme.spacing(0, 1),
  marginBottom: "1rem",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(["width", "margin"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
}));

// const isXs = useMediaQuery(theme.breakpoints.down('md'));

const Navigation = ({ navItems, open }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const logout = useLogout();

  const role = user?.user?.role;

  const isHomeActive =
    location.pathname === "/" || location.pathname === "/home";

  const handleNavbarClick = (event) => {
    event.preventDefault();
    const userPermission = window.confirm("Are you sure to Logout?");

    if (userPermission) {
      persistor
        .purge()
        .then((val) => {
          console.log("Purged", val);
          localStorage.removeItem("currUser");
          localStorage.removeItem("authToken");
          // logout();
          dispatch({ type: "auth/logout" });
          dispatch(setIsAuthorized(false));
        })
        .catch((err) => {
          console.log("Error in perging", err);
        });
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "75%",

        // experimental code
        alignItems: open && "start",
        paddingLeft: open && "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledNavLink
          to={navItems[0].path}
          exact={true}
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
            marginTop: open && "1rem",
          })}
        >
          <ListItemButton
            sx={
              {
                // background: 'yellow',
                // borderRadius: '10px',
              }
            }
          >
            <Tooltip
              title={navItems[0].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[0].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[0].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[1].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
            // marginTop: open && '1rem'
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[1].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[1].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[1].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[2].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[2].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[2].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[2].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[3].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[3].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[3].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[3].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        {role === "1" && (
          <StyledNavLink
            to={navItems[4].path}
            exact
            style={({ isActive }) => ({
              color: isActive ? "black" : "white",
              background: isActive && "white",
              borderRadius: open && "10px",
            })}
          >
            <ListItemButton>
              <Tooltip
                title={navItems[4].text}
                arrow
                placement="right"
                componentsProps={{
                  arrow: {
                    sx: {
                      color: "#3dbf73ff",
                    },
                  },
                  tooltip: {
                    sx: {
                      backgroundColor: "#3dbf73ff",
                      color: "white",
                    },
                  },
                }}
              >
                {navItems[4].icon}
              </Tooltip>
              {open && (
                <ListItemText
                  primary={navItems[4].text}
                  sx={{ paddingLeft: 2 }}
                />
              )}
            </ListItemButton>
          </StyledNavLink>
        )}

        <StyledNavLink
          to={navItems[5].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[5].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[5].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[5].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[6].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[6].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[6].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[6].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <StyledNavLink
          to={navItems[7].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[7].text}
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
              arrow
              placement="right"
            >
              {navItems[7].icon}
            </Tooltip>

            {open && (
              <ListItemText
                primary={navItems[7].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[9].path}
          exact
          onClick={handleNavbarClick}
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[9].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[9].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[9].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>

        <StyledNavLink
          to={navItems[8].path}
          exact
          style={({ isActive }) => ({
            color: isActive ? "black" : "white",
            background: isActive && "white",
            borderRadius: open && "10px",
          })}
        >
          <ListItemButton>
            <Tooltip
              title={navItems[8].text}
              arrow
              placement="right"
              componentsProps={{
                arrow: {
                  sx: {
                    color: "#3dbf73ff",
                  },
                },
                tooltip: {
                  sx: {
                    backgroundColor: "#3dbf73ff",
                    color: "white",
                  },
                },
              }}
            >
              {navItems[8].icon}
            </Tooltip>
            {open && (
              <ListItemText
                primary={navItems[8].text}
                sx={{ paddingLeft: 2 }}
              />
            )}
          </ListItemButton>
        </StyledNavLink>
      </Box>
    </Box>
  );
};

export default function MiniDrawer() {
  const isXsScreen = useMediaQuery("(max-width: 600px)"); // adjust size for XS

  const navItems = [
    { text: "Home", icon: <HomeRoundedIcon />, path: "/dashboard/home" },
    {
      text: "Find Influencer",
      icon: <PersonSearchRoundedIcon />,
      path: "/dashboard/find-influencers",
    },
    {
      text: "Collection",
      icon: <LightbulbRoundedIcon />,
      path: "/dashboard/collection",
    },
    {
      text: "Campaign",
      icon: <GroupsRoundedIcon />,
      path: "/dashboard/campaign",
    },
    {
      text: "Manage Brands",
      icon: <TextSnippetRoundedIcon />,
      path: "/dashboard/manage-brands",
    },
    {
      text: "Manage Influencers",
      icon: <ManageAccountsRoundedIcon />,
      path: "/dashboard/manage-influencers",
    },
    {
      text: "Manage Employees",
      icon: <ManageAccountsRoundedIcon />,
      path: "/dashboard/manage-employees",
    },
    {
      text: "Setting",
      icon: <SettingsRoundedIcon />,
      path: "/dashboard/setting",
    },
    {
      text: "Profile",
      icon: <PersonRoundedIcon />,
      path: "/dashboard/profile",
    },
    { text: "Logout", icon: <LogoutIcon />, path: "/login" },
  ];

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const isAuthorized = useSelector((state) => state.authorization);
  const [active, setActive] = React.useState(false);
  const navigate = useNavigate();

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);
  const dispatch = useDispatch();

  const handleDrawer = () => {
    setOpen((x) => (x === true ? false : true));
    dispatch(setIsOpen(isMiniOpen));
  };

  useEffect(() => {
    if (isXsScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isXsScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          position: isXsScreen && "absolute",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <DrawerHeader>
          {open && <Header />}
          <Tooltip title={open ? "Close" : "Open"} placement="right" arrow>
            {/* <IconButton onClick={handleDrawer} style={{ color: '#FFF', position : 'absolute', right : '-15px',background : 'green'  }} size='small'>
              {open ? <ArrowBackIosNewRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
            </IconButton> */}

            <IconButton
              onClick={handleDrawer}
              style={{ color: "#FFF" }}
              size="small"
            >
              {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
              {open ? (
                <ArrowBackIosNewRoundedIcon />
              ) : (
                <ArrowForwardIosRoundedIcon />
              )}
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        <Divider sx={{ background: "grey" }} />

        <Navigation navItems={navItems} open={open} set={setOpen} />
      </Drawer>

      <MiniDrawerRoute />
    </Box>
  );
}
