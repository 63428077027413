import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  ListItemIcon,
  MenuItem,
  styled,
  Button,
  InputAdornment,
  TextField,
  Chip,
  Tooltip,
  NativeSelect,
  Autocomplete,
  useMediaQuery,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
// import SelectComponent from './SelectComponent';
import SelectComponent from "./SelectComponent";
import FilterIcon from "../../../images/filter.png";
import { debounce } from "lodash";

import IG from "../../../images/insta-sm.png";
import youtubeIcon from "../../../images/youtube-sm.png";
import facebookIcon from "../../../images/facebook.png";
import SearchDropdownFilter from "./SearchDropdownFilter";
import usePermission from "../../../../hooks/permissionHooks";
import MinMaxFilterComponent from "../../filterComponents/minmax";
import MultiSelectWithAdd from "../../filterComponents/multiSelectWithTextfieldAdd";
import MulltiCheckbox from "../../filterComponents/multiCheckbox";
import MinMaxFrontFilter from "../../filterComponents/MinMaxFrontFilter";
import MultiSelectAddFilter from "../../filterComponents/MultiSelectAddFrontFilter";
import MultiSelectAddFrontFilter from "../../filterComponents/MultiSelectAddFrontFilter";
import MultiCheckboxFrontFilter from "../../filterComponents/MultiCheckboxFrontFilter";
import InputWithDropdownFrontFilter from "../../filterComponents/InputWithDropdownFrontFilter";

// const Container = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginBottom: '3rem',

// }))

// // CARD
// const SearchCard = styled(Grid)(({ theme }) => ({
//   width: '70%',
//   boxShadow: '0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40',
//   padding: 30,
//   borderRadius: 20,
//   [theme.breakpoints.down('sm')] : {
//     width: '100%',
//     padding: 10,
//   }

// }))

// const ShowBtn = styled(Button)(({ theme }) => ({
//   // padding : '8px 10px'
//   width: '100%',
//   padding: 10
// }))

// const AdvBtn = styled(Button)(({ theme }) => ({

//   textTransform: 'none',
//   fontSize: 15,
//   padding: '8px 25px',
//   borderRadius: 20,

// }))

// const [selectedOption, setSelectedOption] = React.useState('option1');

// const handleChange = (event) => {
//   setSelectedOption(event.target.value);
// };

// const [selectedIcon, setSelectedIcon] = useState('instagram');

// const handleChange = (event) => {
//   setSelectedIcon(event.target.value);
// };

// const renderValue = (selected) => {
//   // Customize the display of the selected item
//   return (
//     <ListItemIcon>
//       {selected === 'instagram' && <img src={IG} width={30} />}
//       {selected === 'drafts' && <img src={IG} width={30} />}
//       {selected === 'send' && <img src={IG} width={30} />}
//     </ListItemIcon>
//   );
// };

// const Container = styled(Box)(({ theme }) => ({
//   // background : '#9BD66A40',
//   // background: 'rgb(61,190,114)',
//   // background: 'radial-gradient(circle, rgba(61,190,114,1) 0%, rgba(255,255,255,1) 100%)',
//   // background: 'linear-gradient(355deg, rgba(61,190,114,1) 17%, rgba(255,255,255,1) 100%)',
//   backgroundImage: 'url("https://framerusercontent.com/images/lre0ZxRR1x4OKJNjYxP6s2SOaB8.png")', // Replace with the URL of your image
//   backgroundSize: 'cover', // You can adjust this based on your image size preference
//   backgroundPosition: 'center', // You can adjust this based on your image position preference
//   // Add other styles as needed
//   padding: 10,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: '300px'
// }))

const Container = styled(Box)(({ theme, sticky }) => ({
  position: sticky ? "sticky" : "relative",
  top: sticky ? "0px" : "auto",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "white",
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "300px",
  zIndex: 10,
}));
// useEffect(() => {
//   const handleScroll = () => {
//     if (window.scrollY < 200) {
//       setIsSticky(true);
//     } else {
//       setIsSticky(false);
//     }
//   };

//   window.addEventListener("scroll", handleScroll);
// }, []);

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginBottom: "1.5rem",
}));

const ChildContainer = styled(Box)(({ theme }) => ({
  width: "95%",
  boxShadow: "0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40",
  // boxShadow: ' 14px 200px 174px 123px rgba(61,190,114,0.43)',
  borderRadius: 20,
  padding: 10,
  // border: '3px solid #3DBE72',
  background: "white",
  margin: "1rem 1.5rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ShowResultBtn = styled(Button)(({ theme }) => ({
  background: "black",
  textTransform: "none",
  borderRadius: 30,
  fontSize: 12,
  // padding: "10px 20px",
  "&:hover": {
    background: "black",
  },
  boxShadow: "none",
}));

const ShowSizeResultBtn = styled(Button)(({ theme }) => ({
  background: "black",
  textTransform: "none",
  borderRadius: 30,
  fontSize: 5,
  paddingLeft: 0,
  paddingRight: 0,
  width: 20,
  "&:hover": {
    background: "black",
  },
  boxShadow: "none",
}));

const SearchField = styled(TextField)(({ theme }) => ({
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SizeSearchField = styled(TextField)(({ theme }) => ({
  width: "100px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const AdvFilterBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "#000000",
  fontWeight: "bold",
}));

const SelectSocial = styled(FormControl)(({ theme }) => ({
  background: "white",
  // marginLeft: "6rem",
  // marginBottom: "3rem",
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 80,
  padding: 0, // Adjust the padding value as needed
}));

const SearchBar = ({
  open,
  set,
  isMiniOpen,
  // setInfluencers,
  // influencers,
  // fetchMoreInfluencers,
  influencerData,
  setInfluencerData,
  selectedSocial,
  setSelectedSocial,
  locations,
  filterData,
  setFilterData,
  filterYTInfluencers,
  subcribersSize,
  setSubcribersSize,
  location,
  setLocation,
  age,
  setAge,
  gender,
  setGender,
  language,
  setLanguage,
  category,
  setCategory,
  genders,
  clearSubsSize,
  setClearSubsSize,
  clearLocation,
  setClearLocation,
  clearAge,
  setClearAge,
  clearGender,
  setClearGender,
  clearLanguage,
  setClearLanguage,
  clearCategory,
  setClearCategory,
  keywords,
  setKeywords,
  clearKeywords,
  setClearKeywords,
  essentialData,
}) => {
  const { keywordsList } = essentialData;

  const [socialMediaOptions, setSocialMediaOptions] = useState([]);

  const ytFilterAccess = usePermission(["findInfluencer/filter/yt"]);
  const igFilterAccess = usePermission(["findInfluencer/filter/ig"]);
  const searchBoxAccess = usePermission(["findInfluencer/searchbox"]);

  const [selectedValue, setSelectedValue] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [sticky, setSticky] = useState(false);

  const [filterAccess, setFilterAccess] = useState(false);

  // todo: useMemo for storing filterData
  const finalFilterData = useMemo(() => {
    return {
      sub_size: subcribersSize ? subcribersSize : {},
      location: location ? location : {},
      Age: age ? age : {},
      gender: gender ? gender : {},
      language: language ? language : {},
      Category: category ? category : {},
      Keywords: keywords ? keywords : {},
    };
  }, [subcribersSize, location, age, gender, language, category, keywords]);

  // todo: filter state h yahan aur filterDataState h jo filterData lega jo advance filter ka filter data h
  // const [filter, setFilter] = useState({});
  // const [filterDataState, setFilterDataState] = useState(filterData);

  useEffect(() => {
    // if (selectedSocial == "youtube") {
    //   setFilterAccess(ytFilterAccess);
    // } else if (selectedSocial == "instagram") {
    //   setFilterAccess(igFilterAccess);
    // }
    let socialMediaOptionsArr = [];
    if (ytFilterAccess) {
      setFilterAccess(true);
      socialMediaOptionsArr.push({
        value: "youtube",
        label: "youtube",
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/512px-YouTube_full-color_icon_%282017%29.svg.png?20240107144800",
      });
    } else {
      setFilterAccess(false);
    }
    if (igFilterAccess) {
      setFilterAccess(true);
      socialMediaOptionsArr.push({
        value: "instagram",
        label: "Instagram",
        icon: IG,
      });
    } else {
      setFilterAccess(false);
    }
    setSocialMediaOptions(socialMediaOptionsArr);
  }, [ytFilterAccess, igFilterAccess]);

  // todo: useEffect me filter aur filterData log kara rhe
  useEffect(() => {
    setSelectedValue(selectedSocial);
  }, [selectedSocial]);
  // const handleChangeAdv = (e) => {
  //   const { name, value } = e.target;
  //   if (filter[name] === undefined) {
  //     setFilter((prevFilter) => ({ ...prevFilter, [name]: [value] }));
  //   } else if (!filter[name].includes(value)) {
  //     setFilter((prevFilter) => ({
  //       ...prevFilter,
  //       [name]: [...prevFilter[name], value],
  //     }));
  //   }
  // };

  // const handleChangeAdv = (e) => {
  //   const { name, value } = e.target;

  //   console.log("handle change adv: ", name, value);

  //   setFilter((prevFilter) => {
  //     // Ensure 'name' exists in 'prevFilter', otherwise initialize it
  //     if (!prevFilter[name]) {
  //       return { ...prevFilter, [name]: [value] };
  //     }

  //     // If 'value' is not already in the array, add it
  //     if (!prevFilter[name].includes(value)) {
  //       return { ...prevFilter, [name]: [...prevFilter[name], value] };
  //     }

  //     // If 'value' is already present, return without changes
  //     return prevFilter;
  //   });

  //   setFilterData((prevFilter) => {
  //     // Ensure 'name' exists in 'prevFilter', otherwise initialize it
  //     if (!prevFilter[name]) {
  //       return { ...prevFilter, [name]: [value] };
  //     }

  //     // If 'value' is not already in the array, add it
  //     if (!prevFilter[name].includes(value)) {
  //       return { ...prevFilter, [name]: [...prevFilter[name], value] };
  //     }

  //     // If 'value' is already present, return without changes
  //     return prevFilter;
  //   });
  // };

  // todo: input onChange pe yeh chalta h
  const handleChangeAdv = (e) => {
    const { name, value } = e.target;
    console.log("handle change adv: ", name, value);

    // Update filter state
    // setFilter((prevFilter) => {
    //   const newFilter = { ...prevFilter };
    //   if (!newFilter[name]) {
    //     newFilter[name] = [value];
    //   } else if (!newFilter[name].includes(value)) {
    //     newFilter[name] = [...newFilter[name], value];
    //   }
    //   return newFilter;
    // });

    // Update filterData state
    setFilterData((prevFilterData) => {
      const newFilterData = { ...prevFilterData };
      if (!newFilterData[name]) {
        newFilterData[name] = [value];
      } else if (!newFilterData[name].includes(value)) {
        newFilterData[name] = [...newFilterData[name], value];
      }
      return newFilterData;
    });
  };

  // todo: clear front filters
  const handleClear = async () => {
    // setFilter({});
    // setFilterDataState({});
    clearSubsSize();
    clearLocation();
    clearAge();
    clearGender();
    clearLanguage();
    clearCategory();
    clearKeywords();
    setFilterData({});
    setSearchKey("");
    await filterYTInfluencers();
  };

  // useEffect(() => {
  //   console.log("influencer aagya", influencers)
  // }, [influencers]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedSocial(event.target.value);
  };

  const handleChange1 = (event) => {
    const newValue = event.target.alt;
    setSelectedValue(newValue);
    setSelectedSocial(newValue);
    console.log(newValue);
  };

  // const handleSearch = async (e) => {
  //   let lowerCaseRequest = e.target.value.toLowerCase();
  //   // return lowerCaseRequest==='' && setInfluencers(influencers);
  //   // if(lowerCaseRequest)

  //   setSearchKey(lowerCaseRequest);

  //   if (lowerCaseRequest !== "") {
  //     let filteredInfluencers = influencerData?.data?.filter((influencer) => {
  //       return (
  //         influencer?.name?.toLowerCase()?.includes(lowerCaseRequest) ||
  //         influencer?.handle?.toLowerCase()?.includes(lowerCaseRequest)
  //       );
  //     });
  //     console.log("filtered data: ", filteredInfluencers);

  //     setInfluencerData({ ...influencerData, data: filteredInfluencers });
  //   } else {
  //     setInfluencerData(influencerData);
  //   }

  //   console.log("influencers data: ", influencerData);
  // };

  // const handleDelete = (key, index) => {
  //   const updatedFilter = { ...filterData }; // Clone the filter state

  //   if (Array.isArray(updatedFilter[key])) {
  //     // Handle array case
  //     updatedFilter[key].splice(index, 1);
  //     if (updatedFilter[key].length === 0) {
  //       delete updatedFilter[key];
  //     }
  //   } else if (typeof updatedFilter[key] === "object") {
  //     // Handle object case for { min, max }
  //     if ("min" in updatedFilter[key] && "max" in updatedFilter[key]) {
  //       delete updatedFilter[key]; // Delete the entire object
  //     } else {
  //       // If there are other keys, handle them dynamically
  //       const subKeys = Object.keys(updatedFilter[key]);
  //       if (subKeys[index]) {
  //         delete updatedFilter[key][subKeys[index]];
  //       }
  //       if (Object.keys(updatedFilter[key]).length === 0) {
  //         delete updatedFilter[key];
  //       }
  //     }
  //   }

  //   setFilterData(updatedFilter); // Update the state
  // };

  // const handleDelete = (key, index) => {
  //   // Deep clone of filterData to ensure immutability
  //   const updatedFilter = JSON.parse(JSON.stringify(filterData));

  //   if (Array.isArray(updatedFilter[key])) {
  //     // If the value is an array, remove the specific index
  //     updatedFilter[key] = updatedFilter[key].filter((_, i) => i !== index);
  //   } else if (typeof updatedFilter[key] === "object") {
  //     // If the value is an object, handle it dynamically
  //     if ("min" in updatedFilter[key] && "max" in updatedFilter[key]) {
  //       // Clear the min and max values instead of deleting the object
  //       updatedFilter[key].min = null;
  //       updatedFilter[key].max = null;
  //     } else {
  //       // Handle other nested objects by removing specific key or clearing all values
  //       delete updatedFilter[key][index];
  //       Object.keys(updatedFilter[key]).forEach((nestedKey) => {
  //         updatedFilter[key][nestedKey] = null; // Clear values
  //       });
  //     }
  //   } else {
  //     // For other types (e.g., strings or numbers), clear the value
  //     updatedFilter[key] = null;
  //   }

  //   // Update state to trigger re-render
  //   setFilterData(updatedFilter);
  // };

  const [originalInfluencerData, setOriginalInfluencerData] =
    useState(influencerData);

  useEffect(() => {
    setOriginalInfluencerData(influencerData);
  }, [influencerData]);

  const handleSearch = async (e) => {
    const lowerCaseRequest = e.target.value.toLowerCase();
    setSearchKey(lowerCaseRequest);

    if (lowerCaseRequest !== "") {
      const filteredInfluencers = influencerData?.data?.filter((influencer) => {
        return (
          influencer?.name?.toLowerCase()?.includes(lowerCaseRequest) ||
          influencer?.handle?.toLowerCase()?.includes(lowerCaseRequest)
        );
      });
      console.log("Filtered data:", filteredInfluencers);

      setInfluencerData({ ...influencerData, data: filteredInfluencers });
    } else {
      setInfluencerData({ ...originalInfluencerData });
    }
  };

  useEffect(() => {
    console.log("Influencers data updated:", influencerData);
  }, [influencerData]);

  // const handleDelete = (key, index) => {
  //   switch (key) {
  //     case "Age":
  //       clearAge();
  //       break;
  //     case "sub_size":
  //       clearSubsSize();
  //       break;
  //     default:
  //       handleClear();
  //       break;
  //   }
  // };

  const handleDelete = (key, index) => {
    const updatedFilter = { ...filterData }; // Clone the filter state

    if (Array.isArray(updatedFilter[key])) {
      // Handle array case
      updatedFilter[key].splice(index, 1); // Remove the item at the specified index
      if (updatedFilter[key].length === 0) {
        delete updatedFilter[key]; // Delete the key if the array is empty
      }
    } else if (typeof updatedFilter[key] === "object") {
      if (
        "min" in updatedFilter[key] ||
        "max" in updatedFilter[key] ||
        "for" in updatedFilter[key] ||
        "in" in updatedFilter[key]
      ) {
        console.log(updatedFilter[key]);
        updatedFilter[key] = {};
        switch (updatedFilter[key]) {
          case "Age":
            clearAge();
            break;
          case "sub_size":
            clearSubsSize();
            break;
          default:
            handleClear();
            break;
        }
      } else {
        const subKeys = Object.keys(updatedFilter[key]);
        if (subKeys[index]) {
          delete updatedFilter[key][subKeys[index]]; // Delete the specific sub-key
        }
        if (Object.keys(updatedFilter[key]).length === 0) {
          delete updatedFilter[key]; // Delete the key if the object is empty
        }
      }
    }

    console.log("after deleting the filter: ", updatedFilter);
    setFilterData(updatedFilter); // Update the state
  };

  // Add a debug log for state updates
  useEffect(() => {
    console.log("Filter data updated:", filterData);
  }, [filterData, influencerData]);

  // const handleDelete1 = (key, index) => {
  //   // Create a deep copy of the filter data state to avoid mutation
  //   const updatedFilterData = JSON.parse(JSON.stringify(filterData));
  //   console.log("Before Deletion:", updatedFilterData);

  //   // Check if the key exists in the filter data
  //   if (updatedFilterData.hasOwnProperty(key)) {
  //     const target = updatedFilterData[key];

  //     // Handle deletion for an array type
  //     if (Array.isArray(target) && typeof index === "number") {
  //       target.splice(index, 1);

  //       // Remove the key if the array becomes empty
  //       if (target.length === 0) {
  //         delete updatedFilterData[key];
  //       }
  //     }
  //     // Handle deletion for an object type with nested properties min/max
  //     else if (typeof target === "object" && target !== null) {
  //       if (target.hasOwnProperty(index)) {
  //         delete target[index];

  //         // Remove the key if the object becomes empty after deletion
  //         if (Object.keys(target).length === 0) {
  //           delete updatedFilterData[key];
  //         }
  //       } else {
  //         console.error(`Index "${index}" not found under key "${key}".`);
  //       }
  //     }
  //     // Handle case when type is undefined, meaning delete the entire key
  //     else if (index === undefined) {
  //       delete updatedFilterData[key];
  //     } else {
  //       console.error(`Invalid index or key type for key "${key}".`);
  //     }
  //   } else {
  //     console.error(`Key "${key}" not found in filterDataState.`);
  //   }

  //   console.log("After Deletion:", updatedFilterData);
  //   // Update the filterData state with the modified data
  //   setFilterData(updatedFilterData);
  // };

  // const handleDeleteOptimized = (key, index) => {
  //   // Clone the finalFilterData to avoid mutation
  //   const updatedFilterData = JSON.parse(JSON.stringify(finalFilterData));

  //   console.log("Before Deletion:", updatedFilterData);

  //   // Check if the key exists in the filter data
  //   if (updatedFilterData.hasOwnProperty(key)) {
  //     const target = updatedFilterData[key];

  //     // Handle deletion for an array type
  //     if (Array.isArray(target) && typeof index === "number") {
  //       target.splice(index, 1);

  //       // Remove the key if the array becomes empty
  //       if (target.length === 0) {
  //         delete updatedFilterData[key];
  //       }
  //     }
  //     // Handle deletion for an object type with nested properties (min/max)
  //     else if (typeof target === "object" && target !== null) {
  //       if (target.hasOwnProperty(index)) {
  //         delete target[index];

  //         // Remove the key if the object becomes empty after deletion
  //         if (Object.keys(target).length === 0) {
  //           delete updatedFilterData[key];
  //         }
  //       } else {
  //         console.error(`Index "${index}" not found under key "${key}".`);
  //       }
  //     }
  //     // Handle case when index is undefined, meaning delete the entire key
  //     else if (index === undefined) {
  //       delete updatedFilterData[key];
  //     } else {
  //       console.error(`Invalid index or key type for key "${key}".`);
  //     }
  //   } else {
  //     console.error(`Key "${key}" not found in finalFilterData.`);
  //   }

  //   console.log("After Deletion:", updatedFilterData);

  //   // Update the finalFilterData state with the modified data
  //   setFinalFilterData(updatedFilterData);
  // };

  // const handleButtonClick = () => {
  //   // const results = influencers?.filter((item) => {
  //   //   return (
  //   //     item.name.toLowerCase().includes(searchKey)
  //   //     // || item.categories.toLowerCase().includes(searchKey) ||
  //   //     // item.followers.includes(lowerCaseRequest) ||
  //   //     // item.engagement.includes(lowerCaseRequest) ||
  //   //     // item.average.includes(lowerCaseRequest)
  //   //   );
  //   // });
  //   // setInfluencers(results);

  //   // console.log("final filter data: ", finalFilterData);

  //   // let finalFilterData = { ...filter, ...filterData };

  //   // console.log("Final filter: ", finalFilterData);

  //   let finalFilter = { ...filter, ...filterData };

  //   setFilterData(() => finalFilter);

  //   filterYTInfluencers();
  // };

  // todo: submit button search bar ka filterData set karta h

  const handleButtonClick = async () => {
    setFilterData(() => finalFilterData);
    console.log("final filter data:", finalFilterData);

    // Trigger filter function with the updated filter data
    await filterYTInfluencers();
  };

  const categories = [
    "Beauty",
    "Fashion",
    "Lifestyle",
    "Travel",
    "Food",
    "Gaming",
    "Sports",
    "Technology",
    "Business",
    "Entertainment",
  ];

  const languages = [
    "English",
    "Spanish",
    "Mandarin",
    "Arabic",
    "Hindi",
    "Bengali",
    "Portuguese",
    "Russian",
    "Japanese",
    "Punjabi",
    "German",
    "Javanese",
    "Wu",
    "French",
    "Telugu",
    "Vietnamese",
    "Marathi",
    "Korean",
    "Tamil",
    "Turkish",
    "Polish",
    "Thai",
    "Urdu",
    "Greek",
    "Italian",
    "Cantonese",
    "Swedish",
    "Romanian",
    "Dutch",
    "Hungarian",
    "Finnish",
    "Indonesian",
    "Norwegian",
    "Danish",
    "Czech",
    "Slovak",
    "Bulgarian",
    "Croatian",
    "Slovenian",
    "Lithuanian",
    "Latvian",
    "Estonian",
    "Hebrew",
    "Persian",
    "Afrikaans",
    "Albanian",
    "Amharic",
    "Armenian",
    "Azerbaijani",
    "Basque",
    "Belarusian",
    "Bosnian",
    "Catalan",
    "Cebuano",
    "Chichewa",
    "Corsican",
    "Welsh",
    "Yiddish",
    "Zulu",
    "Hmong",
    "Igbo",
    "Khmer",
    "Lao",
    "Malagasy",
    "Malay",
    "Mongolian",
    "Nepali",
    "Pashto",
    "Sinhala",
    "Somali",
    "Sundanese",
    "Tagalog",
    "Tajik",
    "Ukrainian",
    "Uzbek",
    "Yoruba",
  ];

  const ages = ["13-17", "18-24", "25-34", " 35-44", "45-54", "55-64", "65+"];

  const matches = useMediaQuery("(max-width:600px)"); // adjust the width as needed

  const socialMediaTabs = useMediaQuery("(max-width:768px)"); // adjust the width as needed

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          // margin: "0 auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        sticky={sticky}
      >
        <ChildContainer>
          {selectedSocial && (
            <Header>
              <AdvFilterBtn
                variant="text"
                onClick={() => set(true)}
                startIcon={<img src={FilterIcon} alt="Local Icon" />}
              >
                Advance Filters
              </AdvFilterBtn>
            </Header>
          )}
          <Grid container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "0 auto",
                height: "max-content",
                flexWrap: "wrap",
              }}
            >
              {filterAccess &&
                (socialMediaTabs ? (
                  <Box
                    sx={{
                      margin: "0 auto 20px auto",
                    }}
                  >
                    <Tabs
                      value={selectedValue}
                      onChange={handleChange1}
                      aria-label="Social Media Tabs"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#3dbe72",
                          height: "1px",
                        },
                      }}
                    >
                      {igFilterAccess && (
                        <Tab
                          key="instagram"
                          value="instagram"
                          icon={
                            <img
                              src={IG}
                              alt="instagram"
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                          }
                        />
                      )}
                      {ytFilterAccess && (
                        <Tab
                          key="youtube"
                          value="youtube"
                          icon={
                            <img
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/512px-YouTube_full-color_icon_%282017%29.svg.png?20240107144800"
                              }
                              alt="youtube"
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                          }
                        />
                      )}
                    </Tabs>
                  </Box>
                ) : (
                  <Box marginLeft={4} width={"5%"}>
                    <SelectSocial
                      sx={{
                        margin: "0 auto",
                        width: "100%",
                      }}
                    >
                      <Select
                        value={selectedValue}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          boxShadow:
                            "0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                      >
                        {socialMediaOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <img
                              src={option.icon}
                              alt={option.label}
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectSocial>
                  </Box>
                ))}
              <Box
                display={"flex"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
                width={"auto"}
                flexDirection={"column"}
                mx={"auto"}
                sx={{
                  marginTop: {
                    xs: "1rem",
                    sm: 0,
                  },
                }}
              >
                {searchBoxAccess && (
                  <SearchField
                    // label="Your Label"
                    // variant="outlined"
                    size="small"
                    outline="false"
                    value={searchKey}
                    placeholder="Search Influencers..."
                    onChange={handleSearch}
                    sx={{
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      mx: "auto",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                        padding: 1,
                        "&:hover fieldset": {
                          borderColor: "black", // Set the border color for hover state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black", // Set the border color for focused state
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ShowResultBtn
                            variant="contained"
                            onClick={handleButtonClick}
                            sx={{
                              padding: matches ? "1rem 0" : "10px 20px",
                              "& span": {
                                display: matches ? "none" : "inline",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              <SearchIcon
                                style={{
                                  fontSize: 15,
                                }}
                              />
                              <span>Show Result</span>
                            </Box>
                          </ShowResultBtn>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {searchBoxAccess && (
                  <Grid
                    item
                    size={12}
                    justifyContent={"center"}
                    alignContent={"center"}
                    align="center"
                    style={{ marginTop: 10 }}
                  >
                    {/* size dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-size">Size</InputLabel>
                      <Select
                        labelId="demo-select-small-size"
                        id="demo-select-size"
                        label="Size"
                        onChange={handleChangeAdv}
                        value=""
                        style={{ height: 40, borderRadius: 20 }}
                      >
                        <Box sx={{ m: 1 }}>
                          <em>
                            {selectedSocial === "instagram"
                              ? "Followers Size"
                              : "Subscribers Size"}
                          </em>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          <TextField
                            sx={{ width: "4rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filterData.sub_size?.min || ""}
                            onChange={(e) => {
                              const minSize = e.target.value;
                              setFilterData((prevFilter) => ({
                                ...prevFilter,
                                sub_size: {
                                  ...prevFilter.sub_size,
                                  min: minSize,
                                },
                              }));
                            }}
                          />
                          <TextField
                            sx={{ width: "4rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filterData.sub_size?.max || ""}
                            onChange={(e) => {
                              const maxSize = e.target.value;
                              setFilterData((prevFilter) => ({
                                ...prevFilter,
                                sub_size: {
                                  ...prevFilter.sub_size,
                                  max: maxSize,
                                },
                              }));
                            }}
                          />
                        </Box>
                      </Select>
                    </FormControl> */}

                    <MinMaxFrontFilter
                      title={"subscriber size"}
                      setDataMethod={setSubcribersSize}
                      data={subcribersSize}
                      clearIt={setClearSubsSize}
                    />

                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-size">Size</InputLabel>
                      <Select
                        labelId="demo-select-small-size"
                        id="demo-select-size"
                        label="Size"
                        value={selectedSocial} // Ensure value is bound to valid state
                        onChange={(e) => setSelectedSocial(e.target.value)} // Update state for dropdown
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <MenuItem value="instagram">
                          <em>Followers Size</em>
                        </MenuItem>
                        <MenuItem value="youtube">
                          <em>Subscribers Size</em>
                        </MenuItem>
                      </Select>

                      {selectedSocial && (
                        <Box sx={{ marginTop: "10px" }}>
                          <TextField
                            sx={{ width: "4rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.sizeMin || ""}
                            onChange={(e) => {
                              const minSize = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                sizeMin: minSize,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const minSize = e.target.value.trim();
                                if (minSize) {
                                  const maxSize = filter.sizeMax || "";
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    size: [
                                      ...(prevFilter.size || []),
                                      `${minSize}<=${maxSize}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                          <TextField
                            sx={{ width: "4rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.sizeMax || ""}
                            onChange={(e) => {
                              const maxSize = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                sizeMax: maxSize,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const maxSize = e.target.value.trim();
                                if (maxSize) {
                                  const minSize = filter.sizeMin || "";
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    size: [
                                      ...(prevFilter.size || []),
                                      `${minSize}<=${maxSize}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                        </Box>
                      )}
                    </FormControl> */}

                    {/* location dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Location
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="location"
                        value=""
                        name="location"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="Location"
                          onChange={(event, value) => {
                            if (value) {
                              const newLocation = value;
                              setFilterData((prevFilter) => {
                                const currentLocations = Array.isArray(
                                  prevFilter.location
                                )
                                  ? prevFilter.location
                                  : [];
                                return {
                                  ...prevFilter,
                                  location: [
                                    ...new Set([
                                      ...currentLocations,
                                      newLocation,
                                    ]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={locations}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl> */}

                    {/* <Box maxWidth={200}>
                      <MultiSelectWithAdd
                        selector={"location"}
                        title={"location"}
                        tooltipInfo={""}
                        key={"location"}
                        setDataMethod={setLocation}
                        data={location}
                        clearIt={() => {}}
                        label={"Select Locations"}
                        placeholder={"Enter Location name"}
                        predefinedOptions={locations}
                      />
                    </Box> */}
                    {locations && (
                      <MultiSelectAddFrontFilter
                        title={"location"}
                        label={"location"}
                        placeholder={"location"}
                        setDataMethod={setLocation}
                        data={location}
                        clearIt={setClearLocation}
                        predefinedOptions={locations}
                      />
                    )}

                    {/* age dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">Age</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="age"
                        value=""
                        name="age"
                        onChange={handleChangeAdv}
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: 100,
                        }}
                      >
                        <Box sx={{ m: 1 }}>
                          <em>Age</em>
                        </Box>
                        <Box sx={{ marginBottom: "10px" }}>
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.ageMin}
                            onChange={(e) => {
                              const minAge = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                ageMin: minAge,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const minAge = e.target.value.trim();
                                if (minAge) {
                                  const maxAge = filter.ageMax;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    age: [
                                      ...prevFilter.age,
                                      `${minAge}<=${maxAge}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.ageMax}
                            onChange={(e) => {
                              const maxAge = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                ageMax: maxAge,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const maxAge = e.target.value.trim();
                                if (maxAge) {
                                  const minAge = filter.ageMin;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    age: [
                                      ...prevFilter.age,
                                      `${minAge}<=${maxAge}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <Autocomplete
                            id="demo-select"
                            name="age"
                            onChange={(event, value) => {
                              if (value) {
                                const newAge = value;
                                setFilter((prevFilter) => {
                                  const currentAge = Array.isArray(
                                    prevFilter.age
                                  )
                                    ? prevFilter.age
                                    : [];
                                  return {
                                    ...prevFilter,
                                    age: [...new Set([...currentAge, newAge])],
                                  };
                                });
                              }
                            }}
                            style={{
                              height: 40,
                              borderRadius: 20,
                              width: "100%",
                              padding: 10,
                              marginBottom: 15,
                            }}
                            options={age}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search..."
                                sx={{ margin: "0 auto" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Select>
                    </FormControl> */}

                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">Age</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Age"
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: 100,
                        }}
                      >
                        <Box sx={{ m: 1 }}>
                          <em>Age</em>
                        </Box>
                        <Box sx={{ marginBottom: "10px" }}>
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic-min"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filterData.Age?.min || ""} // Ensure a fallback value
                            onChange={(e) => {
                              const minAge = e.target.value;
                              setFilterData((prevFilter) => ({
                                ...prevFilter,
                                Age: {
                                  ...prevFilter.Age,
                                  min: minAge,
                                },
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const minAge = e.target.value.trim();
                                if (minAge) {
                                  const maxAge = filterData.Age?.max || ""; // Get max value
                                  setFilterData((prevFilter) => ({
                                    ...prevFilter,
                                    Age: {
                                      ...prevFilter.Age,
                                      min: minAge,
                                      max: maxAge,
                                    },
                                  }));
                                }
                              }
                            }}
                          />
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic-max"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filterData.Age?.max || ""} // Ensure a fallback value
                            onChange={(e) => {
                              const maxAge = e.target.value;
                              setFilterData((prevFilter) => ({
                                ...prevFilter,
                                Age: {
                                  ...prevFilter.Age,
                                  max: maxAge,
                                },
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const maxAge = e.target.value.trim();
                                if (maxAge) {
                                  const minAge = filterData.Age?.min || ""; // Get min value
                                  setFilterData((prevFilter) => ({
                                    ...prevFilter,
                                    Age: {
                                      ...prevFilter.Age,
                                      min: minAge,
                                      max: maxAge,
                                    },
                                  }));
                                }
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <Autocomplete
                            id="demo-select"
                            name="age"
                            options={age}
                            getOptionLabel={(option) => option}
                            onChange={(event, value) => {
                              if (value) {
                                setFilterData((prevFilter) => {
                                  const currentAge = prevFilter.Age || {};
                                  return {
                                    ...prevFilter,
                                    Age: {
                                      ...currentAge,
                                      min: value.min || currentAge.min,
                                      max: value.max || currentAge.max,
                                    },
                                  };
                                });
                              }
                            }}
                            style={{
                              height: 40,
                              borderRadius: 20,
                              width: "100%",
                              padding: 10,
                              marginBottom: 15,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search..."
                                variant="outlined"
                                size="small"
                                sx={{ margin: "0 auto" }}
                              />
                            )}
                          />
                        </Box>
                      </Select>
                    </FormControl> */}

                    <MinMaxFrontFilter
                      title={"age"}
                      setDataMethod={setAge}
                      data={age}
                      clearIt={setClearAge}
                    />

                    {/* gender dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="gender"
                        label="gender"
                        value=""
                        onChange={handleChangeAdv}
                        style={{ height: 40, width: 100, borderRadius: 20 }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl> */}
                    {/* <Box maxWidth={200}>
                      <MulltiCheckbox
                        selector={"gender"}
                        title={"gender"}
                        tooltipInfo={""}
                        key={"gender"}
                        setDataMethod={setGender}
                        data={gender}
                        clearIt={() => {}}
                        label={"Select Gender"}
                        predefinedOptions={genders}
                      />
                    </Box> */}
                    <MultiCheckboxFrontFilter
                      title={"gender"}
                      label={"gender"}
                      setDataMethod={setGender}
                      data={gender}
                      clearIt={setClearGender}
                      predefinedOptions={genders}
                    />

                    {/* category dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="category"
                        value=""
                        name="category"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="category"
                          onChange={(event, value) => {
                            if (value) {
                              const newCategory = value;
                              setFilterData((prevFilter) => {
                                const currentCategory = Array.isArray(
                                  prevFilter.category
                                )
                                  ? prevFilter.category
                                  : [];
                                return {
                                  ...prevFilter,
                                  category: [
                                    ...new Set([
                                      ...currentCategory,
                                      newCategory,
                                    ]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={category}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl> */}
                    {/* <Box maxWidth={200}>
                      <MultiSelectWithAdd
                        selector={"Category"}
                        title={"category"}
                        tooltipInfo={""}
                        key={"Category"}
                        setDataMethod={setCategory}
                        data={category}
                        clearIt={() => {}}
                        label={"Select Category"}
                        placeholder={"Enter Category"}
                        predefinedOptions={categories}
                      />
                    </Box> */}
                    <MultiSelectAddFrontFilter
                      title={"category"}
                      label={"category"}
                      placeholder={"category"}
                      setDataMethod={setCategory}
                      data={category}
                      clearIt={setClearCategory}
                      predefinedOptions={categories}
                    />

                    {/* language dropdown filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Language
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="language"
                        value=""
                        name="language"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="language"
                          onChange={(event, value) => {
                            if (value) {
                              const newLanguage = value;
                              setFilterData((prevFilter) => {
                                const currentLanguage = Array.isArray(
                                  prevFilter.language
                                )
                                  ? prevFilter.language
                                  : [];
                                return {
                                  ...prevFilter,
                                  language: [
                                    ...new Set([
                                      ...currentLanguage,
                                      newLanguage,
                                    ]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={language}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl> */}

                    {/* <Box maxWidth={200}>
                      <MultiSelectWithAdd
                        selector={"language"}
                        title={"language"}
                        tooltipInfo={""}
                        key={"language"}
                        setDataMethod={setLanguage}
                        data={language}
                        clearIt={() => {}}
                        label={"Select Languages"}
                        placeholder={"Enter language"}
                        predefinedOptions={languages}
                      />
                    </Box> */}
                    <MultiSelectAddFrontFilter
                      title={"language"}
                      label={"language"}
                      placeholder={"language"}
                      setDataMethod={setLanguage}
                      data={language}
                      clearIt={setClearLanguage}
                      predefinedOptions={languages}
                    />

                    {/* keyword filter */}
                    {/* <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Keyword
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="keyword"
                        value=""
                        name="keyword"
                        onChange={handleChangeAdv}
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: 120,
                        }}
                      >
                        <TextField
                          label="Search..."
                          variant="outlined"
                          size="small"
                          placeholder="Search by Keywords"
                          value={searchKey}
                          sx={{ margin: "5px" }}
                          onChange={(e) => {
                            const keyword = e.target.value;
                            setSearchKey(keyword);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              const newKeyword = e.target.value.trim();
                              if (newKeyword) {
                                setFilterData((prevFilter) => {
                                  const currentKeywords = Array.isArray(
                                    prevFilter.keyword
                                  )
                                    ? prevFilter.keyword
                                    : [];
                                  return {
                                    ...prevFilter,
                                    keyword: [
                                      ...new Set([
                                        ...currentKeywords,
                                        newKeyword,
                                      ]),
                                    ], // Ensure unique values
                                  };
                                });
                                setSearchKey("");
                              }
                            }
                          }}
                        />
                      </Select>
                    </FormControl> */}
                    <InputWithDropdownFrontFilter
                      title={"keywords"}
                      label={"keywords"}
                      placeholder={"keywords"}
                      setDataMethod={setKeywords}
                      data={keywords}
                      clearIt={setClearKeywords}
                      predefinedOptions={keywordsList}
                      type={"text"}
                    />

                    {(Object.keys(filterData).length > 0 ||
                      searchKey !== "") && (
                      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                        <Tooltip title="Clear Filters">
                          <IconButton
                            size="small"
                            onClick={() => handleClear()}
                            sx={{ color: "red" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </FormControl>
                    )}
                  </Grid>
                )}
              </Box>
            </Box>
          </Grid>
          {/* {Object.keys(filter).map((key) => {
            const value = filter[key];

            // If it's an array, render as you were doing before
            if (Array.isArray(value)) {
              return value.map((v, index) => (
                <FormControl key={`${key}-${index}`}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    {key}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={v}
                    onChange={handleSearch}
                    autoWidth
                    label={key}
                    disabled
                    style={{
                      height: 40,
                      borderRadius: 20,
                      width: "max-content",
                      padding: "0px",
                      marginRight: 0,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <button
                          style={{
                            padding: 0,
                            height: 20,
                            marginRight: "15px",
                            backgroundColor: "white",
                            zIndex: 5,
                            border: "none",
                            borderRadius: 20,
                          }}
                          onClick={() => handleDelete(key, index)}
                        >
                          X
                        </button>
                      </InputAdornment>
                    }
                  >
                    <MenuItem
                      style={{ padding: "0px" }}
                      sx={{ padding: "0" }}
                      value={v}
                    >
                      {v}
                    </MenuItem>
                  </Select>
                </FormControl>
              ));
            }

            // If it's an object, render grouped key-value pairs
            if (typeof value === "object") {
              const combinedValue = `${value.min} <= ${value.max}`; // Combine min and max
              return (
                <FormControl key={key}>
                  {value.min && value.max && (
                    <>
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {key}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={combinedValue}
                        onChange={handleSearch}
                        autoWidth
                        label={key}
                        disabled
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: "max-content",
                          padding: "0px",
                          marginRight: 0,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <button
                              style={{
                                padding: 0,
                                height: 20,
                                marginRight: "15px",
                                backgroundColor: "white",
                                zIndex: 5,
                                border: "none",
                                borderRadius: 20,
                              }}
                              onClick={() => handleDelete(key)}
                            >
                              X
                            </button>
                          </InputAdornment>
                        }
                      >
                        (
                        <MenuItem
                          style={{ padding: "0px" }}
                          sx={{ padding: "0" }}
                          value={combinedValue}
                        >
                          {combinedValue}
                        </MenuItem>
                        )
                      </Select>
                    </>
                  )}
                </FormControl>
              );
            }

            return null;
          })} */}

          {/* {Object.keys(filter).map((key) => {
              const value = filter[key];

              if (Array.isArray(value)) {
                return value.map((v, index) => (
                  <FormControl key={`${key}-${index}`}>
                    <InputLabel>{key}</InputLabel>
                    <Select
                      value={v}
                      disabled
                      style={{
                        height: 40,
                        borderRadius: 20,
                        width: "max-content",
                        marginRight: 0,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <button
                            style={{
                              padding: 0,
                              height: 20,
                              marginRight: "15px",
                              backgroundColor: "white",
                              border: "none",
                              borderRadius: 20,
                            }}
                            onClick={() => handleDelete(key, index)}
                          >
                            X
                          </button>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={v}>{v}</MenuItem>
                    </Select>
                  </FormControl>
                ));
              }

              if (
                typeof value === "object" &&
                value.min !== undefined &&
                value.max !== undefined
              ) {
                const combinedValue = `${value.min} <= ${value.max}`;
                return (
                  <FormControl key={key}>
                    <InputLabel>{key}</InputLabel>
                    <Select
                      value={combinedValue}
                      disabled
                      style={{
                        height: 40,
                        borderRadius: 20,
                        width: "max-content",
                        marginRight: 0,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <button
                            style={{
                              padding: 0,
                              height: 20,
                              marginRight: "15px",
                              backgroundColor: "white",
                              border: "none",
                              borderRadius: 20,
                            }}
                            onClick={() => handleDelete(key)}
                          >
                            X
                          </button>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={combinedValue}>{combinedValue}</MenuItem>
                    </Select>
                  </FormControl>
                );
              }

              return null;
            })} */}

          {/* {Object.keys(finalFilterData).map((key) => {
              console.log("final filter: ", finalFilterData);
              const value = finalFilterData[key];

              if (Array.isArray(value)) {
                return value.map((v, index) => (
                  <FormControl key={`${key}-${index}`}>
                    <InputLabel>{key}</InputLabel>
                    <Select
                      value={v}
                      disabled
                      style={{
                        height: 40,
                        borderRadius: 20,
                        width: "max-content",
                        marginRight: 0,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <button
                            style={{
                              padding: 0,
                              height: 20,
                              marginRight: "15px",
                              backgroundColor: "white",
                              border: "none",
                              borderRadius: 20,
                            }}
                            onClick={() => handleDelete(key, index)}
                          >
                            X
                          </button>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={v}>{v}</MenuItem>
                    </Select>
                  </FormControl>
                ));
              }

              if (
                typeof value === "object" &&
                value.min !== undefined &&
                value.max !== undefined
              ) {
                const combinedValue = `${value.min} <= ${value.max}`;
                return (
                  <FormControl key={key}>
                    <InputLabel>{key}</InputLabel>
                    <Select
                      value={combinedValue}
                      disabled
                      style={{
                        height: 40,
                        borderRadius: 20,
                        width: "max-content",
                        marginRight: 0,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <button
                            style={{
                              padding: 0,
                              height: 20,
                              marginRight: "15px",
                              backgroundColor: "white",
                              border: "none",
                              borderRadius: 20,
                            }}
                            onClick={() => handleDelete(key)}
                          >
                            X
                          </button>
                        </InputAdornment>
                      }
                    >
                      <MenuItem value={combinedValue}>{combinedValue}</MenuItem>
                    </Select>
                  </FormControl>
                );
              }

              return null;
            })} */}

          {Object.keys(filterData).length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                flexWrap: "wrap",
                height: "max-content",
              }}
              item
              md={12}
              align="center"
              margin="1rem 0rem"
            >
              {Object.keys(filterData).map((key) => {
                const value = filterData[key];

                {
                  /* if (Array.isArray(value)) {
                  return value.map((v, index) => (
                    <FormControl key={`${key}-${index}`}>
                      <InputLabel id={`label-${key}-${index}`}>
                        {key}
                      </InputLabel>
                      <Select
                        labelId={`label-${key}-${index}`}
                        id={`select-${key}-${index}`}
                        value={v}
                        disabled
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: "max-content",
                          padding: "0px",
                          marginRight: 0,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <button
                              style={{
                                padding: 0,
                                height: 20,
                                marginRight: "15px",
                                backgroundColor: "white",
                                zIndex: 5,
                                border: "none",
                                borderRadius: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(key, index)}
                            >
                              X
                            </button>
                          </InputAdornment>
                        }
                      >
                        <MenuItem value={v}>{v}</MenuItem>
                      </Select>
                    </FormControl>
                  ));
                } */
                }

                if (Array.isArray(value)) {
                  return value.map((v, index) => (
                    <FormControl key={`${key}-${index}`}>
                      <InputLabel id={`label-${key}-${index}`}>
                        {key}
                      </InputLabel>
                      <Select
                        labelId={`label-${key}-${index}`}
                        id={`select-${key}-${index}`}
                        value={v}
                        disabled
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: "max-content",
                          padding: "0px",
                          marginRight: 0,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <button
                              style={{
                                padding: 0,
                                height: 20,
                                marginRight: "15px",
                                backgroundColor: "white",
                                zIndex: 5,
                                border: "none",
                                borderRadius: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(key, index)} // Pass the index here
                            >
                              X
                            </button>
                          </InputAdornment>
                        }
                      >
                        <MenuItem value={v}>{v}</MenuItem>
                      </Select>
                    </FormControl>
                  ));
                }

                if (
                  typeof value === "object" &&
                  (value.min !== undefined ||
                    value.max !== undefined ||
                    value.for !== undefined ||
                    value.in !== undefined)
                ) {
                  // Construct the combinedValue based on the available properties
                  const combinedValue = [
                    value.min !== undefined && value.max !== undefined
                      ? `${value.min} <= ${value.max}`
                      : "",
                    value.for !== undefined && value.in !== undefined
                      ? `${value.for} ${value.in}`
                      : "",
                    value.for !== undefined &&
                    value.min !== undefined &&
                    value.max !== undefined
                      ? `(${value.for})`
                      : "",
                    value.in !== undefined &&
                    value.min !== undefined &&
                    value.max !== undefined
                      ? `(${value.in})`
                      : "",
                    value.in !== undefined &&
                    value.max !== undefined &&
                    value.min !== undefined &&
                    value.for !== undefined
                      ? `${value.min} <= ${value.in} (${value.for} ${value.in})`
                      : "",
                  ]
                    .filter(Boolean)
                    .join(" ");

                  return (
                    <FormControl key={key}>
                      <InputLabel>{key}</InputLabel>
                      <Select
                        value={combinedValue}
                        disabled
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: "max-content",
                          marginRight: 0,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <button
                              style={{
                                padding: 0,
                                height: 20,
                                marginRight: "15px",
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(key)}
                            >
                              X
                            </button>
                          </InputAdornment>
                        }
                      >
                        <MenuItem value={combinedValue}>
                          {combinedValue}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  );
                }

                return null;
              })}
            </Box>
          )}
        </ChildContainer>
      </Box>
    </>
  );
};

export default SearchBar;
