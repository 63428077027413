import React, { useRef, useEffect, useState, useCallback } from "react";

import DataTable from "react-data-table-component";
import html2canvas from "html2canvas";
import Grid from "@mui/material/Grid2";
import {
  Tooltip,
  Menu,
  MenuItem,
  styled,
  IconButton,
  Box,
  AvatarGroup,
  Avatar,
  Typography,
  Button,
  Divider,
  Dialog,
  Drawer,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  ListItem,
  CircularProgress,
  Checkbox,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  FormControlLabel,
  ListItemText,
  Input,
  List,
  ListItemButton,
} from "@mui/material";

import {
  CancelOutlined,
  MoreHoriz as MoreHorizIcon,
  Share as ShareIcon,
  GetApp as GetAppIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Campaign as CampaignIcon,
  PersonAdd as PersonAddIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
  ViewListRounded,
  GridViewRounded,
  InfoOutlined,
  ShareOutlined,
  FileDownloadOutlined,
  Add,
  DragIndicator,
  WarningAmberOutlined,
  AddTaskOutlined,
  Search,
  ArrowDownwardSharp,
  ArrowUpward,
  SubdirectoryArrowLeft,
  Delete,
} from "@mui/icons-material";

import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

import CollectionDrawer from "./CollectionDrawer.jsx";

import { collections } from "./collectionData.js";

import CSVicon from "../../images/csv.png";
import PDFicon from "../../images/pdf.png";
import SBicon from "../../images/sb.png";
import LISTicon from "../../images/lst.png";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

// APIs
import {
  getAllCollection,
  getAllCollectionCount,
} from "../../../service/collections api/getAllCollection.js";

import { getBrandByID } from "../../../service/brands/getBrandByID.js";
import { getAllBrands } from "../../../service/brands/getAllBrands.js";
import ShareCollection from "./ShareCollection.jsx";
import { getCollectionById } from "../../../service/collections api/getCollectionById.js";
import AddNewBrand from "./AddNewBrand.jsx";
import { updateCollection } from "../../../service/collections api/createCollection.js";
import {
  AssignCampaign,
  getAllCampaign,
} from "../../../service/campaign api/getAllCampaign.js";
import {
  addNewColumn,
  addNewColumnData,
  getColumnHead,
  updateColumnHead,
} from "../../../service/api.js";
import axios from "axios";
import { useSelector } from "react-redux";
import CSVExportButton from "../subcomponents/findInfluencer/CSVExportButton .jsx";
import jsPDF from "jspdf";

const avt1 =
  "https://as2.ftcdn.net/v2/jpg/00/69/02/09/1000_F_69020919_N3EY9n6qObMNpzZzIDX059rGpx0SYq8m.jpg";
const avt2 =
  "https://as2.ftcdn.net/v2/jpg/00/69/02/09/1000_F_69020919_N3EY9n6qObMNpzZzIDX059rGpx0SYq8m.jpg";
const avt3 =
  "https://img.freepik.com/free-photo/beautiful-woman-with-trendy-pink-sunglasses_273609-4532.jpg?size=626&ext=jpg&ga=GA1.1.1803636316.1701216000&semt=ais";

const customStyles = {
  table: {
    style: {
      height: "",
    },
  },
  tableWrapper: {
    style: {
      display: "block",
    },
  },
  responsiveWrapper: {
    style: {},
  },
  head: {
    style: {
      fontSize: "13px", // Set the desired font size for the header
    },
  },
};

const findBackgroundColor = (status) => {
  switch (status) {
    case "Ended":
      return "red";
    case "Archived":
      return "#B3C8CF";
    case "Active":
      return "#3DBF73";
    case "Draft":
      return "#453F78";
  }
};

const CustomName = ({ row }) => <>{row.name}</>;

const CustomProfile = ({ row, handleRowClick }) => (
  <>
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{ pointerEvents: "none", cursor: "pointer" }}
      onClick={() => handleRowClick(row)}
    >
      <AvatarGroup>
        <Avatar
          alt="Remy Sharp"
          src={avt3}
          sx={{ position: "relative", bottom: "-10px" }}
        />
        <Box display={"flex"} flexDirection={"column"}>
          <Avatar
            alt="Travis Howard"
            src={avt2}
            sx={{
              width: 28,
              height: 28,
              position: "relative",
              left: "-5px",
              bottom: "-5px",
            }}
          />
          <Avatar
            alt="Agnes Walker"
            src={avt1}
            sx={{
              width: 28,
              height: 28,
              position: "relative",
              left: "-15px",
              top: "-5px",
            }}
          />
        </Box>
        {/* <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" /> */}
      </AvatarGroup>
      {/* <span>{row.name.toUpperCase()}</span> */}
    </Box>
  </>
);

const handleAddColumnData = async ({
  newColumnValue,
  columnName,
  columnId,
  rowId,
  currUser,
  setSaveState,
}) => {
  try {
    const data = {
      name: columnName,
      value: newColumnValue,
      for_table: "Collection",
      for_id: JSON.stringify(rowId),
      column_id: JSON.stringify(columnId),
      status: "active",
      created_by: JSON.stringify(currUser),
    };

    const response = await addNewColumnData(data);
    if (response) {
      setSaveState(true);
    }
  } catch (e) {
    console.log(e);
  }
};

const CustomNewColumn = ({ row, columnName, columnId, currUser }) => {
  const [newColumnValue, setNewColumnValue] = useState("");
  const [saveState, setSaveState] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Input
        onChange={(e) => {
          setNewColumnValue(e.target.value);
        }}
        placeholder="Search"
      />
      {newColumnValue === "" ? null : (
        <>
          {saveState ? (
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "10px",
                padding: "5px !important",
                minWidth: "45px",
                marginLeft: "5px",
                backgroundColor: "#3DBF73",
              }}
            >
              Saved
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{
                fontSize: "10px",
                padding: "5px !important",
                minWidth: "35px",
                marginLeft: "5px",
              }}
              onClick={() => {
                handleAddColumnData({
                  newColumnValue,
                  columnName,
                  columnId,
                  rowId: row.id,
                  currUser,
                  setSaveState,
                });
              }}
            >
              Save
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

const CustomAverage = ({ row }) => {
  const status = row ? row.status : "Archived";

  return (
    <span
      style={{
        background: findBackgroundColor(status),
        padding: "4px 6px",
        borderRadius: 10,
        width: "100%",
        textAlign: "center",
      }}
    >
      {status}
    </span>
  );
};

const CustomNoOfCollection = ({ row }) => {
  const [count, setCount] = useState("");

  const fetchCollection = async (rowid) => {
    console.log(rowid);
    try {
      const res = await getCollectionById(rowid);
      console.log("API Collection ID ", rowid);
      // console.log("====================================");
      console.log("API Call ho gai collection table ki ", res);
      // console.log("====================================");
      let parsedData = res.data.collection[0]; // Parse the response (assuming it's JSON)

      const photos = parsedData.influencers.map(
        ({ influencer_data }) => influencer_data
      );

      parsedData = parsedData.influencers.filter(
        (influencer, index, self) =>
          index ===
          self.findIndex((t) => t.influencer_id === influencer.influencer_id)
      );

      // Safely get the collection name
      const length = parsedData.length || 0;
      console.log("length output: ", length);
      // return length;
      setCount(length); // Update the state with the collection name
    } catch (error) {
      console.error("Error fetching collection count:", error);
      setCount("Error Fetching count");
      // return "NA";
    }
  };

  // Use useEffect to fetch the collection name when the component mounts
  useEffect(() => {
    fetchCollection(row);
  }, []);

  return (
    <div>
      {count} {/* Render the collection name */}
    </div>
  );
};

// Custom UI Components
const Btn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  textTransform: "none",
  fontWeight: "bold",
  marginLeft: "10px",
  "&:hover": { borderColor: "#3DBE72" },
}));

const Mylabel = styled("label")(`
    color : #828282ff;
`);

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const MyInput = styled("input")(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);
const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const CustomActions = ({ setSharePopup, row, combinedColumns }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [collection, setCollection] = useState({ ...row });
  const [campaign, setCampaign] = useState({});
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [isCampaignFocus, setIsCampaignFocus] = useState(false);
  const [isStatusFocus, setIsStatusFocus] = useState(false);
  const [allCampaign, setAllCampaign] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [campaignDetail, setCampaignDetail] = useState({});
  const [emailTemplate, setEmailTemplate] = useState("");
  const [isInviteByEmailChecked, setIsInviteByEmailChecked] = useState(false);
  const [isEmailFocus, setisEmailFoucs] = useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });

  const handleCapmapignListItemClick = (name, id) => {
    setCampaignDetail({
      name,
      id,
    });
    setIsCampaignFocus(false);
  };
  const handleOpenAssignDialog = () => {
    setOpenAssignDialog(true);
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const handleAssignToCampaign = () => {
    // Implement your logic to assign the collection to the selected campaign
    handleCloseAssignDialog();
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    try {
      const response = await updateCollection(collection);
      setCollection(response);

      setIsSuccessful(true);
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection doesn't exists.", status: true });
      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCampaignSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    try {
      const response = await AssignCampaign({
        id: row.id,
        campaign_id: campaignDetail.id,
      });
      // console.log(response);
      setIsSuccessful(true);
      setError({ message: "", status: false });
    } catch (e) {
      console.error("Error while submiting new collection data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
      setIsInviteByEmailChecked(false);
    }
  };

  const handleShareClick = () => {
    setOpenShareDialog(true); // Open the ShareCollection dialog
    setSharePopup(false); // Close the actions menu
  };

  const getBrands = async () => {
    try {
      const response = await getAllBrands();
      //   console.log("all brands : ", response);
      setAllBrands(response);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      // console.log("api hit.");
    }
  };
  const handleArchieve = () => {
    setShowConfirmationModal(true);
    setCollection({ ...collection, status: "Archived" });
  };

  const handleClose = () => {
    setOpenAssignDialog(false);
    setCreateCollectionDialog(false);
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setIsBrandFocus(false);
    setBrandName("");
    setCollection({ ...collection });
    setAnchorEl(null);
    setShowConfirmationModal(false);

    // handleNewCollection(newCollection);
    // getCollection();
  };

  const getCampaigns = async () => {
    try {
      const response = await getAllCampaign();
      setAllCampaign(response);
    } catch (err) {
      console.log(err);
    }
  };

  const getBrand = (id) => {
    // const campaign = allBrands?.filter((brand) => {
    //   // console.log(brand, id, "brand-id");

    //   return brand.id == id;
    // });

    // return campaign;
    return 0;
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false); // Close the ShareCollection dialog
  };
  const dummyEmailTemplates = [
    { name: "Template A", id: 1 },
    { name: "Template B", id: 2 },
    { name: "Template C", id: 3 },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //
  // };

  const closeCreateCollectionDialog = () => {
    setCreateCollectionDialog(false);
  };
  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const handleStatusChanged = (value) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      status: value,
    }));
    setIsStatusFocus(false);
  };

  const handleListItemClick = (name, id) => {
    setBrandName(name);
    // console.log("brand name ", brandName);

    setCollection((prevCollection) => {
      return { ...prevCollection, assigned_brand_id: id };
    });
  };

  const handleCollectionChange = (e) => {
    const { name, value } = e.target;
    setCollection((prevCollection) => ({
      ...prevCollection,
      [name]: value,
    }));
  };

  const handleEmailListItemClick = (name, id) => {
    setEmailTemplate(name);
    setisEmailFoucs(false);
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(value);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    // setStatus(value);
  };

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };
  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  // const filteredBrands = allBrands?.filter((brand) =>
  //   brand.name.toLowerCase().includes(brandName.toLowerCase())
  // );

  useEffect(() => {
    getBrands();
    getCampaigns();
  }, []);
  const columnsData = combinedColumns.map((column) => {
    return { name: column.name, enabled: true }; // Set enabled to true by default
  });

  const handleCheckboxChange = (event) => {
    setIsInviteByEmailChecked(event.target.checked);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon style={{ color: "#3dbf73ff" }} />
      </IconButton>
      <Dialog
        open={openShareDialog}
        onClose={handleCloseShareDialog}
        PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
      >
        <ShareCollection
          sharePopUp={setOpenShareDialog}
          columnsData={columnsData}
          clickedData={row}
        />
      </Dialog>

      <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <Box p={2}>
          <Typography variant="h6">
            Are you sure you want to archive this collection?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClose}
              sx={{
                textTransform: "none",
                borderRadius: 10,
                fontWeight: "bold",
                marginRight: 1,
                background: "#c5edd6ff",
                borderWidth: "2px",
                color: "black",
                borderColor: "#3dbf73ff",
                "&:hover": {
                  borderWidth: "2px",
                  borderColor: "#3dbf73ff",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose();
                handleSubmit();
              }}
              variant="contained"
              sx={{
                bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                boxShadow: "none",
                fontWeight: "bold",
                borderRadius: 10,
                // padding: '10px 30px',
                "&:hover": {
                  bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                },
              }}
            >
              Confirm Archive
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={createCollectionDialog}
        onClose={handleClose}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{
          sx: { borderRadius: 5, border: "2px solid black" },
        }}
      >
        {isSucessful ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
          >
            <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "#3dbe72ff",
                marginTop: 2,
              }}
            >
              Collection Successfully Updated.
            </Typography>

            <Box
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: "#3dbe72ff",
                  // color : '#3dbe72ff',
                  fontWeight: "bold",
                  // border: '1px solid black',
                  boxShadow: "none",
                  textTransform: "none",
                  border: "1px solid #3dbe72ff",
                  marginRight: 1,
                  "&:hover": {
                    bgcolor: "#3dbe72ff",
                    color: "white",
                    borderColor: "black",
                  },
                }}
                onClick={() => handleClose()}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : (
          <Grid container p={4}>
            <Grid
              item
              md={11}
              xs={11}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#4caf50",
                }}
              >
                Edit Collection
              </Typography>
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Tooltip title="Close" arrow placement="left">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateCollectionDialog(false);
                    handleClose();
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={12} xs={12}>
              <form>
                <Box mt={2}>
                  <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                  <Box>
                    <MyInput
                      type="text"
                      name="name"
                      id="collection"
                      value={collection?.name}
                      disabled={!isEditing}
                      placeholder="Enter the Name of collection"
                      onChange={(e) => handleCollectionChange(e)}
                      required
                      onFocus={() => setIsBrandFocus(false)}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="brand">Brand Name</Mylabel>
                  <Box position={"relative"}>
                    <MyInput
                      type="text"
                      name="brand_name"
                      id="brand"
                      disabled={!isEditing}
                      value={
                        getBrand(collection?.assigned_brand_id)?.[0]?.name || ""
                      }
                      placeholder="search the brand "
                      required
                      onChange={(e) => handleBrandChange(e)}
                      onFocus={() => {
                        setIsBrandFocus(true);
                      }}
                      // onBlur={() => setIsBrandFocus(false)}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                      onClick={openAddBrandDialog}
                    >
                      <Tooltip title="Add a new Brand" arrow>
                        <Add />
                      </Tooltip>
                    </IconButton>
                    {isBrandFocus && (
                      <Box
                        sx={{
                          padding: "5px 10px",
                          position: "absolute",
                          width: "100%",
                          background: "white",
                          marginTop: 1,
                          borderRadius: 2,
                          maxHeight: "140px",
                          overflowY: "scroll",
                          zIndex: 10,
                        }}
                      >
                        {/* {filteredBrands?.reverse()?.map(({ name, id }) => (
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleListItemClick(name, id)}
                          >
                            {name}
                          </ListItem>
                        ))} */}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="description">Description</Mylabel>
                  <Box>
                    <MyTextArea
                      rows={3}
                      type="text"
                      name="description"
                      id="description"
                      disabled={!isEditing}
                      placeholder="Write short description"
                      onChange={(e) => handleCollectionChange(e)}
                      required
                      value={collection.description}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="collection">Status</Mylabel>
                  <Box position={"relative"}>
                    <MyInput
                      value={collection.status}
                      type="text"
                      name="brand_name"
                      id="brand"
                      disabled={!isEditing}
                      placeholder="search the brand "
                      required
                      onChange={(e) => handleStatusChange(e)}
                      onFocus={() => {
                        setIsStatusFocus(true);
                      }}
                    />

                    {isStatusFocus && (
                      <Box
                        sx={{
                          padding: "5px 10px",
                          position: "absolute",
                          width: "100%",
                          background: "white",
                          marginTop: 1,
                          borderRadius: 2,
                          maxHeight: "100px",
                          overflowY: "auto",
                          zIndex: 10,
                        }}
                      >
                        <ListItem
                          component="div"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleStatusChanged("Active")}
                        >
                          Active
                        </ListItem>
                        <ListItem
                          component="div"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleStatusChanged("Draft")}
                        >
                          Draft
                        </ListItem>
                        <ListItem
                          component="div"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleStatusChanged("Rejected")}
                        >
                          Rejected
                        </ListItem>
                        <ListItem
                          component="div"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleStatusChanged("Archieved")}
                        >
                          Archieved
                        </ListItem>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "none",
                      borderRadius: 10,
                      fontWeight: "bold",
                      marginRight: 1,
                      background: "#c5edd6ff",
                      borderWidth: "2px",
                      color: "black",
                      borderColor: "#3dbf73ff",
                      "&:hover": {
                        borderWidth: "2px",
                        borderColor: "#3dbf73ff",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                      boxShadow: "none",
                      fontWeight: "bold",
                      borderRadius: 10,
                      // padding: '10px 30px',
                      "&:hover": {
                        bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                      },
                    }}
                    onClick={handleSubmit}
                    endIcon={
                      loading ? (
                        <LoadingSpinner size={25} />
                      ) : isSucessful ? (
                        <AddTaskOutlined />
                      ) : (
                        error.status && <WarningAmberOutlined />
                      )
                    }
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
      </Dialog>

      <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
        <AddNewBrand close={closeAddBrandDialog} />
      </Dialog>

      <Dialog
        open={openAssignDialog}
        onClose={handleCloseAssignDialog}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
      >
        {isSucessful ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
          >
            <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "#3dbe72ff",
                marginTop: 2,
              }}
            >
              Campaign Assigned Successfully.
            </Typography>

            <Box
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: "#3dbe72ff",
                  // color : '#3dbe72ff',
                  fontWeight: "bold",
                  // border: '1px solid black',
                  boxShadow: "none",
                  textTransform: "none",
                  border: "1px solid #3dbe72ff",
                  marginRight: 1,
                  "&:hover": {
                    bgcolor: "#3dbe72ff",
                    color: "white",
                    borderColor: "black",
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : (
          <Grid container p={4}>
            <Grid
              item
              md={11}
              xs={11}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#4caf50",
                }}
              >
                Assign Collection to Campaign
              </Typography>
              {error.status && (
                <Box>
                  <p style={{ color: "#e81e61ff", fontSize: 13 }}>
                    {error.message}
                  </p>
                </Box>
              )}
            </Grid>
            <Grid
              item
              md={1}
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Tooltip title="Close" arrow placement="left">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateCollectionDialog(false);
                    handleClose();
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={12} xs={12}>
              <form onSubmit={handleCampaignSubmit}>
                <Box mt={2}>
                  <Mylabel htmlFor="campaign"> Name of Campaign</Mylabel>
                  <Box position={"relative"}>
                    <MyInput
                      type="text"
                      name="campaign_name"
                      id="campaign"
                      placeholder="search the Campaign "
                      required
                      onChange={(e) => handleBrandChange(e)}
                      onFocus={() => {
                        setIsCampaignFocus(true);
                      }}
                      // onBlur={() => setIsBrandFocus(false)}
                      value={campaignDetail.name}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                      // onClick={}
                    >
                      <Tooltip title="Add a new Brand" arrow>
                        <Add />
                      </Tooltip>
                    </IconButton>
                    {isCampaignFocus && (
                      <Box
                        sx={{
                          padding: "5px 10px",
                          position: "absolute",
                          width: "100%",
                          background: "white",
                          marginTop: 1,
                          borderRadius: 2,
                          maxHeight: "140px",
                          overflowY: "scroll",
                          zIndex: 10,
                        }}
                      >
                        {allCampaign?.map(({ name, id }) => (
                          <ListItem
                            key={id}
                            component="div"
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCapmapignListItemClick(name, id)
                            }
                          >
                            {name}
                          </ListItem>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={2}>
                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label="Invite influencer via email"
                  />
                </Box>
                {isInviteByEmailChecked && (
                  <Box mt={2}>
                    <Mylabel htmlFor="campaign"> Email Template</Mylabel>
                    <Box position={"relative"}>
                      <MyInput
                        type="text"
                        name="email_template"
                        id="email_template"
                        placeholder="Search for a template"
                        // required
                        // onBlur={() => setIsEmailFocus(false)}
                        onFocus={() => setisEmailFoucs(true)}
                        value={emailTemplate}
                      />

                      {isEmailFocus && (
                        <Box
                          sx={{
                            padding: "5px 10px",
                            position: "absolute",
                            width: "100%",
                            background: "white",
                            marginTop: 1,
                            borderRadius: 2,
                            maxHeight: "140px",
                            overflowY: "scroll",
                            zIndex: 10,
                          }}
                        >
                          {dummyEmailTemplates?.map(({ name, id }) => (
                            <ListItem
                              key={id}
                              component="div"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleEmailListItemClick(name, id)}
                            >
                              {name}
                            </ListItem>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "none",
                      borderRadius: 10,
                      fontWeight: "bold",
                      marginRight: 1,
                      background: "#c5edd6ff",
                      borderWidth: "2px",
                      color: "black",
                      borderColor: "#3dbf73ff",
                      "&:hover": {
                        borderWidth: "2px",
                        borderColor: "#3dbf73ff",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                      boxShadow: "none",
                      fontWeight: "bold",
                      borderRadius: 10,
                      // padding: '10px 30px',
                      "&:hover": {
                        bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                      },
                    }}
                    endIcon={
                      loading ? (
                        <LoadingSpinner size={25} />
                      ) : isSucessful ? (
                        <AddTaskOutlined />
                      ) : (
                        error.status && <WarningAmberOutlined />
                      )
                    }
                    disabled={loading || isSucessful}
                  >
                    {isSucessful ? "Assigned" : "Assign"}
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
      </Dialog>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenShareDialog(true);
          }}
        >
          <ShareIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Share
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <GetAppIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Download as CSV
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <PictureAsPdfIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Download as PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenAssignDialog();
          }}
        >
          <CampaignIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Assign to Campaign
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <PersonAddIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Add Influencer
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            openCreateCollectionDialog();
          }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <EditIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />
          Edit Collection
        </MenuItem>
        <MenuItem onClick={handleArchieve}>
          <ArchiveIcon
            sx={{ marginRight: "8px" }}
            style={{ color: "#3dbf73ff" }}
          />{" "}
          Archive
        </MenuItem>
      </Menu>
    </>
  );
};

const CollectionTable = ({
  newAddedCollection,
  brands,
  searched,
  loadMoreData,
  customNewColumn,
  setRowsPerPage,
  setNewPageAdded,
  setCustomHide,
  customHide,
}) => {
  // console.log("brands ct : ", brands);
  const [data, setData] = useState([]);
  const [clickedRow, setClickedRow] = useState(null);
  const [collection, setCollection] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [datafetching, setDataFetching] = useState(false);
  const [updatedCollection, setUpdatedCollection] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedRowArr, setSelectedRowArr] = React.useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const tableRef = useRef();
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [showCollection, setShowCollection] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [pending, setPending] = useState(false);
  const [allCollection, setAllCollection] = useState([]);

  const handleHideColumn = async (columnId) => {
    console.log("columnId : ", columnId);
    const data = {
      status: "hide",
      id: columnId,
    };
    try {
      const res = await updateColumnHead(data);
      console.log("res : ", res);
    } catch (e) {
      console.log("error : ", e);
    } finally {
      setCustomHide(!customHide);
    }

    // setHiddenColumns([...hiddenColumns, columnName]);
  };
  const handleShowAllColumns = () => {
    setHiddenColumns([]);
  };

  const [order, setOrder] = useState(false);
  const [sortedCollection, setSortedCollection] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [collectionCount, setCollectionCount] = useState(0);
  useEffect(() => {
    setCollectionData(searched);
  }, [searched]);

  const handleSort = (name) => {
    let sortedData = [...collectionData];

    if (name === "Sr.No.") {
      sortedData = order
        ? sortedData.sort((a, b) => a.id - b.id)
        : sortedData.sort((a, b) => b.id - a.id);
    } else if (name === "No. of Influencers") {
      sortedData = order
        ? sortedData.sort((a, b) => {
            const lengthA = a.influncers_id
              ? JSON.parse(a.influncers_id).length
              : 0;
            const lengthB = b.influncers_id
              ? JSON.parse(b.influncers_id).length
              : 0;
            return lengthA - lengthB;
          })
        : sortedData.sort((a, b) => {
            const lengthA = a.influncers_id
              ? JSON.parse(a.influncers_id).length
              : 0;
            const lengthB = b.influncers_id
              ? JSON.parse(b.influncers_id).length
              : 0;
            return lengthB - lengthA;
          });
    } else if (name === "Collection Name") {
      sortedData = order
        ? sortedData.sort((a, b) => a.name.localeCompare(b.name))
        : sortedData.sort((a, b) => b.name.localeCompare(a.name));
    } else if (name === "Brand") {
      sortedData = order
        ? sortedData.sort((a, b) => a.assigned_brand_id - b.assigned_brand_id)
        : sortedData.sort((a, b) => b.assigned_brand_id - a.assigned_brand_id);
    } else if (name === "Status") {
      sortedData = order
        ? sortedData.sort((a, b) => a.status.localeCompare(b.status))
        : sortedData.sort((a, b) => b.status.localeCompare(a.status));
    } else if (name === "Created On") {
      sortedData = order
        ? sortedData.sort((a, b) => a.created_at.localeCompare(b.created_at))
        : sortedData.sort((a, b) => b.created_at.localeCompare(a.created_at));
    }

    console.log("name : ", name);
    console.log("sorted data : ", sortedData);

    setOrder(!order);
    setSortedCollection(sortedData);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate * 1000).toISOString().split("T")[0];
    const day = date.split("-")[2];
    const month = date.split("-")[1];
    const year = date.split("-")[0];
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    setSortedCollection(collectionData);
  }, [collectionData]);

  const loadFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  const [currUser, setCurrUser] = useState(loadFromLocalStorage("currUser"));

  const Name = ({
    name,
    handleHideColumn,
    handleShowAllColumns,
    handleAddColumn,
    columnId,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchedUnderAdd, setSearchedUnderAdd] = useState([]);
    const [customColumn, setCustomColumn] = useState("");
    const [addOrDrag, setAddOrDrag] = useState("");

    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const handleSubMenuOpen = (event) => {
      setSubMenuAnchorEl(event.currentTarget);
    };
    const handleSubMenuClose = () => {
      setSubMenuAnchorEl(null);
    };

    const handleMenuOpen = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setSearchedUnderAdd([]);
    };

    const addColumn = (columnName) => {
      handleAddColumn(columnName);
      handleSubMenuClose(); // Close the submenu after adding the column
    };

    const handleHideClick = () => {
      handleHideColumn(columnId);
    };

    const handleAddingColumnHead = async (columnName) => {
      let columnId;
      const data = {
        name: columnName,
        for_table: "collection",
        created_by: JSON.stringify(currUser),
        status: "show",
        for_id: "Collection",
        is_deleted: "false",
      };
      console.log("datacurrUser", data);
      try {
        const response = await addNewColumn(data);
        console.log("response", response);
        columnId = response.data.head.id;
      } catch (e) {
        console.log("error", e);
      } finally {
        try {
          const response = await getColumnHead();
          console.log("responseresponse", response);
        } catch (e) {
          console.log("error", e);
        }
        handleAddColumn({ columnName, columnId, currUser });
        setCustomColumn("");
      }
    };

    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(e) => {
            handleMenuOpen(e);
            setAddOrDrag("Add");
          }}
        >
          <Add
            style={{
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </IconButton>
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(e) => {
            handleMenuOpen(e);
            setAddOrDrag("Drag");
          }}
        >
          <DragIndicator
            style={{
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </IconButton>
        <Typography variant="body1">{name}</Typography>
        <IconButton
          style={{
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
          size="small"
          onClick={() => {
            handleSort(name);
          }}
        >
          {order ? <ArrowDownwardSharp /> : <ArrowUpward />}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {addOrDrag == "Add" ? (
            <Box margin={"1rem"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search"
                  startAdornment={<Search />}
                  sx={{
                    width: "200px",
                    borderRadius: 20,
                    // border: "1px solid #3DBE72",
                  }}
                  onChange={(e) => {
                    let searched = combinedColumns.filter((combinedColumns) =>
                      combinedColumns.name.props.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    if (e.target.value === "") {
                      searched = [];
                      setCustomColumn("");
                    }
                    if (e.target.value != "" && searched.length == 0) {
                      setCustomColumn(e.target.value);
                    }
                    setSearchedUnderAdd(searched);
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {customColumn != "" ? (
                    <Button
                      onClick={() => {
                        handleAddingColumnHead(customColumn);
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <Box>
                <List>
                  {searchedUnderAdd.map((columns) => (
                    <ListItem>
                      <ListItemButton>
                        {searchedUnderAdd.length > 0
                          ? columns.name
                          : "No data found"}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          ) : (
            <div>
              <MenuItem onClick={handleHideClick}>Hide</MenuItem>
              <MenuItem onClick={handleShowAllColumns}>
                Show all hidden columns
              </MenuItem>
            </div>
          )}
        </Menu>
      </Box>
    );
  };

  const getCollections = useCallback(async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      setAllCollection(response.collection);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  }, []);

  useEffect(() => {
    const CollectionCount = async () => {
      try {
        const response = await getAllCollectionCount();
        setCollectionCount(response.data.collectionCount);
      } catch (e) {
        console.log("error", e);
      }
    };
    CollectionCount();
  }, []);

  const handleAddColumn = ({ columnName, columnId, currUser }) => {
    // Check if the column already exists to avoid duplicates
    if (!dynamicColumns.some((column) => column.name === columnName)) {
      const newColumn = {
        name: (
          <Name
            name={columnName}
            handleHideColumn={handleHideColumn}
            handleShowAllColumns={handleShowAllColumns}
            handleAddColumn={handleAddColumn}
          />
        ),
        selector: (row) => row[columnName.toLowerCase().replace(/\s+/g, "_")], // Example selector based on the column name
        // sortable: false,
        cell: (row, index) => {
          return (
            <>
              <CustomNewColumn
                row={row}
                columnName={columnName}
                columnId={columnId}
                currUser={currUser}
              />
            </>
          );
        },
        reorder: true,
        center: true,
        omit: hiddenColumns.includes(columnName),

        // You can add more properties as needed
      };
      setDynamicColumns([...dynamicColumns, newColumn]);
    }
  };

  const handleRowClick = (row) => {
    // console.log("row", row);
    setClickedRow(row);
    setOpenDrawer(true);
  };

  const handleSelectRow = (e, row) => {
    if (e) e.stopPropagation();

    const isSelected = selectedRowArr.some(
      (selectedRow) => selectedRow.id === row.id
    );

    // Update the selectedRows state based on whether the row is currently selected
    if (isSelected) {
      // If the row is already selected, remove it from the selectedRows array
      setSelectedRowArr(
        selectedRowArr.filter((selectedRow) => {
          // console.log("selected row id", selectedRow.id, "row id", row.id);

          return selectedRow.id !== row.id;
        })
      );
    } else {
      // If the row is not selected, add it to the selectedRows array
      // console.log("else");
      setSelectedRowArr([...selectedRowArr, row]);
    }
    // console.log("selected row arr", selectedRowArr);
    // Check if any rows are selected
  };

  const columns = [
    {
      name:
        selectedRowArr.length === 0 ? (
          <Name
            name={"Sr.No."}
            handleHideColumn={handleHideColumn}
            handleShowAllColumns={handleShowAllColumns}
            handleAddColumn={handleAddColumn}
          />
        ) : (
          <Checkbox
            checked={selectedRowArr.length === searched.length}
            onChange={(e) => {
              e.stopPropagation();
              if (e.target.checked) {
                setSelectedRowArr(searched);
              } else {
                setSelectedRowArr([]);
              }
            }}
          />
        ),
      selector: (row) => row.id,
      sortable: false,
      center: true,
      cell: (row, index) => {
        // Determine if the current row is selected
        const isSelected = selectedRowArr.some(
          (selectedRow) => selectedRow.id === row.id
        );
        // Show the checkbox if the row is hovered or selected
        if (hoveredRow === row.id || isSelected) {
          return (
            <Checkbox
              checked={isSelected}
              inputProps={{ "aria-label": "select all desserts" }}
              // Prevent the checkbox from triggering row selection

              onClick={(e) => {
                // Prevent default action
                handleSelectRow(e, row);

                // Determine if the row is already selected
              }}
            />
          );
        } else {
          return <>{row.id}</>;
        }
      },
      omit: hiddenColumns.includes("Sr.No."),
    },
    {
      name: (
        <Name
          name={"Profile"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: "srNo",
      sortable: false,
      center: true,
      reorder: true,

      cell: (row) => <CustomProfile row={row} />,
      omit: hiddenColumns.includes("Profile"),
    },
    {
      name: (
        <Name
          name={"Collection Name"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: "srNo",
      sortable: false,
      center: true,
      reorder: true,

      cell: (row) => <CustomName row={row} />,
      omit: hiddenColumns.includes("Collection Name"),
    },
    {
      name: (
        <Name
          name={"No. of Influencers"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.id,
      sortable: false,
      reorder: true,
      center: true,

      cell: (row) => {
        return <CustomNoOfCollection row={row.id} />;
      },
      omit: hiddenColumns.includes("No. of Influencers"), // Use the omit property
    },
    {
      name: (
        <Name
          name={"Brand"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row?.brand?.name,
      sortable: false,
      reorder: true,
      center: true,

      cell: (row) => row?.brand?.name || "N/A",
      omit: hiddenColumns.includes("Brand"),
      // width: '120px',
    },
    {
      name: (
        <Name
          name={"Created On"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => formatDate(row.created_at_epoch),
      sortable: false,
      reorder: true,
      center: true,
      omit: hiddenColumns.includes("Created On"),
      // width: "120px",
    },
    {
      name: (
        <Name
          name={"Status"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.status,
      sortable: false,
      reorder: true,
      omit: hiddenColumns.includes("Status"),
      center: true,
      cell: (row) => <CustomAverage row={row} />,
    },
    {
      name: (
        <Name
          name={"Actions"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      // selector: row => row.status,
      // sortable: false,
      // reorder: true,
      omit: hiddenColumns.includes("Actions"),
      center: true,
      reorder: true,

      cell: (row) => (
        <CustomActions
          setSharePopup={setShareCollectionDrawer}
          row={row}
          combinedColumns={combinedColumns}
        />
      ),
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
    },
  }));

  const ColumnFromData = () => {
    const customColumns = customNewColumn.map((column) => ({
      name: (
        <Name
          name={column.name}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
          columnId={column.id}
        />
      ),
      selector: (row) => row[column.name],
      sortable: false,
      reorder: true,
      center: true,
      cell: (row) => {
        const rowId = String(row.id); // Ensure row.id is a string
        const customData = column.custom_column_data.find((data) => {
          const dataForId = String(data.for_id); // Ensure data.for_id is a string

          return dataForId === rowId;
        });

        return <>{customData ? customData.value : "No data"}</>;
      },
      omit: hiddenColumns.includes(column.name),
    }));

    return customColumns;
  };

  const handleAddToCollectionDialogOpen = () => {
    // Open the dialog
    setShowCollection(true);

    // Call the API function when the dialog is opened
    getCollections();
  };

  const handleAddToCampaignDialogOpen = () => {
    // Open the dialog
    setShowCampaign(true);

    // Call the API function when the dialog is opened
    // getCollections();
  };

  // const customColumns = ColumnFromData();

  const combinedColumns = [...columns, ...dynamicColumns];
  // const combinedColumns = [...columns];

  // if (customNewColumn.length > 0) {
  //   combinedColumns.push(...customColumns);
  // }

  const columnsData = combinedColumns.map((column) => {
    return { name: column.name, enabled: true }; // Set enabled to true by default
  });

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);

  const [influencersView, setInfluencersView] = useState(true);

  // Additional Utility Functions
  const downloadTableAsPDF = useCallback(async () => {
    setDownloading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure table updates have taken effect

      const table = tableRef.current;

      if (!table) {
        console.error("Table element not found.");
        setDownloading(false);
        return;
      }

      // Use html2canvas to capture the expanded table
      const canvas = await html2canvas(table);

      // Create a PDF document
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 size
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= pageHeight;
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }

      pdf.save("table.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setDownloading(false);
    }
  }, [tableRef]);

  // Influencer CRUD Functions
  const handleRemoveCollection = () => {};

  return (
    <>
      {/* //Drawer for Collection  */}
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: { width: { md: "60%", xs: "100%" } },
        }}
      >
        <CollectionDrawer
          setOpen={setOpenDrawer}
          sharePopUp={setShareCollectionDrawer}
          data={clickedRow}
        />
        <Dialog
          open={shareCollectionDrawer}
          onClose={() => setShareCollectionDrawer(false)}
          PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
        >
          <ShareCollection
            sharePopUp={setShareCollectionDrawer}
            clickedData={clickedRow}
            columnsData={columnsData}
          />
        </Dialog>

        {/* Drawer for share collection  */}
      </Drawer>

      {sortedCollection.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
            flexGrow: 1,
            mx: "auto",
            marginBottom: 3,
          }}
          ref={tableRef}
          id="influencertable"
        >
          <Box
            sx={{
              width: "100%",
              // width: "90vw",
              overflowX: "auto",
              mx: "auto",
            }}
          >
            <Box
              marginBottom={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "width 0.4s ease-in-out",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box>
                  <Tooltip title="Cards view" arrow placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setInfluencersView(false)}
                    >
                      <img src={SBicon} alt="" width={30} />
                      {/* <WindowOutlined sx={{fontSize : 30, color : 'green'}}/> */}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="List view" arrow placement="top">
                    <IconButton
                      size="small"
                      onClick={() => setInfluencersView(true)}
                    >
                      <img src={LISTicon} alt="" width={30} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  {selectedRowArr.length > 0 && (
                    <CSVExportButton
                      data={selectedRowArr}
                      filename="influencers_data"
                    />
                  )}
                </Box>
                <Box>
                  <Tooltip title="Download PDF" arrow placement="top">
                    <IconButton
                      disabled={downloading}
                      onClick={downloadTableAsPDF}
                      size="small"
                    >
                      <img src={PDFicon} alt="" width={30} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                {selectedRowArr?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box>
                      <Btn
                        size="small"
                        variant="outlined"
                        startIcon={<BookmarkBorderIcon />}
                        onClick={handleAddToCollectionDialogOpen}
                      >
                        Add to Collection
                      </Btn>
                    </Box>
                    <Box>
                      <Btn
                        size="small"
                        variant="outlined"
                        startIcon={<AddRoundedIcon />}
                        onClick={handleAddToCampaignDialogOpen}
                      >
                        Add to Campaign
                      </Btn>
                    </Box>
                    <Box>
                      <Btn
                        size="small"
                        variant="outlined"
                        startIcon={<VisibilityOffRoundedIcon />}
                      >
                        Hide Collections
                      </Btn>
                    </Box>
                    <Box>
                      <Btn
                        size="small"
                        variant="outlined"
                        startIcon={<Delete />}
                        onClick={() => handleRemoveCollection()}
                      >
                        Remove Collection
                      </Btn>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <DataTable
              key={hiddenColumns.join(",")}
              columns={combinedColumns}
              data={sortedCollection}
              customStyles={customStyles}
              fixedHeader
              pagination
              paginationTotalRows={collectionCount}
              onChangePage={(page) => {
                setNewPageAdded(page);
              }}
              onChangeRowsPerPage={(currentRowsPerPage) => {
                setRowsPerPage(currentRowsPerPage);
              }}
              onRowMouseEnter={(row, event) => setHoveredRow(row.id)}
              onRow
              onRowMouseLeave={() => setHoveredRow(null)}
              onRowClicked={handleRowClick}
              progressPending={datafetching}
              selectableRowsHighlight
              pointerOnHover
              fixedHeaderScrollHeight="550px"
              responsive
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress
            sx={{
              margin: "auto",
              width: "50px",
              height: "50px",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default CollectionTable;
