import { useMemo, memo, useEffect, useState } from "react";
import { Box, FormControl, InputLabel, Popover, Button } from "@mui/material";
import CustomCheckboxGroup from "../customInputComponents/checkboxGroup";

const MultiCheckboxFrontFilter = memo(
  ({ title, label, setDataMethod, data, clearIt, predefinedOptions = [] }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    useMemo(() => {
      if (data) {
        let updatedData = [];
        predefinedOptions.forEach((option, index) => {
          if (data.includes(option.value)) {
            updatedData.push(option);
          }
        });
        setSelectedOptions(updatedData);
      }
      console.log("incoming data memo running");
    }, [data]);

    useEffect(() => {
      selectedOptions.length > 0
        ? setDataMethod(
            selectedOptions.map((option, index) => {
              return option.value;
            })
          )
        : setDataMethod(null);
    }, [selectedOptions]);

    const clearMultiCheckbox = () => {
      setSelectedOptions([]);
    };

    useEffect(() => {
      clearIt(() => clearMultiCheckbox);
    }, [clearIt]);

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "multi-checkbox-popover" : undefined;

    return (
      <FormControl sx={{ m: 1, minWidth: 80, width: 120 }} size="small">
        <Button
          aria-describedby={id}
          onClick={handleOpen}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 20,
            paddingLeft: 2,
            border: "1px solid lightgrey",
            textTransform: "capitalize",
          }}
        >
          <InputLabel id="multi-checkbox-label">{title}</InputLabel>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: 80,
            }}
          >
            <CustomCheckboxGroup
              label={label}
              options={predefinedOptions}
              selectedValues={selectedOptions}
              onChange={(newSelectedOptions) =>
                setSelectedOptions(newSelectedOptions)
              }
            />
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={clearMultiCheckbox}
            >
              Clear
            </Button>
          </Box>
        </Popover>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default MultiCheckboxFrontFilter;
