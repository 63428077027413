import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Popover,
  Button,
} from "@mui/material";

const MinMaxFrontFilter = memo(
  ({ title, setDataMethod, data, clearIt }) => {
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    useMemo(() => {
      if (data) {
        if (data.min && data.max) {
          setMinValue(data.min.toString());
          setMaxValue(data.max.toString());
        }
      }
    }, [data]);

    useEffect(() => {
      if (minValue !== "" || maxValue !== "") {
        setDataMethod({
          min: minValue.toString().replace(/'/g, '"'),
          max: maxValue.toString().replace(/'/g, '"'),
        });
      } else {
        setDataMethod(null);
      }
    }, [minValue, maxValue]);

    const clearMinMax = () => {
      setMinValue("");
      setMaxValue("");
    };

    useEffect(() => {
      clearIt(() => clearMinMax);
    }, [clearIt]);

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "min-max-popover" : undefined;

    return (
      <FormControl
        sx={{ m: 1, minWidth: 80, width: 120, maxWidth: 180 }}
        size="small"
      >
        <Button
          aria-describedby={id}
          onClick={handleOpen}
          sx={{
            textAlign: "center",
            height: 40,
            borderRadius: 20,
            justifyContent: "center",
            paddingLeft: 2,
            border: "1px solid lightgrey",
            textTransform: "capitalize",
          }}
        >
          <InputLabel id="min-max-select-label">{title}</InputLabel>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <TextField
                sx={{ width: "4rem", mb: 2 }}
                id="min-size-input"
                label="Min"
                variant="outlined"
                size="small"
                type="number"
                value={minValue || ""}
                onChange={(e) => setMinValue(e.target.value)}
              />
              <TextField
                sx={{ width: "4rem", mb: 2 }}
                id="max-size-input"
                label="Max"
                variant="outlined"
                size="small"
                type="number"
                value={maxValue || ""}
                onChange={(e) => setMaxValue(e.target.value)}
              />
            </Box>
            <Button fullWidth variant="outlined" onClick={clearMinMax}>
              Clear
            </Button>
          </Box>
        </Popover>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default MinMaxFrontFilter;
