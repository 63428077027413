import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Chip,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
  TextareaAutosize,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  Add,
} from "@mui/icons-material";
import { borderRadius } from "@mui/system";
import { updateCampaign } from "../../../service/campaign api/updateCampaign";

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
}));

const HtmlLabel = styled("label")(`
      color : #828282ff;
      font-size : '1.1rem';
  
  `);

const UpdateCampaignDialog = ({ open, set, data }) => {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [platform, setPlatform] = useState();
  const [start_date, setStart_Date] = useState();
  const [start_time, setStart_Time] = useState();
  const [description, setDescription] = useState();
  const [brand_name, setBrand_Name] = useState();
  const [brand_id, setBrand_Id] = useState();
  const [brand_hastags, setBrand_Hastags] = useState();
  const [brand_instagram, setBrand_Instagram] = useState();
  const [brand_youtube, setBrand_Youtube] = useState();
  const [brand_mention_mandatory, setBrand_Mention_Mandatory] = useState();
  const [type, setType] = useState();
  const [paid_finance, setPaid_Finance] = useState();
  const [paid_amount, setPaid_Amount] = useState();
  const [paid_indivdual_buget, setPaid_Indivdual_Buget] = useState();
  const [paid_allow_negotiation, setPaid_Allow_Negotiation] = useState();
  const [paid_payment_method_by_brand, setPaid_Payment_Method_By_Brand] =
    useState();
  const [
    paid_payment_method_to_influencer,
    setPaid_Payment_Method_To_Influencer,
  ] = useState();
  const [exchange_product_name, setExchange_Product_Name] = useState();
  const [exchange_product_value, setExchange_Product_Value] = useState();
  const [exchange_product_link, setExchange_Product_Link] = useState();
  const [exchange_product_description, setExchange_Product_Description] =
    useState();
  const [exchange_picture_link, setExchange_Picture_Link] = useState();
  const [requirement_title, setRequirement_Title] = useState();
  const [requirement_redirect_url, setRequirement_Redirect_Url] = useState();
  const [requirement_media, setRequirement_Media] = useState();
  const [requirement_approved_date, setRequirement_Approved_Date] = useState();
  const [requirement_upload_live_date, setRequirement_Upload_Live_Date] =
    useState();
  const [requirement_upload_time, setRequirement_Upload_Time] = useState();
  const [requirement_description, setRequirement_Description] = useState();
  const [
    requirement_description_is_shareable,
    setRequirement_Description_Is_Shareable,
  ] = useState();
  const [influencer_id, setInfluencer_Id] = useState();
  const [status, setStatus] = useState();
  const [media_ids, setMedia_Ids] = useState();

  const [updatedCampaignData, setUpdatedCampaignData] = useState({
    id: id,
    name: name,
    platform: platform,
    start_date: start_date,
    start_time: start_time,
    description: description,
    brand_name: brand_name,
    brand_id: brand_id,
    brand_hastags: brand_hastags,
    brand_instagram: brand_instagram,
    brand_youtube: brand_youtube,
    brand_mention_mandatory: brand_mention_mandatory,
    type: type,
    paid_finance: paid_finance,
    paid_amount: paid_amount,
    paid_indivdual_buget: paid_indivdual_buget,
    paid_allow_negotiation: paid_allow_negotiation,
    paid_payment_method_by_brand: paid_payment_method_by_brand,
    paid_payment_method_to_influencer: paid_payment_method_to_influencer,
    exchange_product_name: exchange_product_name,
    exchange_product_value: exchange_product_value,
    exchange_product_link: exchange_product_link,
    exchange_product_description: exchange_product_description,
    exchange_picture_link: exchange_picture_link,
    requirement_title: requirement_title,
    requirement_redirect_url: requirement_redirect_url,
    requirement_media: requirement_media,
    requirement_approved_date: requirement_approved_date,
    requirement_upload_live_date: requirement_upload_live_date,
    requirement_upload_time: requirement_upload_time,
    requirement_description: requirement_description,
    requirement_description_is_shareable: requirement_description_is_shareable,
    influencer_id: influencer_id,
    status: status,
    media_ids: media_ids,
  });

  const handleUpdateCampaign = async () => {
    const res = await updateCampaign(updatedCampaignData);
    console.log("update campaign response: ", res);
  };

  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#3dbf73ff",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              Update Campaign
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <SectionTitle>1. Campaign Details</SectionTitle>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="name">Update Campaign Name</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="name"
                label={data.title}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="platform">Update Platform</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="platform"
                label="Platform"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="brand_name">Update Brand Name</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="brand_name"
                label="Brand Name"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="brand_hashtags">
                Update Brand Hashtags
              </HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="brand_hashtags"
                label="Brand Hashtags"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="type">Update Type</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField fullWidth id="type" label="Type" variant="outlined" />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="desc">Update Description</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="desc"
                label="description"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="status">Update Status</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="status"
                label="Status"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box mt={1} width={"100%"}>
              <Button
                title="Update"
                variant="contained"
                size="medium"
                onClick={() => handleUpdateCampaign()}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default UpdateCampaignDialog;
