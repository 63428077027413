import { useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  AvatarGroup,
  Avatar,
  Menu,
  MenuItem,
  ClickAwayListener,
  Link,
  Chip,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid from "@mui/material/Grid2";
// import { Box } from '@mui/system'

import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import insta from "../../../images/insta-xsm.png";
import insta_sm from "../../../images/insta-sm.png";
import youtube_sm from "../../../images/youtube-sm.png";
import whatsapp from "../../../images/WhatsApp.png";
import gmail from "../../../images/Gmail.png";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import { Button } from '@mui/base';
import AccountAnalytics from "./AccountAnalytics";
import { addInfluencerToCollection } from "../../../../service/collections api/addInfluencerToCollection";

import ShareProfile from "./ShareProfile";
import { Link as RouterLink } from "react-router-dom";

// const TemptDP =
//   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

const InfluencerProfile = ({
  close,
  row,
  influencerData,
  selectedSocial,
  showCollection,
  setShowCollection,
  closeCollectionDialog,
  setInfluencerId,
  handleAddToCollectionDialogOpen,
}) => {
  const baseURL = window.location.origin;

  const [showDp, setShowDp] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  // const [showCollection, setShowCollection] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  // Encryption
  // Base64 encode function
  const encodeId = (id) => btoa(id);
  const encodedId = btoa(row.id.toString());

  const handleClickDP = () => {
    setShowDp(true);
  };

  const handleShowCampaign = () => {
    setShowCampaign(true);
  };
  const handleClose = () => {
    setShowCampaign(false);
  };

  const showSharepopup = () => {
    setSharePopup(true);
  };
  const closeSharepopup = () => {
    setSharePopup(false);
  };
  //addInfluencer to collectin :
  const addInfluencer = async () => {
    try {
      const response = await addInfluencerToCollection({
        id: 1,
        influencer_id: row.id,
      });
      console.log(response);
      setIsAdded(true);
    } catch (e) {
      console.error("error while adding influencer  : " + e.message);
    }
  };

  // dropdown logics
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // function formatNumber(num) {
  //   try {
  //     const strNum = num;

  //     if (strNum.endsWith("000000")) {
  //       return strNum.replace(/000000$/, "M"); // Replace trailing six zeros with 'M'
  //     } else if (strNum.endsWith("000")) {
  //       return strNum.replace(/000$/, "K"); // Replace trailing three zeros with 'K'
  //     } else {
  //       return num; // Return the number as is
  //     }
  //   } catch (error) {
  //     return "error";
  //   }
  // }

  function formatNumber(num) {
    try {
      // Convert the input to a number in case it's a string
      const number = Number(num);

      // Check if the conversion was successful
      if (isNaN(number)) {
        throw new Error("Invalid number");
      }

      // Format the number based on its value
      if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + "M"; // Convert to millions
      } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
      } else {
        return number.toString(); // Return the number as is
      }
    } catch (error) {
      return "error";
    }
  }

  function formatYouTubeSubscribers(count) {
    if (count < 1_000) return count.toString(); // For counts less than 1K, return as-is

    const units = ["K", "M", "B", "T"]; // Thousand, Million, Billion, Trillion
    let unitIndex = -1;

    while (count >= 1_000 && unitIndex < units.length - 1) {
      count /= 1_000;
      unitIndex++;
    }

    // Limit to one decimal place
    return `${count.toFixed(1).replace(/\.0$/, "")}${units[unitIndex]}`;
  }

  return (
    <>
      {/* share profile  */}
      <Dialog
        open={sharePopup}
        onClose={closeSharepopup}
        PaperProps={{
          style: { borderRadius: "18px", border: "1px solid black" },
        }}
      >
        <ShareProfile
          close={closeSharepopup}
          name={row.name}
          id={encodedId}
          baseUrl={baseURL}
        />
      </Dialog>

      {/* Campaign Popup  */}
      <Dialog
        open={showCampaign}
        onClose={() => setShowCampaign(false)}
        sx={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Grid container>
          <Grid
            item
            md={4}
            p={3}
            justifyContent="space-between"
            sx={{ background: "rgba(61, 190, 114, 0.2)" }}
          >
            <Grid container>
              <Grid item md={12}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Campaign
                </Typography>
                <TextField
                  size="small"
                  placeholder="Search here..."
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    background: "#FFFFFF",
                    marginTop: 2,
                    marginBottom: 5,
                    boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <nav aria-label="secondary mailbox folders">
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          borderRadius: "10px",
                          background: "black",
                          color: "#FFFFFF",
                          "&:hover": { background: "black", color: "#FFFFFF" },
                        }}
                      >
                        <ListItemText>
                          <span>Kurkure</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Lays</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Pringles</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Grid>
            </Grid>
            <Grid item md={12} mt={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  background: "rgba(61, 190, 114, 0.3)",
                  borderRadius: 20,
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "5px 20px",
                  border: "2px solid rgba(61, 190, 114, 1)",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Create new Campaign
              </Button>
            </Grid>
          </Grid>

          <Grid item md={8}>
            <Grid item md={12} align="end" p={2}>
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => setShowCampaign(false)}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container p={4}>
              <Grid item md={12} display="flex">
                <img
                  width={60}
                  sx={{ borderRadius: "50%" }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                  alt=""
                />
                <Box ml={2}>
                  <Typography fontSize="1.3rem" fontWeight="bold">
                    Kurkure
                  </Typography>
                  <Typography fontSize="0.9rem">
                    We want to increase our yearly sales.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Deliverables
                </Typography>
                <Box>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Reels
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                      margin: "0px 10px",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Posts
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Story
                  </Button>
                </Box>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Creator Preferences
                </Typography>
                <Box>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Male
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                      margin: "0px 5px",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Female
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Both
                  </Button>
                </Box>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography fontWeight={"bold"}>Campaign Budget</Typography>
                <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
                  $1.5k
                </Typography>
              </Grid>
              <Grid item md={12} align="end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#3DBE72",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                  }}
                >
                  Add to Campaign
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      {/* Collection Popup  */}
      {/* <Dialog
        open={showCollection}
        onClose={() => setShowCollection(false)}
        sx={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Grid container>
          <Grid
            item
            md={4}
            p={3}
            justifyContent="space-between"
            sx={{ background: "rgba(61, 190, 114, 0.2)" }}
          >
            <Grid container>
              <Grid item md={12}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Collection
                </Typography>
                <TextField
                  size="small"
                  placeholder="Search here..."
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    background: "#FFFFFF",
                    marginTop: 2,
                    marginBottom: 5,
                    boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <nav aria-label="secondary mailbox folders">
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText>
                          <span>Food Influencers</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Lays</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Pringles</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Grid>
            </Grid>
            <Grid item md={12} mt={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  background: "rgba(61, 190, 114, 0.3)",
                  borderRadius: 20,
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "5px 20px",
                  border: "2px solid rgba(61, 190, 114, 1)",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Create new Collection
              </Button>
            </Grid>
          </Grid>

          <Grid item md={8}>
            <Grid item md={12} align="end" p={2}>
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => setShowCollection(false)}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container p={4}>
              <Grid item md={12} display="flex">
                <AvatarGroup
                  max={4}
                  total={147}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    alt="Remy Sharp"
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                    }
                  />
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    alt="Travis Howard"
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                    }
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Agnes Walker"
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                    }
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Trevor Henderson"
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                    }
                  />
                </AvatarGroup>
                <Box ml={2}>
                  <Typography fontSize="1.3rem" fontWeight="bold">
                    Food Influencers
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mt={2}>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                    marginRight: 10,
                  }}
                  size="small"
                  variant="outlined"
                >
                  Food & Drinks
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                  }}
                  size="small"
                  variant="outlined"
                >
                  Infotainment
                </Button>
              </Grid>
              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Assigned to Campaign
                </Typography>
                <Typography>Lays Marketing</Typography>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Bio
                </Typography>
                <Typography>
                  Live life on your own tems #jointhemvmt Watches, Eyewear &
                  Premium Accessories For her @mvmforher Shop our Site
                </Typography>
                <span>
                  #Live #life #jointhemvmt #Watches #Eyewear #Premium
                  #Accessories
                </span>
              </Grid>

              <Grid item md={12} align="end">
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#3DBE72",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                  }}
                  onClick={() => addInfluencer()}
                >
                  {isAdded ? "Added to Collection" : "Add to Collection"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog> */}

      <Box sx={{ padding: 4, maxWidth: "40vw", width: "40vw", height: "100%" }}>
        <Dialog open={showDp}>
          <img
            src={row.photo_link}
            alt="profile_photo"
            width={"100%"}
            height={"100%"}
          />

          <IconButton size="small" onClick={() => setShowDp(false)}>
            <Tooltip
              title="Close"
              arrow
              componentsProps={{
                arrow: {
                  sx: {
                    //   backgroundColor: 'red',
                    color: "red",
                  },
                },
                tooltip: {
                  sx: {
                    color: "white",
                    backgroundColor: "red",
                    // fontSize: "0.9rem",
                  },
                },
              }}
            >
              <CancelOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Dialog>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Typography
            sx={{
              color: "#3DBE72",
              fontWeight: "bold",
              fontSize: "1.6rem",
              marginBottom: "1.5rem",
            }}
          >
            Find Influencer
          </Typography>
          <Tooltip
            title="close"
            arrow
            componentsProps={{
              arrow: {
                sx: {
                  //   backgroundColor: 'red',
                  color: "red",
                },
              },
              tooltip: {
                sx: {
                  color: "white",
                  backgroundColor: "red",
                  // fontSize: "0.9rem",
                },
              },
            }}
          >
            <IconButton onClick={() => close(false)}>
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container>
          <Grid item marginRight={3}>
            <Box
              position="relative"
              onMouseEnter={() => setShowOverlay(true)}
              onMouseLeave={() => setShowOverlay(false)}
              onClick={handleClickDP}
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={row.photo_link ? row.photo_link : dp}
                alt={row.name}
                sx={{
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  border: 3,
                  borderColor: "#3DBE72",
                }}
              />
              {showOverlay && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "34%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    // borderRadius: '50%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  {/* Your overlay content goes here */}
                  <span
                    style={{
                      color: "#3DBE72",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    View
                  </span>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item size={"grow"}>
            <Box sx={{ width: "100%" }}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <Box width={"auto"}>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {row.name}
                  </Typography>
                </Box>
                <VerifiedIcon color={"primary"} />
                <Box display={"flex"} flexGrow={1}>
                  {/* <IconButton size="large">
                    <img src={insta_sm} alt="" width={25} />
                  </IconButton> */}
                  <IconButton
                    size="small"
                    target="_blank"
                    href={`https://www.youtube.com/channel/${
                      row?.channel_id ? row?.channel_id : ""
                    }`}
                  >
                    <img src={youtube_sm} alt="" width={26} />
                  </IconButton>
                  {/* <IconButton size="large">
                    <img
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
                      }
                      alt=""
                      width={25}
                    />
                  </IconButton>
                  <IconButton size="large">
                    <img
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
                      }
                      alt=""
                      width={27}
                    />
                  </IconButton> */}
                </Box>
              </Box>
              <Box>
                <Link
                  component={RouterLink}
                  to={`/dashboard/find-influencers/profile/${encodedId}`}
                  target="_blank"
                  underline="none"
                >
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    fontSize={"1rem"}
                  >
                    {row.handle}
                  </Typography>
                </Link>
                <Typography fontSize={"0.9rem"} sx={{ color: "#7b7b7b" }}>
                  {selectedSocial === "youtube"
                    ? formatNumber(row.subscriber) + " Subscribers"
                    : selectedSocial === "instagram"
                    ? formatNumber(row.followers)
                    : ""}
                </Typography>
                {row?.gender && (
                  <Typography
                    fontWeight={"bold"}
                    textTransform={"uppercase"}
                    fontSize={"0.8rem"}
                  >
                    {row?.gender}
                  </Typography>
                )}
              </Box>
              {/* <Box sx={{ marginLeft: 10 }}>
                <IconButton
                  sx={{
                    color: "#3DBE72",
                    padding: 5,
                    border: "1px solid #3DBE72",
                  }}
                  aria-label="add to shopping cart"
                >
                  <TurnRightRoundedIcon sx={{ fontSize: 29 }} />
                </IconButton>
              </Box> */}
              {/* <Button
                onClick={() => setShowCampaign(true)}
                variant="outlined"
                sx={{
                  padding: "5px 20px",
                  borderRadius: 20,
                  fontSize: "11px",
                  color: "#3DBE72",
                  fontWeight: "bold",
                  textTransform: "none",
                  marginRight: "10px",
                  "&:hover": { borderColor: "#3DBE72" },
                }}
              >
                Add to Campaign
              </Button> */}
            </Box>
            <Box marginTop={1}>
              {row?.["influencer_category"] != "" &&
                row?.["influencer_category"] && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    alignItems={"center"}
                    gap={1}
                  >
                    {row?.influencer_category
                      ?.replace(/'/g, '"')
                      .split(",")
                      .map((category, index) => (
                        <Chip
                          key={"influencer-topic"}
                          component={"span"}
                          sx={{ borderRadius: 2 }}
                          label={category?.trim()}
                        />
                      ))}
                  </Box>
                )}
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              gap={1}
              flexGrow={1}
              marginTop={1}
            >
              <Button
                onClick={showSharepopup}
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 20,
                  height: "min-content",
                  fontSize: 12,
                  color: "white",
                  fontWeight: "bold",
                  background: "#3DBE72",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                Share
              </Button>

              {/* <IconButton
                onClick={() => setShowCollection(true)}
                sx={{ color: "#3DBE72" }}
                aria-label="add to shopping cart"
              >
                <AddCircleRoundedIcon sx={{ padding: 0, fontSize: 25 }} />
              </IconButton> */}
              <Box component={"span"}>
                {/* <ClickAwayListener onClickAway={handleClose}> */}
                <IconButton
                  onClick={handleClick}
                  sx={{ color: "#3DBE72", padding: 0 }}
                  aria-label="add to shopping cart"
                >
                  <AddCircleRoundedIcon sx={{ fontSize: 32 }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                >
                  <MenuItem
                    onClick={() => setShowCampaign(true)}
                    sx={{
                      // borderRadius: 20,
                      // fontSize: "11px",
                      color: "#3DBE72",
                      // fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Add to Campaign
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleAddToCollectionDialogOpen();
                      setInfluencerId((prev) => [...prev, row?.id]);
                    }}
                    sx={{
                      // borderRadius: 20,
                      // fontSize: "11px",
                      color: "#3DBE72",
                      // fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Add to Collection
                  </MenuItem>
                </Menu>
                {/* </ClickAwayListener> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          borderRadius={4}
          padding={3}
          marginTop={2}
          sx={{
            background: "#F2F7FF",
          }}
        >
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Avatar
              src={row.photo_link ? row.photo_link : dp}
              alt={row.name}
              sx={{ width: 60, height: 60 }}
            />
            <Box sx={{ marginLeft: 2 }}>
              <Typography fontWeight={'bold'} fontSize={'1rem'}>
                {row.handle}
              </Typography>
              <Typography fontSize={'0.8rem'}>
                {selectedSocial === 'youtube' ? formatYouTubeSubscribers(row.subscriber) + " Subscribers" : selectedSocial === 'instagram' ? formatNumber(row.followers) + " Followers" : ''}
              </Typography>
              <Typography textTransform={'capitalize'} fontSize={'0.8rem'}>
                <Typography textTransform={'uppercase'} component={'span'} fontWeight={'bold'} fontSize={'0.8rem'}>Gender: </Typography>
                {row.gender}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ margin: "10px 0px" }}>
            {row.description}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {JSON.parse(row?.hashtags ? row?.hashtags?.replace(/'/g, '"') : null)?.join(", ")}
          </Typography>
        </Box> */}
        <AccountAnalytics
          ID={row.id}
          selectedSocial={selectedSocial}
          data={row}
          mediaData={influencerData?.media}
        />
        {/* <Box sx={{ background: '#F2F7FF', borderRadius: 20, padding: 30, marginTop: 30, }}>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 15 }} >Account Analytics</Typography>
                        <Button variant="contained" sx={{ padding: '5px 20px', borderRadius: 30, fontSize: '10px', color: "#FFFFFF", fontWeight: 'bold', background: '#3DBE72', boxShadow: 'none' }}>View Full Report</Button>
                    </Box>


                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>ER</Typography>
                                <Box>
                                    <span sx={{ fontSize: 8 }}>Average</span>
                                    <IconButton sx={{ color: '#3DBE72' }}>

                                        <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.7rem' }} >0.12%</Typography>
                        </Box>

                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Suggested Price</Typography>
                                <IconButton sx={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.7rem' }} >$1.5K - $3k</Typography>
                        </Box>
                    </Box>



                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Average Likes</Typography>
                                <IconButton sx={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.7rem' }} >266</Typography>
                        </Box>

                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Average Comments</Typography>
                                <IconButton sx={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.7rem' }} >6</Typography>
                        </Box>
                    </Box>




                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Brand Mentions</Typography>
                                <IconButton sx={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography > <span sx={{ fontWeight: 'bold', fontSize: '1.7rem' }}>O</span> <span>in last 180d</span></Typography>
                        </Box>

                        <Box sx={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Yearly Growth</Typography>
                                <Box>
                                    <span sx={{ fontSize: 8 }}>Low</span>
                                    <IconButton sx={{ color: '#3DBE72' }}>
                                        <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.7rem' }} >-0.8%</Typography>
                        </Box>
                    </Box>





                </Box>
 */}
      </Box>
    </>
  );
};

export default InfluencerProfile;
