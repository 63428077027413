import { memo, useEffect, useMemo, useState } from "react";
import React from "react";

// MUI Components
import {
  Box,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

// Icons
import { ExpandMore, HelpOutlineRounded } from "@mui/icons-material";
import MinMaxWithDropdown from "../customInputComponents/minMaxWithDropdown";

const MinMaxDropdown = memo(
  ({
    title,
    selector,
    tooltipInfo,
    setDataMethod,
    data,
    clearIt,
    predefinedOptions = [],
  }) => {
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    // const [forVal, setForValue] = useState("");
    const [inVal, setInVal] = useState("");

    useMemo(() => {
      if (data) {
        if (data.min || data.max || data.in) {
          setMinValue(data.min.toString());
          setMaxValue(data.max.toString());
          // setForValue(data.for.toString());
          setInVal(data.in.toString());
        }
      }
      console.log("incoming data memo running");
    }, [data]);

    useEffect(() => {
      if (minValue !== "" || maxValue !== "" || inVal !== "") {
        setDataMethod({
          min: minValue.toString().replace(/'/g, '"'),
          max: maxValue.toString().replace(/'/g, '"'),
          // for: forVal.toString().replace(/'/g, '"'),
          in: inVal.toString().replace(/'/g, '"'),
        });
      } else {
        setDataMethod(null);
      }
    }, [minValue, maxValue, inVal]);

    const clearMinMax = () => {
      setMinValue("");
      setMaxValue("");
      // setForValue("");
      setInVal("");
    };

    const swapMinMax = () => {
      if (minValue !== "" || maxValue !== "") {
        setMinValue(maxValue);
        setMaxValue(minValue);
      }
    };

    useEffect(() => {
      clearIt(() => clearMinMax);
    }, [clearIt]);

    return (
      <Accordion sx={{ boxShadow: "none" }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <IconButton style={{ color: "#3DBE72" }}>
              <Tooltip title={tooltipInfo}>
                <HelpOutlineRounded
                  style={{ fontSize: 14, padding: 0, margin: 0 }}
                />
              </Tooltip>
            </IconButton>
            <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0, textAlign: "start" }}>
          <MinMaxWithDropdown
            minValue={minValue}
            maxValue={maxValue}
            onMinChange={setMinValue}
            onMaxChange={setMaxValue}
            // Optional props
            minPlaceholder="min"
            maxPlaceholder="max"
            disabled={false}
            size="small"
            error={false}
            fullWidth={false}
            // leftTextLabel="For"
            // leftTextValue={forVal}
            // onLeftTextChange={setForValue}
            rightSelectLabel="In"
            rightSelectValue={inVal}
            onRightSelectChange={setInVal}
            selectOptions={predefinedOptions}
          />
        </AccordionDetails>
      </Accordion>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default MinMaxDropdown;
