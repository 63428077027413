import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;

export const updateCampaign = async ({ data }) => {
  try {
    const response = await axios.post(`${URL}/campaign/update`, data);
    // const data = response.data.campaigns;
    console.log("campaign", response);
    // if (data) {
    //   // console.log("campaign data: ", data);
    //   return data;
    // } else {
    //   console.error("Error: collections is empty");
    //   return false;
    // }
  } catch (error) {
    console.error("error occured while updating campaign : " + error.message);
  }
};
