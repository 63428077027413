import React from "react";
import {
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

const InputWithDropdown = ({
  disabled = false,
  size = "small",
  fullWidth = false,
  allowDecimals = false,
  allowNegative = false,
  // Modified left field props
  leftTextValue,
  onLeftTextChange,
  leftTextLabel = "Left Input",
  // Right select props
  rightSelectValue,
  onRightSelectChange,
  selectOptions = [],
  rightSelectLabel = "Right Select",
  title,
  type,
}) => {
  // Validate number input
  const validateNumber = (value) => {
    if (value === "") return true;
    if (allowDecimals) {
      return allowNegative
        ? /^-?\d*\.?\d*$/.test(value)
        : /^\d*\.?\d*$/.test(value);
    }
    return allowNegative ? /^-?\d*$/.test(value) : /^\d*$/.test(value);
  };

  const handleLeftTextChange = (event) => {
    const value = event.target.value;
    if (type === "number") {
      if (validateNumber(value)) {
        onLeftTextChange(value);
      }
    } else onLeftTextChange(value);
    console.log("input: ", value);
  };

  const handleRightSelectChange = (event) => {
    const value = event.target.value;
    onRightSelectChange(value);
    console.log("dropdown: ", value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          width: fullWidth ? "100%" : "auto",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", gap: 2, alignItems: "center" }}
        >
          {/* <Typography sx={{ fontWeight: 500 }}>{title}</Typography> */}
          <FormControl size={size} disabled={disabled} sx={{ width: "100%" }}>
            <InputLabel>{rightSelectLabel}</InputLabel>
            <Select
              name={rightSelectLabel}
              value={rightSelectValue}
              onChange={handleRightSelectChange}
              label={rightSelectLabel}
            >
              {selectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TextField
          placeholder={`${type === "number" ? "Enter days" : "Enter keywords"}`}
          label={leftTextLabel}
          value={leftTextValue}
          onChange={handleLeftTextChange}
          size={size}
          type={type}
          disabled={disabled}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default InputWithDropdown;
