const campaign = [
  {
    id: 1,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "70.5%",
    },
  },
  {
    id: 2,
    title: "Mazza Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "60.5%",
    },
  },
  {
    id: 3,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "90%",
    },
  },
  {
    id: 4,
    title: "Mazza Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "60.5%",
    },
  },
  {
    id: 5,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "70.5%",
    },
  },
  {
    id: 6,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "70.5%",
    },
  },
  {
    id: 7,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "70.5%",
    },
  },
  {
    id: 8,
    title: "Kurkure Campaign",
    subtitle: "Marketing campaign by Parle",
    dp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzXNKNvZoeIkHElQ_ZK5Aaxve49qnWGQxU6pX3TV8BHhxmOWZepbhzDR_zh5r4kpqUp9c&usqp=CAU",
    details: {
      status: "In Progress",
      completion: "70.5%",
    },
  },
];

export { campaign };
