import React, { memo, useEffect, useMemo, useState } from "react";
import { Box, Button, Popover, InputLabel, FormControl } from "@mui/material";
import InputWithDropdown from "../customInputComponents/inputWithDropdown";

const InputWithDropdownFrontFilter = memo(
  ({
    title,
    label,
    placeholder,
    type,
    setDataMethod,
    data,
    clearIt,
    predefinedOptions = [],
  }) => {
    // const [selectedValues, setSelectedValues] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const [forVal, setForValue] = useState("");
    const [inVal, setInVal] = useState("");

    // useMemo(() => {
    //   console.log("input with dropdown front filter data: ", data);

    //   if (data) {
    //     const updatedData = data.map((option) => ({
    //       label: option,
    //       value: option,
    //     }));
    //     // setSelectedValues(updatedData);
    //   }
    // }, [data]);

    // const handleChange = (newValues) => {
    //   setSelectedValues(newValues);
    // };

    // useEffect(() => {
    //   if (selectedValues.length > 0) {
    //     setDataMethod(selectedValues.map((option) => option.value));
    //   } else {
    //     setDataMethod(null);
    //   }
    // }, [selectedValues]);

    const clearAll = () => {
      //   setSelectedValues([]);
      setForValue("");
      setInVal("");
    };

    useEffect(() => {
      clearIt(() => clearAll);
    }, [clearIt]);

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "keyword-popover" : undefined;

    // const [minValue, setMinValue] = useState("");
    // const [maxValue, setMaxValue] = useState("");

    useMemo(() => {
      if (data) {
        if (data.in && data.for) {
          //   setMinValue(data.min.toString());
          //   setMaxValue(data.max.toString());
          setForValue(data.for.toString());
          setInVal(data.in.toString());
        }
      }
    }, [data]);

    useEffect(() => {
      if (forVal !== "" && inVal !== "") {
        setDataMethod({
          //   min: minValue.toString().replace(/'/g, '"'),
          //   max: maxValue.toString().replace(/'/g, '"'),
          for: forVal.toString().replace(/'/g, '"'),
          in: inVal.toString().replace(/'/g, '"'),
        });
      } else {
        setDataMethod(null);
      }
    }, [forVal, inVal]);

    return (
      <FormControl sx={{ m: 1, minWidth: 80, width: 120 }} size="small">
        <Button
          aria-describedby={id}
          onClick={handleOpen}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 20,
            paddingLeft: 2,
            border: "1px solid lightgrey",
            textTransform: "capitalize",
          }}
        >
          <InputLabel id="multi-select-label">{title}</InputLabel>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              width: 150,
              minWidth: 80,
            }}
          >
            <InputWithDropdown
              disabled={false}
              size="small"
              fullWidth={false}
              leftTextLabel={title}
              leftTextValue={forVal}
              onLeftTextChange={setForValue}
              rightSelectLabel="In"
              rightSelectValue={inVal}
              onRightSelectChange={setInVal}
              selectOptions={predefinedOptions}
              title={title}
              type={type}
            />
            <Button sx={{ mt: 2 }} variant="outlined" onClick={clearAll}>
              Clear
            </Button>
          </Box>
        </Popover>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default InputWithDropdownFrontFilter;
