import React from "react";
import { assignedCampaign } from "./collectionData";
import { Box, Typography, Avatar, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

const AssignedCampaign = () => {
  return (
    <Grid container p={2} sx={{ bgcolor: "#f2f7ff", borderRadius: 4, mt: 3 }}>
      <Grid item size={{ md: 9, xs: 9 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          Assigned Campaign
        </Typography>
      </Grid>
      <Grid
        item
        size={{ md: 3, xs: 3 }}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: 8,
            boxShadow: "none",
            bgcolor: "#3dbf73ff",
            "&:hover": { boxShadow: "none", bgcolor: "#3dbf73ff" },
          }}
        >
          View Full Report
        </Button>
      </Grid>
      <Grid item size={{ md: 12, xs: 12 }} mt={3}>
        {assignedCampaign?.map((item) => (
          <Grid container key={item.id} mb={2}>
            <Grid
              item
              size={{ md: 1, xs: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar alt="Remy Sharp" src={item.img} />
            </Grid>
            <Grid
              item
              size={{ md: 8, xs: 8 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography fontWeight={"bold"} fontSize={"1rem"}>
                Kurkure
              </Typography>
              <Typography fontSize={".9rem"}>
                Targetr : 1.23 Million Conversations
              </Typography>
            </Grid>
            <Grid item size={{ md: 3, xs: 3 }} align="center">
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: "none",
                  borderRadius: 8,
                  color: "#3dbf73ff",
                  borderColor: "#3dbf73ff",
                  "&:hover": { borderColor: "#3dbf73ff" },
                }}
              >
                View Report
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* {
      assignedCampaign.map((item) => (
        
      ))
    } */}

      {/* <Grid item md={1.5} xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>

      <Avatar alt="Remy Sharp" src='https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg' />
    </Grid>
    <Grid item md={10} xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
      <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
    </Grid> */}
    </Grid>
  );
};

export default AssignedCampaign;
