import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  AvatarGroup,
  Avatar,
  Divider,
  Dialog,
  Drawer,
  IconButton,
  CircularProgress,
  Tooltip,
  ListItem,
  Tab,
  Tabs,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CollectionDrawer from "./CollectionDrawer.jsx";
import { Link } from "react-router-dom";
import {
  Search,
  Add,
  GridViewRounded,
  ViewListRounded,
  HighlightOffOutlined,
  WarningAmberOutlined,
  AddTaskOutlined,
  CancelOutlined,
  FilterListRounded,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import SBicon from "../../images/sb.png";
import LISTicon from "../../images/lst.png";

import CollectionListAndCardView from "./CollectionListAndCardView";
import InfluencerTable from "./InfluencerTable";
import CollectionTable from "./CollectionTable";

// APIs
import {
  fetchMoreCollectionData,
  getAllCollection,
  getAllFilterCollecetion,
  getBrandList,
  getFilteredCollection,
  getSearchedCollection,
} from "../../../service/collections api/getAllCollection";
import { useSelector } from "react-redux";
import { createNewCollection } from "../../../service/collections api/createCollection";
import { getAllBrands } from "../../../service/brands/getAllBrands";

import ShareCollection from "./ShareCollection";
import { getAllInfluencers } from "../../../service/getAllApi";
import AddNewBrand from "./AddNewBrand";
import SearchDropdownFilter from "./SearchDropdownFilter.jsx";

const avt1 =
  "https://st.depositphotos.com/1308396/61552/i/450/depositphotos_615523558-stock-photo-view-sunrise-light-rear-car.jpg";
const avt2 =
  "https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510_640.jpg";
const avt3 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3OOfrBLXPZKaVZ-nwC8p9_dYGFi3jY4ktjQ&usqp=CAU";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const MyInput = styled("input")(`
    background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
    outlined : none;
    border : none;
    width : 100%;
    padding : 15px 10px;
    border-radius : 8px;
    font-size : 15px;
    margin-top : 5px;
`);

const Mylabel = styled("label")(`
    color : #828282ff;
`);

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const Collections = () => {
  const user = useSelector((state) => state.user);
  const id = user?.user?.id;
  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);
  const [sortby, setSortby] = useState(null);
  const [view1, setView1] = useState("list");
  const [allCollection, setAllCollection] = useState([]);
  const [searchedCollection, setSearchedCollection] = useState(allCollection);
  const [newCollection, setNewCollection] = useState({});
  const [displayCollection, setDisplayCollection] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [filteredBrand, setFilteredBrand] = useState(allBrands);
  const [brandID, setBrandID] = useState(null);
  const [searchCollection, setSearchCollection] = useState("");
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [encodedNewCollectionId, setEncodedNewCollectionId] = useState("");

  const [clickedCard, setClickedCard] = useState(null);
  const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterBG, setFilterBG] = useState("#1f2933ff");
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [brand, setBrand] = useState(null);
  const [min_influencer, setMin_influencer] = useState(null);
  const [max_influencer, setMax_influencer] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([
    "Active",
    "Draft",
    "Archived",
  ]);
  const [customColumn, setCustomColumn] = useState([]);

  // active tab logic
  const [tabUnderline, setTabUnderline] = useState("All");

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);

  useEffect(() => {
    const filteredData = allCollection.filter((collection) => {
      if (tabUnderline === "All") {
        return collection;
      } else {
        return collection.status === tabUnderline;
      }
    });
    console.log("filteredData", allCollection);
    setDisplayCollection(filteredData);
  }, [tabUnderline]);

  const handleFilterChange = (e, optionName = null) => {
    const { name, value } = optionName
      ? { name: optionName, value: e }
      : e.target;

    // Special handling for date because it's an array of start and end date
    if (name === "startdate") setStartDate(value);
    if (name === "enddate") setEndDate(value);
    if (name === "brand") setBrand(value);
    if (name === "min_influencer") setMin_influencer(value);
    if (name === "max_influencer") setMax_influencer(value);
    if (name === "status") {
      if (value !== "All") {
        setSelectedStatus([value]);
      }
      if (value === "All") {
        setSelectedStatus(["Active", "Draft", "Archived"]);
      }
      handleFilter();
    }
  };

  const handleFilter = async () => {
    let date = null;

    // Check if startdate is a valid date, then add it to the date array
    if (!isNaN(new Date(startdate).getTime())) {
      const dateObj = new Date(startdate);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = dateObj.getFullYear();
      date = `${day}-${month}-${year}`;
    }
    // Construct the payload
    const payload = {
      date,
      brand,
      min_influencer,
      max_influencer,
      limit: rowsPerPage,
    };

    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value != null)
    );

    console.log(filteredPayload);

    // Here you would use the payload to make your API call or whatever next steps are required
    try {
      const response = await getAllFilterCollecetion(filteredPayload);

      console.log("filterresponse", response.collection);
      setDisplayCollection(response.collection);
      // console.log("filter response", response);
      setShowFilterOptions(false);
      setLoading(false); // Replace filterCollectionApi with your actual API call function
      // Handle the response
    } catch (error) {
      console.error("Error filtering collections:", error);
      setDisplayCollection(allCollection);
      alert("No data found");
      setLoading(false);
      // Handle the error
    }
  };

  const handleCardClick = (card) => {
    // console.log(card);
    setClickedCard(card);
    // setOpenDrawer(true);
  };

  const handleCardClick1 = (card) => {
    setClickedCard(card);
    setOpenDrawer(true);
  };

  // console.log("searched collection : ", displayCollection);

  const [tabBtn, setTabBtn] = React.useState(0);

  const handleViewChange = (event, newView) => {
    setView1(newView);
  };

  const handleSortbyChange = (e) => {
    setSortby(e.target.value);
  };

  const closeCreateCollectionDialog = () => {
    setCreateCollectionDialog(false);
  };
  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const [loading, setLoading] = useState(false);
  // const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });
  const [collection, setCollection] = useState({
    name: "",
    description: "",
    assigned_brand_id: 1,
    influncers_id: [],
    created_by: id,
  });

  const handleListItemClick = (name, id) => {
    setBrandName(name);
    console.log("brand name ", brandName);

    setCollection((prevCollection) => {
      console.log("Updated collection:", {
        ...prevCollection,
        assigned_brand_id: id,
      });
      return { ...prevCollection, assigned_brand_id: id };
    });

    console.log("Clicked on:", name, "with ID:", id);
    console.log("payload collectin :", collection);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCollection((prevCollection) => ({
      ...prevCollection,
      [name]: value,
    }));
    // console.log(collection);
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(value);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)

    console.log(value);
  };

  //get all collection:

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newPageAdded, setNewPageAdded] = useState(1);
  const [customHide, setCustomHide] = useState(false);

  const getCollection = async () => {
    try {
      const response = await getAllCollection({
        limit: rowsPerPage,
      });
      console.log("all collection : ", response);
      setAllCollection(response.collection);
      setDisplayCollection(response.collection);
      setCustomColumn(response.customcolumn);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const handleNewCollection = (newCollection) => {
    setAllCollection([...allCollection, newCollection]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    console.log("******collection data: *******", collection);
    try {
      const response = await createNewCollection(collection);
      setNewCollection(response);
      // Encryption
      // Base64 encode function
      const encodeId = (id) => btoa(id.toString());
      const encodedId = encodeId(response.id);
      setEncodedNewCollectionId(encodedId);
      console.log("Encoded ID:", encodedId);
      // handleNewCollection(response);
      // getCollection();
      // setAllCollection(preCollection => [...preCollection, response])

      setIsSuccessful(true);
      handleNewCollection();
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection already exists.", status: true });
      console.error("Error while submiting new collection data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setCreateCollectionDialog(false);
    setIsBrandFocus(false);
    setBrandName("");
    setCollection({
      name: "",
      description: "",
      assigned_brand_id: null,
      influncers_id: [],
      created_by: id,
    });
    // handleNewCollection(newCollection);
    // getCollection();
  };

  const getBrands = async () => {
    try {
      const response = await getBrandList();
      const data = response.data.Brands;
      setAllBrands(data);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const [allInfluencers, setAllInfluencers] = useState([]);

  const getInfluencers = async () => {
    try {
      // setFetchingInfluencer(true);
      const response = await getAllInfluencers();
      console.log(response);
      // dispatch(setInfluencers(response));
      setAllInfluencers(response.users);
    } catch (error) {
      console.error("Error fetching influencers:", error);
      // Handle the error (e.g., display an error message)
    } finally {
      // setFetchingInfluencer(false)
    }
  };

  // Function to get the name of the brand by its ID
  function getBrandNameById(brandId) {
    // Find the brand object with the matching ID
    const brand = allBrands?.find((brand) => brand.id == brandId);

    // If the brand is found, return its name, otherwise return null or handle it as needed
    return brand ? brand.name : "Not found";
  }

  // Function to get influencer names from influencer IDs
  function getInfluencersNamesById(influencersIds, influencersState) {
    // Initialize an empty array to store influencer names
    const influencersNames = [];

    console.log("influencersIds", influencersIds);
    console.log("influencersState", influencersState);

    // Parse the string representation of influencer IDs into an array of numbers
    const parse_influencersIds = JSON.parse(influencersIds);

    // Iterate over the influencer IDs
    parse_influencersIds.forEach((influencerId) => {
      // Find the influencer object with the matching ID from the state
      const influencer = influencersState.find(
        (influencer) => influencer.id === influencerId
      );
      // If influencer found, push its name to the names array
      if (influencer) {
        influencersNames.push(influencer.name);
      }
    });

    // Return the array of influencer names
    return influencersNames;
  }

  const handleSearch = async (e) => {
    const { value } = e.target;
    console.log("value", value);
    if (value.length >= 3) {
      try {
        const data = await getSearchedCollection(value);
        setDisplayCollection(data);
      } catch (error) {
        console.log("Error fetching searched collection", error);
      }
    } else {
      setDisplayCollection(allCollection);
    }
  };

  useEffect(() => {
    console.log("Collections list is upto date");
    getCollection();
    getBrands();
    getInfluencers();
  }, [newCollection, rowsPerPage, customHide]);

  // const [addNewBrandDialog, setAddNewBrandDialog] = useState(false)

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };

  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  // row select logic
  const [selectedRowArr, setSelectedRowArr] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (row) => {
    // Update the selectedRow state when a row is clicked
    setSelectedRow(row);

    // You can perform additional actions or navigate to a detail page, etc.
    console.log("Clicked Row:", row);
  };

  // Add a ref to the outermost box of the filter options
  const filterRef = useRef(null);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const IsMiniOpen = useSelector((state) => state.sharedState.isOpen);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenSize(window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    console.log("brandName", brandName);
  }, [brandName]);

  return (
    <>
      {/* Create Collection Diaglog  */}
      <Dialog
        open={createCollectionDialog}
        onClose={handleClose}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
      >
        {isSucessful ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
          >
            <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "#3dbe72ff",
                marginTop: 2,
              }}
            >
              Collection Successfully Created.
            </Typography>

            <Box
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: "#3dbe72ff",
                  // color : '#3dbe72ff',
                  fontWeight: "bold",
                  // border: '1px solid black',
                  boxShadow: "none",
                  textTransform: "none",
                  border: "1px solid #3dbe72ff",
                  marginRight: 1,
                  "&:hover": {
                    bgcolor: "#3dbe72ff",
                    color: "white",
                    borderColor: "black",
                  },
                }}
                onClick={() => handleClose()}
              >
                Close
              </Button>

              <Link to={`/dashboard/collection/${encodedNewCollectionId}`}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    // bgcolor: '#3dbe72ff',
                    color: "#3dbe72ff",
                    fontWeight: "bold",
                    borderColor: "#3dbe72ff",
                    boxShadow: "none",
                    textTransform: "none",
                    marginRight: 1,
                    transition: ".4s",
                    "&:hover": {
                      bgcolor: "#3dbe72ff",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                >
                  Go to Collection
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <Grid container p={4}>
            <Grid
              item
              size={{ md: 11, xs: 11 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#4caf50",
                }}
              >
                Create new Collection
              </Typography>
              {error.status && (
                <Box>
                  <p style={{ color: "#e81e61ff", fontSize: 13 }}>
                    {error.message}
                  </p>
                </Box>
              )}
            </Grid>
            <Grid
              item
              size={{ md: 1, xs: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Tooltip title="Close" arrow placement="left">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateCollectionDialog(false);
                    handleClose();
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item size={12}>
              <form onFocus={() => getBrands()} onSubmit={handleSubmit}>
                <Box mt={2}>
                  <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                  <Box>
                    <MyInput
                      type="text"
                      name="name"
                      id="collection"
                      placeholder="Enter the Name of collection"
                      onChange={(e) => handleChange(e)}
                      required
                      onFocus={() => setIsBrandFocus(false)}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="brand">Brand Name</Mylabel>
                  <Box position={"relative"}>
                    <MyInput
                      type="text"
                      name="brand_name"
                      id="brand"
                      placeholder="search the brand "
                      required
                      onChange={(e) => handleBrandChange(e)}
                      onFocus={() => {
                        setIsBrandFocus(true);
                      }}
                      // onFocusCapture={() => setIsBrandFocus(true)}
                      // onBlur={() => setIsBrandFocus(false)}
                      value={brandName}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                      onClick={openAddBrandDialog}
                    >
                      <Tooltip title="Add a new Brand" arrow>
                        <Add />
                      </Tooltip>
                    </IconButton>
                    {isBrandFocus && (
                      <Box
                        sx={{
                          padding: "5px 10px",
                          position: "absolute",
                          width: "100%",
                          background: "white",
                          marginTop: 1,
                          borderRadius: 2,
                          maxHeight: "140px",
                          overflowY: "scroll",
                          zIndex: 10,
                        }}
                      >
                        {allBrands?.reverse()?.map(({ name, id }) => {
                          return (
                            <ListItem
                              key={id}
                              component="div"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleListItemClick(name, id);
                                setIsBrandFocus(false);
                              }}
                            >
                              {name}
                            </ListItem>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="description">Description</Mylabel>
                  <Box>
                    <MyTextArea
                      rows={3}
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Write short description"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "none",
                      borderRadius: 10,
                      fontWeight: "bold",
                      marginRight: 1,
                      background: "#c5edd6ff",
                      borderWidth: "2px",
                      color: "black",
                      borderColor: "#3dbf73ff",
                      "&:hover": {
                        borderWidth: "2px",
                        borderColor: "#3dbf73ff",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                      boxShadow: "none",
                      fontWeight: "bold",
                      borderRadius: 10,
                      // padding: '10px 30px',
                      "&:hover": {
                        bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                      },
                    }}
                    endIcon={
                      loading ? (
                        <LoadingSpinner size={25} />
                      ) : isSucessful ? (
                        <AddTaskOutlined />
                      ) : (
                        error.status && <WarningAmberOutlined />
                      )
                    }
                    disabled={loading || isSucessful}
                  >
                    {isSucessful ? "Created" : "Create Collection"}
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
      </Dialog>

      <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
        <AddNewBrand close={closeAddBrandDialog} />
      </Dialog>

      <Container>
        <Grid container sx={{}}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            mt={2}
            item
            size={{ md: 8, xs: 12 }}
            p={3}
          >
            <Typography sx={{ fontSize: "2rem", fontWeight: "bold" }}>
              Collection
            </Typography>
            <Typography sx={{ fontSize: ".9rem" }}>
              Curanted Influencers : A Diverse Collection of Trendsetters
              Shaping the Digital Landscape
            </Typography>
          </Grid>
          <Grid
            p={3}
            item
            mt={{ md: 5, xs: 2 }}
            size={{ md: 4, xs: 12 }}
            align="end"
          >
            <Box mt={1}>
              <Button
                variant="contained"
                onClick={openCreateCollectionDialog}
                // fullWidth
                sx={{
                  background: "#1f2933ff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  boxShadow: "none",
                  "&:hover": {
                    background: "#1f2933ff",
                  },
                }}
                startIcon={<Add />}
              >
                Create Collection
              </Button>
            </Box>
          </Grid>
          <Grid item size={12} p={3}>
            <Grid container>
              <Grid item size={5}>
                <TextField
                  size="small"
                  outline="false"
                  placeholder="Search by collection name"
                  // onChange={handleSearch}
                  onChange={handleSearch}
                  fullWidth
                  sx={{
                    // Adjust the value as needed for your desired border radius

                    "& .MuiOutlinedInput-root": {
                      // Adjust the value as needed for your desired border radius
                      borderRadius: 8,
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "#3dbf73ff", // Set the border color for hover state
                        boxShadow: "0 0 10px rgba(61, 191, 115, .5)",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#3dbf73ff", // Set the border color for hover state
                        boxShadow: "0 0 10px rgba(61, 191, 115, .5)",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item size={7}></Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Box
          p={3}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 3,
            },
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              width: "70%",
            }}
          >
            <ToggleButtonGroup
              value={view1}
              exclusive
              onChange={handleViewChange}
              aria-label="text alignment"
              size="small"
            >
              <ToggleButton
                value="list"
                style={{
                  color: view1 === "list" ? "#3dbf73ff" : "inherit",
                  border: view1 === "list" ? "2px solid #3dbf73ff" : "inherit",
                }}
              >
                <ViewListRounded />
              </ToggleButton>
              <ToggleButton
                value="card"
                style={{
                  color: view1 === "card" ? "#3dbf73ff" : "inherit",
                  border: view1 === "card" ? "2px solid #3dbf73ff" : "inherit",
                }}
              >
                <GridViewRounded />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              variant="contained"
              onClick={() => {
                setShowFilterOptions(!showFilterOptions);
                filterBG === "#1f2933ff"
                  ? setFilterBG("#3DBE72")
                  : setFilterBG("#1f2933ff");
              }}
              sx={{
                marginLeft: "10px",
                background: filterBG,
                fontWeight: "bold",
                borderRadius: 8,
                boxShadow: "none",
                "&:hover": {
                  background: "#3DBE72",
                },
              }}
              startIcon={<FilterListRounded />}
            >
              Filter
            </Button>
            {showFilterOptions && (
              <Box
                ref={filterRef}
                sx={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: { sm: "80%", xs: "100%" },
                  background: "#ffffff",
                  zIndex: 999,
                  padding: 2,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IconButton
                  sx={{
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                  onClick={() => setShowFilterOptions(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    type="date"
                    label="Created At"
                    value={startdate}
                    name="startdate"
                    onChange={handleFilterChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 150, mr: 2, mt: 2 }}
                  />
                  <SearchDropdownFilter
                    setBrand={setBrand}
                    options={allBrands}
                    text="Brand Name"
                  />

                  <TextField
                    type="number"
                    label="Min Influencers"
                    value={min_influencer}
                    name="min_influencer"
                    onChange={handleFilterChange}
                    placeholder="Min"
                    sx={{ width: 150, mr: 2, mt: 2 }}
                  />
                  <TextField
                    type="number"
                    label="Max Influencers"
                    value={max_influencer}
                    name="max_influencer"
                    onChange={handleFilterChange}
                    placeholder="Max"
                    sx={{ width: 150, mr: 2, mt: 2 }}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowFilterOptions(false);
                        setBrand("");
                        setStartDate("");
                        setEndDate("");
                        setMin_influencer("");
                        setMax_influencer("");
                      }}
                      sx={{
                        background: "#f44336",
                        fontWeight: "bold",
                        borderRadius: 8,
                        boxShadow: "none",
                        mt: 3,
                        mr: 2,
                        "&:hover": {
                          background: "#f44336",
                        },
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setLoading(true);
                        handleFilter();
                      }}
                      sx={{
                        background: "#3DBE72",
                        fontWeight: "bold",
                        borderRadius: 8,
                        boxShadow: "none",
                        mt: 3,
                        mr: 2,
                        "&:hover": {
                          background: "#3DBE72",
                        },
                      }}
                    >
                      {loading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          <Tabs
            className="tabs"
            value={tabUnderline}
            onChange={(e, newValue) => setTabUnderline(newValue)}
            aria-label="icon label tabs example"
            sx={{
              textTransform: "none",
              padding: 0,
              margin: 0,
              marginRight: 1,
            }}
          >
            <Tab
              label="All"
              value="All"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
                borderBottom:
                  tabUnderline === "All" ? "2px solid #3DBE72" : "none",
                mb: -1,
              }}
            />
            <Tab
              label="Active"
              value="Active"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
                borderBottom:
                  tabUnderline === "Active" ? "2px solid #3DBE72" : "none",
                mb: -1,
              }}
            />
            <Tab
              label="Draft"
              value="Draft"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
                borderBottom:
                  tabUnderline === "Draft" ? "2px solid #3DBE72" : "none",
                mb: -1,
              }}
            />
            <Tab
              label="Archived"
              value="Archived"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
                borderBottom:
                  tabUnderline === "Archived" ? "2px solid #3DBE72" : "none",
                mb: -1,
              }}
            />
          </Tabs>
        </Box>

        {view1 === "list" ? (
          // List View
          <Box
            sx={{
              height: "max-content",
              mx: "auto",
              overflowX: "hidden",
              width:
                IsMiniOpen && screenSize > 1442
                  ? "90vw"
                  : !IsMiniOpen && screenSize > 1442
                  ? "80vw"
                  : IsMiniOpen && screenSize <= 1442
                  ? "60vw"
                  : "70vw",
              transition: "width 0.4s ease-in-out", // Smooth transition over 0.3 seconds
            }}
          >
            {displayCollection.length > 0 ? (
              <CollectionTable
                setCustomHide={setCustomHide}
                customHide={customHide}
                handleFilter={handleFilter}
                setRowsPerPage={setRowsPerPage}
                setNewPageAdded={setNewPageAdded}
                customNewColumn={customColumn}
                newAddedCollection={newCollection}
                brands={allBrands}
                searched={
                  displayCollection?.length > 1
                    ? displayCollection?.sort((a, b) => b.id - a.id)
                    : displayCollection
                }
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
        ) : (
          // Grid View
          <>
            <Drawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              anchor="right"
              PaperProps={{
                sx: { width: { md: "60%", xs: "100%" } },
              }}
            >
              <CollectionDrawer
                setOpen={setOpenDrawer}
                sharePopUp={setShareCollectionDrawer}
                data={clickedCard}
              />
              {/* Drawer for share collection  */}
              <Dialog
                open={shareCollectionDrawer}
                onClose={() => setShareCollectionDrawer(false)}
                PaperProps={{
                  sx: { borderRadius: 5, border: "2px solid black" },
                }}
              >
                <ShareCollection
                  sharePopUp={setShareCollectionDrawer}
                  clickedData={clickedCard}
                />
              </Dialog>
            </Drawer>
            <Grid container spacing={3} p={4}>
              {displayCollection
                ?.sort((a, b) => b.id - a.id)
                ?.map((cardDetail, id) => (
                  <Grid
                    item
                    // md={3}
                    size={{
                      xs: 12,
                      sm: 6,
                      lg: 3,
                    }}
                    onClick={() => {
                      handleCardClick(cardDetail);
                    }}
                  >
                    <Grid
                      container
                      p={2}
                      sx={{
                        bgcolor: "#FFFFFF",
                        borderRadius: 5,
                        border: "1px solid black",
                        height: "300px",
                      }}
                      onClick={() => {
                        selectedRowArr.some(
                          (selectedRow) => selectedRow.id === id
                        )
                          ? setSelectedRowArr(
                              selectedRowArr.filter(
                                (selectedRow) => selectedRow.id !== id
                              )
                            )
                          : setSelectedRowArr([
                              ...selectedRowArr,
                              // eslint-disable-next-line no-restricted-globals
                              { id, name },
                            ]);

                        console.log(id);
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor: selectedRowArr.some(
                          (selectedRow) => selectedRow.id === id
                        )
                          ? "#BFFFBF"
                          : "white",
                      }}
                    >
                      <Grid item size={{ xs: 4 }}>
                        <Box display={"flex"} alignItems={"center"}>
                          <AvatarGroup>
                            <Avatar
                              alt="Remy Sharp"
                              src={avt3}
                              sx={{ position: "relative", bottom: "-10px" }}
                            />
                            <Box display={"flex"} flexDirection={"column"}>
                              <Avatar
                                alt="Travis Howard"
                                src={avt2}
                                sx={{
                                  width: 28,
                                  height: 28,
                                  position: "relative",
                                  left: "-5px",
                                  bottom: "-5px",
                                }}
                              />
                              <Avatar
                                alt="Agnes Walker"
                                src={avt1}
                                sx={{
                                  width: 28,
                                  height: 28,
                                  position: "relative",
                                  left: "-15px",
                                  top: "-5px",
                                }}
                              />
                            </Box>
                          </AvatarGroup>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        size={{ xs: 8 }}
                        onClick={() => handleCardClick1(cardDetail)}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {cardDetail?.name}
                        </Typography>
                        <Typography sx={{ fontSize: ".8rem" }}>
                          {cardDetail.influncers_id ? (
                            cardDetail?.influncers_id.length > 0 ? (
                              <>
                                {getInfluencersNamesById(
                                  cardDetail?.influncers_id,
                                  allInfluencers
                                )
                                  .slice(0, 2)
                                  .join(", ")}
                                {cardDetail?.influncers_id.length > 2 &&
                                  `, and ${
                                    cardDetail?.influncers_id.length - 2
                                  } others`}
                              </>
                            ) : (
                              "No influencers"
                            )
                          ) : (
                            "No influencers"
                          )}
                        </Typography>
                        {/* <Typography sx={{ fontSize: '.8rem' }}>Ankur Warikoo, Ashneer Grover... 159 Others</Typography> */}
                      </Grid>
                      <Grid container size={{ xs: 12 }}>
                        <Grid item mt={2} size={{ xs: 4 }} align={"center"}>
                          <Typography
                            sx={{
                              fontSize: ".6rem",
                              fontWeight: "bold",
                              marginBottom: "5px",
                            }}
                          >
                            No. of Influencers
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "bold",
                              wordWrap: "break-word", // Break the word to the next line
                              whiteSpace: "normal", // Allow the text to wrap
                              width: "100%",
                            }}
                          >
                            {cardDetail?.influncers_id
                              ? cardDetail?.influncers_id.length
                              : 0}
                          </Typography>
                        </Grid>
                        {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                        <Grid
                          ml={0.5}
                          item
                          mt={2}
                          size={{ xs: 4 }}
                          align={"center"}
                          sx={{
                            borderLeft: "1px solid black",
                            overflow: "hidden",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: ".6rem", fontWeight: "bold" }}
                          >
                            Status
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "bold",
                              padding: "2px",
                              marginBottom: "5px",
                              wordWrap: "break-word", // Break the word to the next line
                              whiteSpace: "normal", // Allow the text to wrap
                              width: "100%",
                            }}
                          >
                            {cardDetail?.status}
                          </Typography>
                        </Grid>
                        <Grid
                          ml={0.5}
                          item
                          mt={2}
                          size={{ xs: 3 }}
                          align={"center"}
                          sx={{ borderLeft: "1px solid black" }}
                        >
                          <Typography
                            sx={{ fontSize: ".6rem", fontWeight: "bold" }}
                          >
                            Brand
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "bold",
                              padding: "2px",
                              marginBottom: "5px",
                              wordWrap: "break-word", // Break the word to the next line
                              whiteSpace: "normal", // Allow the text to wrap
                              width: "100%",
                            }}
                          >
                            {cardDetail?.assigned_brand_id &&
                              getBrandNameById(cardDetail?.assigned_brand_id)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item size={{ xs: 12 }} mt={3} align={"center"}>
                        <Button
                          sx={{
                            width: "90%",
                            boxShadow: "none",
                            textTransform: "none",
                            bgcolor: "#3DBE72",
                            borderRadius: 10,
                            "&:hover": {
                              bgcolor: "#3DBE72",
                              boxShadow: "none",
                            },
                          }}
                          size="small"
                          variant="contained"
                        >
                          Assign to Brand
                        </Button>
                      </Grid>
                      <Grid item size={{ xs: 12 }} mt={2} align={"center"}>
                        <Button
                          sx={{
                            width: "90%",
                            boxShadow: "none",
                            textTransform: "none",
                            bgcolor: "#3DBE72",
                            borderRadius: 10,
                            "&:hover": {
                              bgcolor: "#3DBE72",
                              boxShadow: "none",
                            },
                          }}
                          size="small"
                          variant="contained"
                        >
                          Add to Campaign
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default Collections;
