import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

const CustomCheckboxGroup = ({
  options = [],
  label = "",
  selectedValues = [],
  onChange,
}) => {
  const handleCheckboxChange = (option) => {
    const isSelected = selectedValues.some(
      (item) => item.value === option.value
    );

    // Update selected array based on checkbox selection
    let updatedSelected;
    if (isSelected) {
      updatedSelected = selectedValues.filter(
        (item) => item.value !== option.value
      );
    } else {
      updatedSelected = [...selectedValues, option];
    }

    onChange(updatedSelected); // Send updated selected options to the parent
  };

  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">{label}</FormLabel> */}
      <FormGroup>
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedValues.some(
                  (item) => item.value === option.value
                )}
                onChange={() => handleCheckboxChange(option)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CustomCheckboxGroup;
