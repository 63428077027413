import React, {
  Profiler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
// import DataTableExtensions from 'react-data-table-component-extensions';
// import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from "@mui/material/CircularProgress";
// import { data, influencers } from "./InfluencersData";
import "../../../../index.css";
import {
  Box,
  Typography,
  Chip,
  IconButton,
  TextField,
  Drawer,
  Paper,
  Tooltip,
  Button,
  styled,
  Dialog,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  AvatarGroup,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  Hidden,
  accordionActionsClasses,
  Skeleton,
  Checkbox,
  Popover,
  Input,
  Pagination,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import CSVExportButton from "./CSVExportButton ";
import CSVicon from "../../../images/csv.png";
import PDFicon from "../../../images/pdf.png";
import SBicon from "../../../images/sb.png";
import LISTicon from "../../../images/lst.png";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import InfluencerProfile from "./InfluencerProfile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import remove, { Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  GroupsRounded,
  TurnedInNotOutlined,
  WindowOutlined,
  Search,
  CancelOutlined,
  ImportExport,
  Add,
  DragIndicator,
  ArrowDownwardSharp,
  ArrowUpward,
  CheckBox,
  MoreHoriz as MoreHorizIcon,
  Share as ShareIcon,
  GetApp as GetAppIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Campaign as CampaignIcon,
  PersonAdd as PersonAddIcon,
  Edit as EditIcon,
  Archive as ArchiveIcon,
} from "@mui/icons-material";
// const TestComponent = () => <CustomERHeader />;
import instaIcon from "../../../images/insta-sm.png";
import updownIcon from "../../../images/arrowupdown.png";
import {
  Container,
  display,
  flexbox,
  fontSize,
  style,
  width,
} from "@mui/system";

import { useSelector } from "react-redux";

// apis
import { getAllCollection } from "../../../../service/collections api/getAllCollection";
import { getCollectionById } from "../../../../service/collections api/getCollectionById";
import { addInfluencerToCollection } from "../../../../service/collections api/addInfluencerToCollection";
import { getUserByID } from "../../../../service/user/getUserByID";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import TotalSelectedInfluencers from "./TotalSelectedInfluencers";
import WhatsAppIcon from "../../../images/WhatsApp.png";
import GmailIcon from "../../../images/Gmail.png";
import ShareCollection from "../../collection/ShareCollection";
import AddNewBrand from "../../collection/AddNewBrand";
import CardComponent from "./CardComponent";
import {
  addNewColumn,
  addNewColumnData,
  getApprovedStatus,
  getColumnHead,
} from "../../../../service/api";
import CustomActions from "./CustomActions";
import { useDispatch } from "react-redux";
import {
  setForId,
  setForTable,
  setName,
  setStatus,
  setValue,
} from "../../../../features/customColumn/CustomColumnSlice";

const TemptDP =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

// list view styling

const Btn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  textTransform: "none",
  fontWeight: "bold",
  marginLeft: "10px",
  "&:hover": { borderColor: "#3DBE72" },
}));

const ITEMS_PER_PAGE = 6;

//card styling below :
const CategorieBtn = styled(Button)(({ theme }) => ({
  marginTop: 4,
  textTransform: "capitalize",
  background: "#3DBE72",
  boxShadow: "none",
  borderRadius: "20px",
  padding: "3px 25px",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3px 15px",
  },
}));

const AddCampaignBtn = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  background: "#3DBE72",
  borderRadius: 20,
  padding: "3px 10px",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    padding: "5px 10px",
  },
}));

const AddCollectionBtn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  borderRadius: 20,
  textTransform: "none",
  padding: "3px 10px",
  boxShadow: "none",
  "&:hover": {
    borderColor: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    padding: "5px 10px",
  },
}));

const avt3 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU";

const CustomAccounts = ({ row, setOpen }) => (
  <Box
    style={{
      pointerEvents: "none",
      opacity: row?.status !== "active" ? 0.5 : 1,
    }}
    display="flex"
    alignItems="center"
    mt={1}
    mb={1}
    width="auto"
  >
    <img
      src={row.photo_link}
      alt=""
      width={50}
      height={50}
      style={{ borderRadius: "50%", overflow: "hidden" }}
    />
    <Typography
      ml={1}
      fontWeight="bold"
      color={row?.status !== "active" ? "GrayText" : ""}
      fontSize="14px"
    >
      {row.name}
    </Typography>
  </Box>
);

const handleAddColumnData = async ({
  newColumnValue,
  columnName,
  columnId,
  rowId,
  currUser,
}) => {
  const data = {
    name: columnName,
    value: newColumnValue,
    for_table: "Influencers",
    for_id: JSON.stringify(rowId),
    column_id: JSON.stringify(columnId),
    status: "active",
    created_by: JSON.stringify(currUser),
  };

  const response = await addNewColumnData(data);
  console.log(response);
};

const CustomNewColumn = ({ row, columnName, columnId, currUser }) => {
  const [newColumnValue, setNewColumnValue] = useState("");
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Input
        onChange={(e) => {
          setNewColumnValue(e.target.value);
        }}
        placeholder="Search"
      />
      {newColumnValue === "" ? null : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            fontSize: "10px",
            padding: "5px !important",
            minWidth: "35px",
            marginLeft: "5px",
          }}
          onClick={() => {
            handleAddColumnData({
              newColumnValue,
              columnName,
              columnId,
              rowId: row.id,
              currUser,
            });
          }}
        >
          Save
        </Button>
      )}
    </Box>
  );
};

const Customcategory = ({ row }) => (
  <Box
    style={{ pointerEvents: "none" }}
    display="flex"
    alignItems="center"
    mt={1}
    mb={1}
    width="auto"
  >
    <Chip
      style={{ pointerEvents: "none", fontSize: "12px" }}
      label={row.influencer_category ? row.influencer_category : "No Category"}
      color="success"
      size="small"
    />
  </Box>
);

const CustomER = ({ row }) => (
  <span style={{ pointerEvents: "none" }}>{row["Engagement Rate"]}</span>
);

const CustomSubscribers = ({ row }) => (
  <span style={{ pointerEvents: "none" }}>{row.subscriber}</span>
);

const AverageLike = ({ row }) => (
  <span style={{ pointerEvents: "none" }}>
    {row["Channel Audience Likers"]}
  </span>
);
const AverageComment = ({ row }) => (
  <span style={{ pointerEvents: "none" }}>{row["Average Comments"]}</span>
);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        backgroundColor: "#3dbf73ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CustomStatus = ({ row, isApproved, onToggle }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <FormControlLabel
      size="medium"
      control={
        <AntSwitch
          sx={{ m: 1 }}
          onClick={() => onToggle(row.id)}
          checked={isApproved}
        />
      }
    />
  </Box>
);

const CustomContact = ({ row }) => (
  <Box display={"flex"}>
    <IconButton size="small">
      <img src={WhatsAppIcon} alt="" width={"100%"} />
    </IconButton>
    <IconButton size="small">
      <img src={GmailIcon} alt="" width={"100%"} />
    </IconButton>
  </Box>
);

const CustomFeedback = ({ row }) => (
  <Box>
    <input
      type="text"
      name=""
      id=""
      style={{
        borderRadius: 5,
        padding: "5px 5px",
        width: "100%",
        border: "none",
        background: "#dfe6e0",
      }}
      placeholder="write here..."
    />
  </Box>
);

const customStyles = {
  head: {
    style: {
      fontSize: "13px", // Set the desired font size for the header
    },
  },
  rows: {
    style: {
      "&:hover": {
        ".rdt_TableCell:first-child": {
          visibility: "visible",
        },
      },
    },
  },
};

const CustomSortIcon = () => (
  // <span >
  //     <ImportExport style={{color : 'black', "&:hover" : {color : 'yellow'}}}/> {/* Icon for ascending sort */}
  //     {/* <ImportExport /> Icon for descending sort */}
  // </span>
  <span>
    <img src={updownIcon} width={15} style={{ margin: "5px" }} />
  </span>
);

const InfluencersDataTable = ({
  influencers,
  fetching,
  fetchMoreInfluencers,
  onScrollFetching,
  selectedSocial,
  extraColumns,
  approvedInf,
  collectionId,
  customColumns,
}) => {
  const [showCollection, setShowCollection] = useState(false);
  const [pending, setPending] = useState(false);
  const [hideBtn, setHideBtn] = useState(false);
  const [selectedRowArr, setSelectedRowArr] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [allCollection, setAllCollection] = useState([]);
  const [aCollection, setCollection] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [views, setViews] = useState(true);
  const [isAdded, setIsAdded] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [createdBy, setCreatedBy] = useState({});
  const [sortedInfluencers, setSortedInfluencers] = useState([]);
  const [ascending, setAscending] = useState(false);
  const [dragOrAdd, setDragOrAdd] = useState("");
  const [searchedUnderAdd, setSearchedUnderAdd] = useState([]);
  const [TotalHidden, setTotalHidden] = useState(false);
  const [influencersData, setInfluencersData] = useState([]);
  const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);

  const [view, setView] = React.useState("1");
  const handleChange = (event, nextView) => {
    setView(nextView);
  };
  const [selectedCards, setSelectedCards] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (influencers.length > 0) {
      setInfluencersData(influencers);
    }
  }, [influencers]);

  const handleClickDragOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [approvedInfluencers, setApprovedInfluencers] = useState([]);

  useEffect(() => {
    try {
      // const parsedApprovedInfluencers = JSON.parse(approvedInf);
      // console.log("Approved influencers: ", parsedApprovedInfluencers);

      setApprovedInfluencers(Array.isArray(approvedInf) ? approvedInf : []);
    } catch (error) {
      console.error("Error parsing approved influencers string:", error);
      setApprovedInfluencers([]);
    }
  }, [approvedInf]);

  // State to keep track of approved influencers
  const [approved, setApproved] = useState(new Set(approvedInfluencers));

  useEffect(() => {
    setApproved(new Set(approvedInfluencers));
  }, [approvedInfluencers]);

  // Handle toggle action
  const handleToggle = async (id) => {
    const newApproved = new Set(approved);
    const status = newApproved.has(id) ? false : true;
    console.log("**********CollectionId:**********", collectionId);
    console.log("**********approved:**********", approved);
    console.log("**********newApproved:**********", newApproved);
    console.log("**********status:**********", status);
    console.log("**********id:**********", id);
    console.log("**********approved status:**********", approved.has(id));

    const currCollectionId = parseInt(collectionId);

    try {
      // const response = await getApprovedStatus({
      //   id: currCollectionId,
      //   inf_val: id,
      //   status: status,
      // });

      // console.log("Approved Status Response:", response);

      // // Update the local state after a successful API call
      // if (newApproved.has(id)) {
      //   newApproved.delete(id);
      // } else {
      //   newApproved.add(id);
      // }

      setApproved(newApproved);
    } catch (error) {
      console.error("Error updating influencer status:", error);
    }
  };

  const openDrag = Boolean(anchorEl);
  const id = openDrag ? "simple-popover" : undefined;

  // Hide and Show

  const handleRowClick = (row) => {
    // Update the selectedRow state when a row is clicked
    setSelectedRow(row);
    setOpen(true);

    // You can perform additional actions or navigate to a detail page, etc.
    console.log("Clicked Row:", row);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const removeInfluencer = () => {
    console.log("Influencers saare", sortedInfluencers);
    console.log("Selected Influencers", selectedRowArr);

    // Remove the selected influencer from the list
    let influe = sortedInfluencers.filter((influencer) => {
      let flag = true;
      selectedRowArr.forEach((selectedInfluencer) => {
        if (influencer.id === selectedInfluencer.id) {
          flag = false;
        }
      });
      return flag;
    });
    setSortedInfluencers(influe);
  };

  // Pdf download
  const [downloading, setDownloading] = useState(false);

  const tableRef = useRef(null);

  const downloadTableAsPDF = useCallback(async () => {
    setDownloading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure table updates have taken effect

      const table = tableRef.current;

      if (!table) {
        console.error("Table element not found.");
        setDownloading(false);
        return;
      }

      // Use html2canvas to capture the expanded table
      const canvas = await html2canvas(table);

      // Create a PDF document
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 size
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= pageHeight;
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }

      pdf.save("table.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setDownloading(false);
    }
  }, [tableRef]);

  //get all collections;
  const getCollections = async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      console.log("Get all collections response: ", response);
      setAllCollection(response);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  };

  const handleAddToCollectionDialogOpen = () => {
    // Open the dialog
    setShowCollection(true);

    // Call the API function when the dialog is opened
    getCollections();
  };

  const getACollectionById = (collectionId) => {
    const selectedCollection = allCollection?.find(
      (collection) => collection.id === collectionId
    );
    return selectedCollection;
  };

  const getCreatedBy = async () => {
    const id_ = {
      id: aCollection.created_by,
    };
    try {
      const res = await getUserByID(id_);
      console.log("created by", res);
      setCreatedBy(res);
      console.log("created by state : ", createdBy);
      // if (aCollection.created_by) {
      //     console.log('got it ');
      // }
    } catch (e) {
      console.error(
        "error occurs while getting name of created by : ",
        e.message
      );
    }
  };

  const [currentCardPage, setCurrentCardPage] = useState(1);
  const itemsPerCardPage = 6; // Adjust as needed

  const handlePageCardChange = (event, value) => {
    setCurrentCardPage(value);
  };

  const startCardIndex = (currentCardPage - 1) * itemsPerCardPage;
  const currentCardItems = influencers.slice(
    startCardIndex,
    startCardIndex + itemsPerCardPage
  );
  const totalCardPages = Math.ceil(influencers.length / itemsPerCardPage);

  const detailedCollection = async (id) => {
    const data = getACollectionById(id);
    setCollection(data);
    getCreatedBy();
    const created_by_id = data.created_by;
    console.log("created by : ", created_by_id);

    // const res = await getCollectionById(created_by_id);
    // console.log("xysd", res);
  };

  // useEffect(() => {
  //     detailedCollection(1)
  // },[])

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleSelectRow = (e, row) => {
    if (e) e.stopPropagation();

    const isSelected = selectedRowArr.some(
      (selectedRow) => selectedRow.id === row.id
    );
    console.log("row", row.id, "se");
    console.log(isSelected);

    // Update the selectedRows state based on whether the row is currently selected
    if (isSelected) {
      // If the row is already selected, remove it from the selectedRows array
      setSelectedRowArr(
        selectedRowArr.filter((selectedRow) => {
          console.log("selected row id", selectedRow.id, "row id", row.id);

          return selectedRow.id !== row.id;
        })
      );
    } else {
      // If the row is not selected, add it to the selectedRows array
      console.log("else");
      setSelectedRowArr([...selectedRowArr, row]);
    }
    // Check if any rows are selected
  };

  const handleHidebutton = () => {
    if (selectedRowArr.length > 0) {
      // Rows are selected, set hideBtn to true
      setHideBtn(true);
    } else {
      // No rows selected, set hideBtn to false
      setHideBtn(false);
    }
  };

  useEffect(() => {
    handleHidebutton();
    console.log("selected row arr", selectedRowArr);
  }, [selectedRowArr]);

  const handleHideColumn = (columnName) => {
    setHiddenColumns([...hiddenColumns, columnName]);
  };
  const handleShowAllColumns = () => {
    setHiddenColumns([]);
  };

  const handleAddColumn = ({ columnName, columnId, currUser }) => {
    // Check if the column already exists to avoid duplicates
    if (!dynamicColumns.some((column) => column.name === columnName)) {
      const newColumn = {
        name: (
          <Name
            name={columnName}
            handleHideColumn={handleHideColumn}
            handleShowAllColumns={handleShowAllColumns}
            handleAddColumn={handleAddColumn}
          />
        ),
        selector: (row) => row[columnName.toLowerCase().replace(/\s+/g, "_")], // Example selector based on the column name
        // sortable: false,
        cell: (row, index) => {
          return (
            <>
              <CustomNewColumn
                row={row}
                columnName={columnName}
                columnId={columnId}
                currUser={currUser}
              />
            </>
          );
        },
        reorder: true,
        center: true,
        omit: hiddenColumns.includes(columnName),

        // You can add more properties as needed
      };
      setDynamicColumns([...dynamicColumns, newColumn]);
    }
  };

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [order, setOrder] = useState(false);

  const handleSort = (name) => {
    let sortedData = [...influencersData];

    if (name === "Sr.No.") {
      sortedData = order
        ? sortedData.sort((a, b) => a.id - b.id)
        : sortedData.sort((a, b) => b.id - a.id);
    } else if (name === "Accounts") {
      sortedData = order
        ? sortedData.sort((a, b) => a.name.localeCompare(b.name))
        : sortedData.sort((a, b) => b.name.localeCompare(a.name));
    } else if (name === "Categories") {
      sortedData = order
        ? sortedData.sort((a, b) => a.categories.localeCompare(b.categories))
        : sortedData.sort((a, b) => b.categories.localeCompare(a.categories));
    } else if (name === "Subscribers") {
      sortedData = order
        ? sortedData.sort((a, b) => a.Subscribers - b.Subscribers)
        : sortedData.sort((a, b) => b.Subscribers - a.Subscribers);
    } else if (name === "ER%") {
      sortedData = order
        ? sortedData.sort((a, b) => a.er - b.er)
        : sortedData.sort((a, b) => b.er - a.er);
    } else if (name === "AVG.Likes") {
      sortedData = order
        ? sortedData.sort((a, b) => a.average - b.average)
        : sortedData.sort((a, b) => b.average - a.average);
    } else if (name === "AVG.Comments") {
      sortedData = order
        ? sortedData.sort((a, b) => a.average - b.average)
        : sortedData.sort((a, b) => b.average - a.average);
    } else if (name === "Actions") {
      sortedData = order
        ? sortedData.sort((a, b) => a.actions - b.actions)
        : sortedData.sort((a, b) => b.actions - a.actions);
    }

    setOrder(!order);
    setSortedInfluencers(sortedData);
    console.log("sorted data", sortedData);
  };

  useEffect(() => {
    setSortedInfluencers(influencersData);
  }, [influencersData]);

  const loadFromLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  const [currUser, setCurrUser] = useState(loadFromLocalStorage("currUser"));

  const Name = ({
    name,
    handleHideColumn,
    handleShowAllColumns,
    handleAddColumn,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchedUnderAdd, setSearchedUnderAdd] = useState([]);
    const [customColumn, setCustomColumn] = useState("");
    const [addOrDrag, setAddOrDrag] = useState("");
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

    const handleSubMenuOpen = (event) => {
      setSubMenuAnchorEl(event.currentTarget);
    };
    const handleSubMenuClose = () => {
      setSubMenuAnchorEl(null);
    };

    const handleMenuOpen = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
      setSearchedUnderAdd([]);
    };

    const addColumn = (columnName) => {
      handleAddColumn(columnName);
      handleSubMenuClose(); // Close the submenu after adding the column
    };

    const handleHideClick = () => {
      handleHideColumn(name);
    };

    const handleAddingColumnHead = async (columnName) => {
      let columnId;
      const data = {
        name: columnName,
        for_table: "influencers",
        created_by: JSON.stringify(currUser),
        status: "show",
        for_id: "influencers",
        is_deleted: "false",
      };
      console.log("datacurrUser", data);
      try {
        const response = await addNewColumn(data);
        console.log("response", response);
        columnId = response.data.head.id;
      } catch (e) {
        console.log("error", e);
      } finally {
        try {
          const response = await getColumnHead();
          console.log("responseresponse", response);
        } catch (e) {
          console.log("error", e);
        }
        handleAddColumn({ columnName, columnId, currUser });
        setCustomColumn("");
      }
    };

    return (
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(e) => {
            handleMenuOpen(e);
            setAddOrDrag("Add");
          }}
        >
          <Add
            style={{
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </IconButton>
        <IconButton
          size="small"
          sx={{ p: 0 }}
          onClick={(e) => {
            handleMenuOpen(e);
            setAddOrDrag("Drag");
          }}
        >
          <DragIndicator
            style={{
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </IconButton>
        <Typography variant="body1">{name}</Typography>
        <IconButton
          style={{
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
          size="small"
          onClick={() => {
            handleSort(name);
          }}
        >
          {order ? <ArrowDownwardSharp /> : <ArrowUpward />}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {addOrDrag == "Add" ? (
            <Box margin={"1rem"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search"
                  startAdornment={<Search />}
                  sx={{
                    width: "200px",
                    borderRadius: 20,
                    // border: "1px solid #3DBE72",
                  }}
                  onChange={(e) => {
                    let searched = combinedColumns.filter((combinedColumns) =>
                      combinedColumns.name.props.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    );
                    if (e.target.value === "") {
                      searched = [];
                      setCustomColumn("");
                    }
                    if (e.target.value != "" && searched.length == 0) {
                      setCustomColumn(e.target.value);
                    }
                    setSearchedUnderAdd(searched);
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {customColumn != "" ? (
                    <Button
                      onClick={() => {
                        handleAddingColumnHead(customColumn);
                      }}
                    >
                      Add
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <Box>
                <List>
                  {searchedUnderAdd.map((columns) => (
                    <ListItem>
                      <ListItemButton>
                        {searchedUnderAdd.length > 0
                          ? columns.name
                          : "No data found"}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          ) : (
            <div>
              <MenuItem onClick={handleHideClick}>Hide</MenuItem>
              <MenuItem onClick={handleShowAllColumns}>
                Show all hidden columns
              </MenuItem>
            </div>
          )}
        </Menu>
      </Box>
    );
  };
  const columns = [
    {
      name:
        selectedRowArr.length > 0 ? (
          <Checkbox
            sx={{ display: "flex", alignItems: "center", mx: "auto" }}
            checked={selectedRowArr.length === influencers.length}
            onChange={(e) => {
              e.stopPropagation();
              if (e.target.checked) {
                setSelectedRowArr(influencers);
              } else {
                setSelectedRowArr([]);
              }
            }}
          />
        ) : (
          <Name
            name={"Sr.No."}
            handleHideColumn={handleHideColumn}
            handleShowAllColumns={handleShowAllColumns}
            handleAddColumn={handleAddColumn}
          />
        ),
      center: true,
      selector: (row, index) => index + 1,
      reorder: true,
      cell: (row, index) => {
        // Determine if the current row is selected
        const isSelected = selectedRowArr.some(
          (selectedRow) => selectedRow.id === row.id
        );
        // Show the checkbox if the row is hovered or selected
        if (hoveredRow === row.id || isSelected) {
          return (
            <Checkbox
              checked={isSelected}
              inputProps={{ "aria-label": "select all desserts" }}
              // Prevent the checkbox from triggering row selection

              onClick={(e) => {
                // Prevent default action
                handleSelectRow(e, row);

                // Determine if the row is already selected
              }}
            />
          );
        } else {
          return <>{index + 1} </>;
        }
      },
      omit: hiddenColumns.includes("Sr.No."),
    },
    {
      name: (
        <Name
          name={"Accounts"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.name,
      reorder: true,
      cell: (row) => <CustomAccounts row={row} setOpen={handleRowClick} />,
      omit: hiddenColumns.includes("Accounts"),
    },
    {
      name: (
        <Name
          name={"Categories"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.categories,
      reorder: true,
      center: true,
      cell: (row) => <Customcategory row={row} />,
      omit: hiddenColumns.includes("Categories"),
    },
    {
      name: (
        <Name
          name={"Subscribers"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => <CustomSubscribers row={row} />,
      omit: hiddenColumns.includes("Subscribers"),
      reorder: true,
      center: true,
    },
    {
      name: (
        <Name
          name={"ER%"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => <CustomER row={row} />,
      omit: hiddenColumns.includes("ER%"),
      reorder: true,
      center: true,
    },
    {
      name: (
        <Name
          name={"AVG.Likes"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.average,
      omit: hiddenColumns.includes("AVG.Likes"),
      reorder: true,
      cell: (row) => <AverageLike row={row} />,
      center: true,
    },
    {
      name: (
        <Name
          name={"AVG.Comments"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.average,
      reorder: true,
      cell: (row) => <AverageComment row={row} />,
      omit: hiddenColumns.includes("AVG.Comments"),
      center: true,
    },
    {
      name: (
        <Name
          name={"Actions"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row.actions,
      omit: hiddenColumns.includes("Actions"),
      cell: (row) => (
        <CustomActions
          setSharePopup={setShareCollectionDrawer}
          row={row}
          combinedColumns={combinedColumns}
        />
      ),
      center: true,
      reorder: true,
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
    },
  }));

  const newCollectionColumns = [
    {
      name: (
        <Name
          name={"Status"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      // selector: row => row.actions,
      reorder: true,
      width: "80px",
      cell: (row) => (
        <CustomStatus
          id={row.id}
          isApproved={approved.has(row.id)}
          onToggle={handleToggle}
          row={row}
        />
      ),
    },
    {
      name: (
        <Name
          name={"Contact"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      reorder: true,
      width: "130px",
      cell: (row) => <CustomContact row={row} />,
      center: "true",
    },
    {
      name: (
        <Name
          name={"Feedback"}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      reorder: "false",
      cell: (row) => <CustomFeedback row={row} />,
      width: "200px",
    },
  ];

  const ColumnFromData = () => {
    const customNewColumn = customColumns?.map((column) => ({
      name: (
        <Name
          name={column.name}
          handleHideColumn={handleHideColumn}
          handleShowAllColumns={handleShowAllColumns}
          handleAddColumn={handleAddColumn}
        />
      ),
      selector: (row) => row[column.name],
      sortable: false,
      reorder: true,
      center: true,
      cell: (row) => {
        const rowId = String(row.id); // Ensure row.id is a string
        const customData = column.custom_column_data.find((data) => {
          const dataForId = String(data.for_id); // Ensure data.for_id is a string

          return dataForId === rowId;
        });

        return <>{customData ? customData.value : "No data"}</>;
      },
      omit: hiddenColumns.includes(column.name),
    }));

    return customNewColumn;
  };

  const CustomColumnFromData = ColumnFromData();

  const combinedColumns = [...columns, ...dynamicColumns];

  if (extraColumns) {
    combinedColumns.push(...newCollectionColumns);
  }

  if (customColumns) {
    combinedColumns.push(...CustomColumnFromData);
  }

  const handleAddInfluencers = async () => {
    try {
      // Fetch the existing influencers from the API or other source
      // Here, we'll assume that it's an array from the API response
      const collection_id = aCollection.id;
      console.log("collection : id : ", collection_id);
      let existingInfluencers = await fetchExistingInfluencers(collection_id);

      // Extracting numeric values from existingInfluencers
      // const normalizedInfluencers = existingInfluencers
      //     .filter(value => typeof value === 'number') // Filter out non-numeric values
      //     .map(value => Number(value)); // Convert remaining values to numbers

      // Using map and parseInt to convert each string to an integer
      // const integerArray = existingInfluencers.map(str => parseInt(str, 10));
      // const numberArray = existingInfluencers.map(Number);

      const selectedInfluencerIds = selectedRowArr?.map(
        (influencer) => influencer.id
      );

      existingInfluencers = JSON.parse(existingInfluencers)
        ? JSON.parse(existingInfluencers)
        : [];

      // console.log("387", existingInfluencers);

      console.log("existing influencer : ", existingInfluencers);
      console.log("selected influencer : ", selectedInfluencerIds);

      // const updatedInfluencers = Array.from(new Set([...existingInfluencers,selectedInfluencerIds]));
      // const updatedInfluencers = [...new Set([...existingInfluencers, ...selectedInfluencerIds])];

      const updatedInfluencers = [
        ...new Set([...existingInfluencers, ...selectedInfluencerIds]),
      ];

      // Construct the payload
      const payload = {
        id: collection_id,
        influncers_id: updatedInfluencers,
      };

      // Call the API function
      const updatedCollection = await addInfluencerToCollection(payload);

      // Handle the updated collection data as needed
      console.log("Updated Collection:", updatedCollection);

      setIsAdded(true);
    } catch (error) {
      console.error("Error adding influencers to collection:", error.message);
    }
  };

  const fetchExistingInfluencers = async (collectionId) => {
    try {
      const id_ = {
        id: collectionId,
      };
      const res = await getCollectionById(id_);

      // const collection = JSON.parse(res.data.collection[0]);

      console.log("ressss : ", res);
      // console.log("after parsing : ", collection)

      // res && res.data && res.data.collection && res.data.collection.influncers_id
      if (res) {
        // const { influncers_id } = res.data.collection;
        const { influncers_id } = res.data.collection[0];
        console.log(influncers_id);
        console.log("Fetched Influencers IDs:", influncers_id);
        // return Array.isArray(influncers_id) ? influncers_id : [];
        return influncers_id;
      } else {
        console.error("Invalid response structure:", res);
        return [];
      }
    } catch (error) {
      console.error("Error fetching influencers:", error.message);
      return [];
    }
  };

  const closeAddToCollection = () => {
    setShowCollection(false);
    setIsAdded(false);
  };

  useEffect(() => {
    getCreatedBy();
  }, []);

  // Fetch more influencers when user is near bottom
  useEffect(() => {
    fetchMoreInfluencers();
    console.log(influencers);
  }, []);

  // Function to handle mouse enter event

  // const [currentPage, setCurrentPage] = useState(1);

  // const totalPages = Math.ceil(influencers.length / ITEMS_PER_PAGE);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  // const currentInfluencers = influencers.slice(
  //   (currentPage - 1) * ITEMS_PER_PAGE,
  //   currentPage * ITEMS_PER_PAGE
  // );

  const IsOpen = useSelector((state) => state.sharedState.isOpen || false);

  return (
    <Box sx={{ width: "100%" }}>
      <Dialog
        open={showCollection}
        onClose={() => closeAddToCollection()}
        style={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Box container>
          <Box
            item
            md={4}
            p={3}
            justifyContent="space-between"
            style={{ background: "white" }}
          >
            <Box container>
              <Box item md={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Collection
                </Typography>
                <TextField
                  size="small"
                  placeholder="Search here..."
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    background: "#FFFFFF",
                    marginTop: 2,
                    marginBottom: 5,
                    boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <ToggleButtonGroup
                    orientation="vertical"
                    value={view}
                    exclusive
                    onChange={handleChange}
                    size="small"
                    sx={{
                      border: "none",
                      maxHeight: "280px",
                      overflowY: "scroll",
                      scrollbarWidth: "thin", // For Firefox
                      scrollbarColor: "transparent transparent", // For Firefox
                      msOverflowStyle: "none", // For Internet Explorer and Microsoft Edge
                    }}
                  >
                    {allCollection?.map(({ name, id }, index) => (
                      <ToggleButton
                        key={index}
                        value={id}
                        aria-label="list"
                        sx={{
                          textTransform: "none",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        onClick={() => detailedCollection(id)}
                      >
                        {name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
              </Box>
            </Box>
            <Box item md={12} mt={3}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                style={{
                  background: "rgba(61, 190, 114, 0.3)",
                  borderRadius: 20,
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "5px 20px",
                  border: "2px solid rgba(61, 190, 114, 1)",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Create new Collection
              </Button>
            </Box>
          </Box>

          <Box item md={8}>
            <Box item md={12} align="end">
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => closeAddToCollection()}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Box>

            <Box
              container
              p={3}
              sx={{
                borderLeft: "3px solid black",
                height: "90%",
                marginRight: 1,
              }}
            >
              <Box item md={12} display="flex">
                <AvatarGroup
                  max={4}
                  total={147}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    alt="Remy Sharp"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    alt="Travis Howard"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Agnes Walker"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Trevor Henderson"
                    src={TemptDP}
                  />
                </AvatarGroup>
                <Box ml={2}>
                  <Typography fontSize="1.3rem" fontWeight="bold">
                    {aCollection.name}
                  </Typography>
                </Box>
              </Box>
              <Box item md={12} mt={2}>
                <Button
                  style={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                    marginRight: 10,
                  }}
                  size="small"
                  variant="outlined"
                >
                  Food & Drinks
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                  }}
                  size="small"
                  variant="outlined"
                >
                  Infotainment
                </Button>
              </Box>

              <Box item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Created by
                </Typography>
                <Typography>
                  {createdBy ? createdBy.name : "not found"}
                </Typography>
              </Box>

              <Box item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Assigned to Campaign
                </Typography>
                <Typography>Lays Marketing</Typography>
              </Box>

              <Box item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Bio
                </Typography>
                <Typography>
                  {aCollection.description}
                  {/* Live life on your own tems #jointhemvmt Watches, Eyewear & Premium Accessories For her @mvmforher Shop our Site */}
                </Typography>
                <span>
                  #Live #life #jointhemvmt #Watches #Eyewear #Premium
                  #Accessories
                </span>
              </Box>

              <Box item md={12} align="end">
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    background: "#3DBE72",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                  }}
                  onClick={() => handleAddInfluencers()}
                >
                  {isAdded ? "Added to Collection" : "Add to Collection"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>

      {/* influencer profile in the right side drawer  */}
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <InfluencerProfile
          selectedSocial={selectedSocial}
          close={setOpen}
          row={selectedRow}
        />
      </Drawer>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // width: IsOpen ? "90vw" : "76vw",
            width: "100%",
            alignItems: "center",
            transition: "width 0.4s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box>
              <Tooltip title="Cards view" arrow placement="top">
                <IconButton size="small" onClick={() => setViews(false)}>
                  <img src={SBicon} alt="" width={30} />
                  {/* <WindowOutlined sx={{fontSize : 30, color : 'green'}}/> */}
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="List view" arrow placement="top">
                <IconButton size="small" onClick={() => setViews(true)}>
                  <img src={LISTicon} alt="" width={30} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              {selectedRowArr.length != 0 && (
                <CSVExportButton
                  data={selectedRowArr}
                  filename="influencers_data"
                />
              )}
            </Box>
            <Box>
              <Tooltip title="Download PDF" arrow placement="top">
                <IconButton
                  disabled={downloading}
                  onClick={downloadTableAsPDF}
                  size="small"
                >
                  <img src={PDFicon} alt="" width={30} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box>
            {hideBtn && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box>
                  <Btn
                    size="small"
                    variant="outlined"
                    startIcon={<BookmarkBorderIcon />}
                    onClick={handleAddToCollectionDialogOpen}
                  >
                    Add to Collection
                  </Btn>
                </Box>
                <Box>
                  <Btn
                    size="small"
                    variant="outlined"
                    startIcon={<AddRoundedIcon />}
                  >
                    Add to Campaign
                  </Btn>
                </Box>
                <Box>
                  <Btn
                    size="small"
                    variant="outlined"
                    startIcon={<VisibilityOffRoundedIcon />}
                  >
                    Hide Influencers
                  </Btn>
                </Box>
                <Box>
                  <Btn
                    size="small"
                    variant="outlined"
                    startIcon={<Delete />}
                    onClick={() => removeInfluencer()}
                  >
                    Remove Influencers
                  </Btn>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* <TestComponent/> */}
      {views ? (
        <>
          {combinedColumns.length > 0 ? (
            <Box
              sx={{
                flexGrow: 1,
              }}
              ref={tableRef}
              id="influencertable"
            >
              <Box
                fullWidth
                sx={{
                  width: "100%",
                  mx: "auto",
                  backgroundColor: "black",
                }}
              >
                <DataTable
                  // title="Influencers"
                  columns={combinedColumns}
                  data={sortedInfluencers}
                  fixedHeader
                  pagination
                  // onRowMouseEnter={(row,event)=>console.log(row,event)}
                  onRowClicked={handleRowClick}
                  customStyles={customStyles}
                  // sortIcon={<CustomSortIcon />}"
                  progressPending={fetching}
                  scrollbarColor="rgba(61, 190, 114, 1)"
                  selectableRowsHighlight
                  pointerOnHover
                  // progressComponent={<CircularProgress />}
                  fixedHeaderScrollHeight={downloading ? "100%" : "550PX"}
                  // onSelectedRowsChange={handleSelectRow}
                  clearSelectedRows={toggledClearRows}
                  // sortIcon={<CustomSortIcon />}
                  onRowMouseEnter={(row, event) => setHoveredRow(row.id)}
                  onRowMouseLeave={() => setHoveredRow(null)}
                />
              </Box>
              {selectedRowArr.length > 0 && (
                <Box
                  sx={{
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    transition: "width 1s ease-in-out",
                    border: "1px solid black",
                    width: "fit-content",
                    mt: -5,
                    mb: 5,
                    py: 1,

                    // flexDirection: "row-reverse",
                  }}
                >
                  <IconButton
                    onClick={() => setTotalHidden(!TotalHidden)}
                    style={{ float: "left", marginTop: "5px" }}
                  >
                    {TotalHidden ? (
                      <VisibilityOffRoundedIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>

                  {TotalHidden ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <TotalSelectedInfluencers
                        selectedRowArr={selectedRowArr}
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress
                sx={{
                  margin: "auto",
                  width: "50px",
                  height: "50px",
                }}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <Grid container spacing={3} p={4}>
            {currentCardItems.map(
              ({
                photo,
                Subscribers,
                name,
                likes,
                engagement,
                categories,
                id,
              }) => (
                <Grid item xs={12} sm={6} lg={3}>
                  <Grid
                    container
                    p={2}
                    sx={{
                      bgcolor: "#FFFFFF",
                      borderRadius: 5,
                      border: "1px solid black",
                      height: "300px",
                    }}
                    onClick={() => {
                      selectedRowArr.some(
                        (selectedRow) => selectedRow.id === id
                      )
                        ? setSelectedRowArr(
                            selectedRowArr.filter(
                              (selectedRow) => selectedRow.id !== id
                            )
                          )
                        : setSelectedRowArr([...selectedRowArr, { id, name }]);

                      console.log(id);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: selectedRowArr.some(
                        (selectedRow) => selectedRow.id === id
                      )
                        ? "#BFFFBF"
                        : "white",
                    }}
                  >
                    <Grid item xs={4}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="100%"
                      >
                        <AvatarGroup sx={{ width: "100%", height: "100%" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={avt3}
                            sx={{
                              borderRadius: "50%",
                              width: "80px",
                              height: "80px",
                              marginTop: 1,
                            }}
                          />
                        </AvatarGroup>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{ padding: 1 }}
                      onClick={() => handleRowClick({ id })}
                    >
                      <Typography sx={{ paddingLeft: 1, fontWeight: "bold" }}>
                        Full Name
                      </Typography>
                      <CategorieBtn variant="contained" size="small">
                        {"Tech Influencer"}
                      </CategorieBtn>
                    </Grid>
                    <Grid container>
                      <Grid item mt={2} xs={4} align={"center"}>
                        <Typography
                          sx={{
                            fontSize: ".6rem",
                            fontWeight: "bold",
                            marginBottom: "5px",
                          }}
                        >
                          Subscribers
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            fontWeight: "bold",
                            wordWrap: "break-word", // Break the word to the next line
                            whiteSpace: "normal", // Allow the text to wrap
                            width: "100%",
                          }}
                        >
                          10M
                        </Typography>
                      </Grid>
                      <Grid
                        ml={0.5}
                        item
                        mt={2}
                        xs={4}
                        align={"center"}
                        sx={{
                          borderLeft: "1px solid black",
                          overflow: "hidden",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: ".6rem", fontWeight: "bold" }}
                        >
                          Engag. Rate
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            fontWeight: "bold",
                            padding: "2px",
                            marginBottom: "5px",
                            wordWrap: "break-word", // Break the word to the next line
                            whiteSpace: "normal", // Allow the text to wrap
                            width: "100%",
                          }}
                        >
                          12.5%
                        </Typography>
                      </Grid>
                      <Grid
                        ml={0.5}
                        item
                        mt={2}
                        xs={3}
                        align={"center"}
                        sx={{ borderLeft: "1px solid black" }}
                      >
                        <Typography
                          sx={{ fontSize: ".6rem", fontWeight: "bold" }}
                        >
                          Avg. Likes
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            fontWeight: "bold",
                            padding: "2px",
                            marginBottom: "5px",
                            wordWrap: "break-word", // Break the word to the next line
                            whiteSpace: "normal", // Allow the text to wrap
                            width: "100%",
                          }}
                        >
                          122K
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} mt={3} align={"center"}>
                      <AddCampaignBtn
                        size="small"
                        startIcon={<GroupsRounded />}
                        variant="contained"
                        sx={{ width: "90%" }}
                      >
                        Add to Campaign
                      </AddCampaignBtn>
                    </Grid>
                    <Grid item xs={12} mt={1} align={"center"}>
                      <AddCollectionBtn
                        size="small"
                        variant="outlined"
                        startIcon={<TurnedInNotOutlined />}
                        sx={{ width: "90%" }}
                      >
                        Add to Collection
                      </AddCollectionBtn>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          <Pagination
            count={totalCardPages}
            page={currentCardPage}
            onChange={handlePageCardChange}
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}
          />
        </>
      )}
    </Box>
  );
};

const Template = (args) => <InfluencersDataTable {...args} />;

export const FixedHeader = Template.bind({});

FixedHeader.args = {
  fixedHeader: true,
  fixedHeaderScrollHeight: "300px",
};

export default InfluencersDataTable;
