import React from "react";
import {
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { SwapHoriz } from "@mui/icons-material";

const MinMaxWithSelectInput = ({
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  minPlaceholder = "min",
  maxPlaceholder = "max",
  disabled = false,
  size = "small",
  error = false,
  fullWidth = false,
  allowDecimals = false,
  allowNegative = false,
  // Modified left field props
  leftTextValue,
  onLeftTextChange,
  leftTextLabel = "Left Input",
  // Right select props
  rightSelectValue,
  onRightSelectChange,
  selectOptions = [],
  rightSelectLabel = "Right Select",
}) => {
  // Validate number input
  const validateNumber = (value) => {
    if (value === "") return true;
    if (allowDecimals) {
      return allowNegative
        ? /^-?\d*\.?\d*$/.test(value)
        : /^\d*\.?\d*$/.test(value);
    }
    return allowNegative ? /^-?\d*$/.test(value) : /^\d*$/.test(value);
  };

  // Handle min value change
  const handleMinChange = (event) => {
    const value = event.target.value;
    if (validateNumber(value)) {
      onMinChange(value);
    }
    console.log("min", value);
  };

  // Handle max value change
  const handleMaxChange = (event) => {
    const value = event.target.value;
    if (validateNumber(value)) {
      onMaxChange(value);
    }
    console.log("max", value);
  };

  const handleLeftTextChange = (event) => {
    const value = event.target.value;
    if (validateNumber(value)) {
      onLeftTextChange(value);
    }
    console.log("for", value);
  };

  const handleRightSelectChange = (event) => {
    const value = event.target.value;
    onRightSelectChange(value);
    console.log("in", value);
  };

  // Validate min-max relationship
  const getError = (isMin) => {
    if (error) return true;
    if (minValue === "" || maxValue === "") return false;

    const min = parseFloat(minValue);
    const max = parseFloat(maxValue);

    if (isNaN(min) || isNaN(max)) return false;
    return isMin ? min > max : max < min;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: fullWidth ? "100%" : "auto",
        }}
      >
        <TextField
          label={leftTextLabel}
          value={leftTextValue}
          onChange={handleLeftTextChange}
          size={size}
          disabled={disabled}
          sx={{
            width: "50%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
          }}
        />
        <FormControl size={size} disabled={disabled} sx={{ width: "50%" }}>
          <InputLabel>{rightSelectLabel}</InputLabel>
          <Select
            name={rightSelectLabel}
            value={rightSelectValue}
            onChange={handleRightSelectChange}
            label={rightSelectLabel}
          >
            <MenuItem value="">None</MenuItem>
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: fullWidth ? "100%" : "auto",
        }}
      >
        <TextField
          value={minValue}
          onChange={handleMinChange}
          placeholder={minPlaceholder}
          size={size}
          disabled={disabled}
          error={getError(true)}
          inputProps={{
            inputMode: "numeric",
            pattern: allowDecimals
              ? allowNegative
                ? "-?[0-9]*[.]?[0-9]*"
                : "[0-9]*[.]?[0-9]*"
              : allowNegative
              ? "-?[0-9]*"
              : "[0-9]*",
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
            width: fullWidth ? "100%" : "120px",
          }}
        />
        <SwapHoriz size={20} className="text-gray-400" />
        <TextField
          value={maxValue}
          onChange={handleMaxChange}
          placeholder={maxPlaceholder}
          size={size}
          disabled={disabled}
          error={getError(false)}
          inputProps={{
            inputMode: "numeric",
            pattern: allowDecimals
              ? allowNegative
                ? "-?[0-9]*[.]?[0-9]*"
                : "[0-9]*[.]?[0-9]*"
              : allowNegative
              ? "-?[0-9]*"
              : "[0-9]*",
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
            width: fullWidth ? "100%" : "120px",
          }}
        />
      </Box>
    </Box>
  );
};

export default MinMaxWithSelectInput;
