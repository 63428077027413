import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";

const Card3 = ({
  views,
  likes,
  comments,
  date,
  link,
  media_id,
  title,
  duration,
}) => {
  const convertDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    return hours > 0
      ? `${hours}h ${minutes}m ${seconds}s`
      : minutes > 0
      ? `${minutes}m ${seconds}s`
      : `${seconds}s`;
  };

  return (
    <Link
      href={`https://youtube.com/video/${media_id}`}
      target="_blank"
      sx={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          minWidth: 120,
          width: 180,
          maxHeight: 280,
          height: 250,
          position: "relative",
          marginBottom: 2,
        }}
      >
        <CardMedia
          sx={{ width: "100%", height: "60%", objectFit: "cover" }}
          image={link}
          title="Profile image"
        />
        <CardContent sx={{ position: "relative", pt: 1, pb: 0 }}>
          <Typography
            sx={{
              textAlign: "center",
              width: "100%",
              textDecoration: "none",
            }}
            variant="body2"
          >
            {title.length >= 15 ? title.substring(0, 15) + "..." : title}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              width: "max-content",
              textDecoration: "none",
              position: "absolute",
              top: -28,
              right: 0,
              px: 1,
              py: 0.5,
              borderRadius: "5px 0 0 0",
              backgroundColor: "#3DBE72",
              color: "#FFF",
            }}
            variant="body2"
          >
            {convertDuration(duration)}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            justifyContent: "center",
            color: "grey",
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <VisibilityOutlinedIcon fontSize="10px" />
            <Typography variant="subtitle2">{views}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FavoriteBorderIcon fontSize="10px" />
            <Typography variant="subtitle2">{likes}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChatBubbleOutlineOutlinedIcon fontSize="10px" />
            <Typography variant="body2">{comments}</Typography>
          </Box>
        </CardActions>
        <Typography
          sx={{
            color: "grey",
            textAlign: "center",
            width: "100%",
          }}
          variant="body2"
        >
          {date}
        </Typography>
      </Card>
    </Link>
  );
};

export default Card3;
