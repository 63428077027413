import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  InputAdornment,
  Dialog,
  Avatar,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  AvatarGroup,
  CircularProgress,
  ListItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link, useParams } from "react-router-dom";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountAnalytics from "./AccountAnalytics";
import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import ShareIcon from "../../../images/directionRight.png";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Bio from "./Bio";
import VerifiedIcon from "@mui/icons-material/Verified";
import BasicTabs from "./BasicTabs";
import { getUserByID } from "../../../../service/user/getUserByID";
import {
  Add,
  AddRounded,
  AddTaskOutlined,
  CancelOutlined,
  Instagram,
  Search,
  ShareRounded,
  WarningAmberOutlined,
  YouTube,
} from "@mui/icons-material";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import CustomCarousel from "./CustomCarousel";
import CustomCarousel1 from "./CustomCarousel1";
import ShareCollection from "../../collection/ShareCollection";
import ShareInfluencers from "./ShareInfluencers";
import ProfileCarousel from "./ProfileCarousel";
import { useSelector } from "react-redux";
import {
  getAllCollection,
  getBrandList,
} from "../../../../service/collections api/getAllCollection";
import { getCollectionById } from "../../../../service/collections api/getCollectionById";
import { createNewCollection } from "../../../../service/collections api/createCollection";
import { updateInfluencerCollection } from "../../../../service/collections api/updateInfluencer";

const TemptDP =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

// Custom UI Components
const Btn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  textTransform: "none",
  fontWeight: "bold",
  marginLeft: "10px",
  "&:hover": { borderColor: "#3DBE72" },
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const MyInput = styled("input")(`
  background : #dce7f7ff;
  outlined : none;
  border : none;
  width : 100%;
  padding : 15px 10px;
  border-radius : 8px;
  font-size : 15px;
  margin-top : 5px;
`);

const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
  outlined : none;
  border : none;
  width : 100%;
  padding : 15px 10px;
  border-radius : 8px;
  font-size : 15px;
  margin-top : 5px;
`);

const Mylabel = styled("label")(`
  color : #828282ff;
`);

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "white",
  background: "black",
  fontSize: ".8rem",
  padding: "2px 5px",
  "&:hover": {
    color: "white",
    background: "black",
  },
}));

const Profile = (props) => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decodedId : ", decodedId, typeof decodedId);

  const getUser = async () => {
    const user = await getUserByID({ id: decodedId });
    console.log("user from profile page : ", user);
    setUser(user);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search Term Updated:", event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const IsOpen = useSelector((state) => state.sharedState.isOpen);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // const handleResize = () => {
  //   setScreenWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  function removeEmailsAndPhones(text) {
    // Regular expression to match email addresses
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Regular expression to match phone numbers (generic pattern for digits with optional separators)
    const phonePattern =
      /(\+?\d{1,4}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/g;

    // Replace email addresses and phone numbers with an empty string
    return text?.replace(emailPattern, "")?.replace(phonePattern, "");
  }

  // function formatNumber(num) {
  //   try {
  //     const strNum = num.toString();

  //     if (strNum.endsWith("000000")) {
  //       return strNum.replace(/000000$/, "M"); // Replace trailing six zeros with 'M'
  //     } else if (strNum.endsWith("000")) {
  //       return strNum.replace(/000$/, "K"); // Replace trailing three zeros with 'K'
  //     } else {
  //       return num.toString(); // Return the number as is
  //     }
  //   } catch (error) {
  //     return "error";
  //   }
  // }

  function formatNumber(num) {
    try {
      // Convert the input to a number in case it's a string
      const number = Number(num);

      // Check if the conversion was successful
      if (isNaN(number)) {
        throw new Error("Invalid number");
      }

      // Format the number based on its value
      if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + "M"; // Convert to millions
      } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
      } else {
        return number.toString(); // Return the number as is
      }
    } catch (error) {
      return "error";
    }
  }

  const [pending, setPending] = useState(false);
  const [allCollection, setAllCollection] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [view, setView] = useState("1");
  const [aCollection, setaCollection] = useState({});
  const [createdBy, setCreatedBy] = useState({});

  const [loading, setLoading] = useState(false);
  // const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });

  const [collection, setCollection] = useState({
    brand_id: null,
    campaign_id: 1,
    status: "inactive",
    user_id: null,
    name: "",
    description: "",
    influencer_id: null,
  });

  const [searchText, setSearchText] = useState("");

  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [brandName, setBrandName] = useState("");

  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);

  const [newCollection, setNewCollection] = useState({});

  const [addBrandDialog, setAddBrandDialog] = useState(false);

  const [encodedNewCollectionId, setEncodedNewCollectionId] = useState("");

  const [showCollection, setShowCollection] = useState(false);

  const [allBrands, setAllBrands] = useState([]);

  const [collectionDesc, setCollectionDesc] = useState("");

  const [collectionName, setCollectionName] = useState("");

  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isAddedSuccessful, setIsAddedSuccessful] = useState(false);

  const [brandId, setBrandId] = useState(null);

  const [selectedRowArr, setSelectedRowArr] = useState([]);

  let influencerIds = [decodedId];

  const getBrands = async () => {
    try {
      const response = await getBrandList();
      const data = response.data.Brands;
      setAllBrands(data);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const getCreatedBy = async () => {
    try {
      // const id_ = {
      //   id: id,
      // };
      console.log("get user by id ki id: ", selectedItemId);
      const res = await getUserByID({ id: selectedItemId });
      console.log("created by", res);
      setCreatedBy(selectedItemId);
      console.log("created by state : ", createdBy);
      // if (aCollection.created_by) {
      //     console.log('got it ');
      // }
    } catch (e) {
      console.error(
        "error occurs while getting name of created by : ",
        e.message
      );
    }
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(() => value);

    console.log("brandName", brandName);
    console.log("brand id", id);
    // setIsBrandFocus(false);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)
  };

  const handleNewCollection = (newCollection) => {
    setAllCollection([...allCollection, newCollection]);
  };

  useEffect(() => {
    if (isSucessful) getCollections();

    if (aCollection) {
      getCreatedBy();
      // Perform any action that depends on aCollection immediately after it updates
      console.log("aCollection updated:", aCollection);
    }
  }, [isSucessful, createdBy, aCollection]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    console.log("Collection data:--- ", collection);
    // setCollection((prevCollection) => {
    //   return {
    //     ...prevCollection,
    //     influencer_id: influencerIds,
    //     // assigned_brand_id: id,
    //     user_id: user?.user?.id,
    //     brand_id: brandId,
    //     description: collectionDesc,
    //     name: collectionName,
    //   };
    // });

    const updatedCollection = {
      ...collection,
      influencer_id: influencerIds,
      user_id: user?.user?.id,
      brand_id: brandId,
      description: collectionDesc,
      name: collectionName,
    };
    try {
      console.log("collection data create new collection: ", updatedCollection);
      const response = await createNewCollection(updatedCollection);
      setNewCollection(response);
      // Encryption
      // Base64 encode function
      const encodeId = (id) => btoa(id.toString());
      const encodedId = encodeId(response.id);
      setEncodedNewCollectionId(encodedId);
      console.log("Encoded ID:", encodedId);
      // handleNewCollection(response);
      // getCollection();
      // setAllCollection(preCollection => [...preCollection, response])

      setIsSuccessful(true);
      handleNewCollection(response);
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection already exists.", status: true });
      console.error("Error while submiting new collection data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const handleListItemClick = (name, id) => {
    setBrandName(() => name);
    setBrandId(id);
    console.log("brand name ", brandName);
    console.log("brand id ", brandId);

    console.log("Clicked on:", name, "with ID:", id);
    console.log("payload collection :", collection);
  };

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };
  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleDescChange = (event) => {
    console.log(event.target.value);
    setCollectionDesc(() => event.target.value);
  };

  const handleNameChange = (event) => {
    console.log(event.target.value);
    setCollectionName(() => event.target.value);
  };

  // get all collections
  const getCollections = useCallback(async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      console.log("Get all collections response data: ", response.collection);

      setAllCollection(response.collection);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  }, []);

  const handleAddInfluencers = async () => {
    const data = {
      collection_id: createdBy,
      influencer_id: influencerIds,
    };

    console.log("handle add influencer log: ", data);

    try {
      const res = await updateInfluencerCollection(data);
      console.log("update influencer collection: ", res);
      setIsSuccessful(true);
      setIsAddedSuccessful(true);
    } catch (error) {
      console.error("Error updating influencer collection: ", error);
    }
  };

  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const closeAddToCollection = () => {
    setShowCollection(false);
    setIsAdded(false);
    setIsAddedSuccessful(false);
  };

  const handleAddToCollectionDialogOpen = () => {
    // Open the dialog
    setShowCollection(true);

    // Call the API function when the dialog is opened
    getCollections();
  };

  const getACollectionById = (collectionId) => {
    const selectedCollection = allCollection?.find(
      (collection) => collection.id === collectionId
    );
    return selectedCollection;
  };

  const detailedCollection = async (id) => {
    const data = getACollectionById(id);

    console.log("Get collection by id data: ", data);

    setaCollection(() => data);

    let created_by_id = data?.id;
    console.log("detailed collection - created by : ", created_by_id);

    setSelectedItemId(() => created_by_id);

    // getCreatedBy();
    const res = await getCollectionById(created_by_id);
    console.log("get collection by ID: ", res);
  };

  return (
    <Box container padding={2} paddingX={6} width={"100%"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 2,
          gap: 2,
          width: "100%",
        }}
      >
        {/* collections dialog box  */}
        <Dialog
          open={showCollection}
          onClose={() => closeAddToCollection()}
          style={{ borderRadius: 20 }}
          maxWidth="md"
        >
          <Box container>
            <Box
              item
              md={12}
              px={3}
              pt={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                Collection
              </Typography>
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => closeAddToCollection()}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Box>

            <Box display={"flex"} item md={12}>
              <Box
                item
                md={6}
                px={3}
                pb={3}
                display={"flex"}
                flexDirection={"column"}
                justifyContent="space-between"
                style={{ background: "white" }}
              >
                <Box container>
                  <Box item md={12}>
                    <TextField
                      size="small"
                      placeholder="Search here..."
                      sx={{
                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                        background: "#FFFFFF",
                        marginTop: 2,
                        marginBottom: 5,
                        boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 8, // Adjust the value as needed for your desired border radius
                          paddingRight: 0.4,
                          "&:hover fieldset": {
                            borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        orientation="vertical"
                        value={view}
                        exclusive
                        onChange={handleChange}
                        size="small"
                        sx={{
                          border: "none",
                          maxHeight: "280px",
                          overflowY: "scroll",
                          scrollbarWidth: "thin", // For Firefox
                          scrollbarColor: "transparent transparent", // For Firefox
                          msOverflowStyle: "none", // For Internet Explorer and Microsoft Edge
                        }}
                      >
                        {allCollection?.map(({ name, id }, index) => (
                          <ToggleButton
                            key={index}
                            value={id}
                            aria-label="list"
                            sx={{
                              border: "none",
                              textTransform: "none",
                              fontSize: "1rem",
                              fontWeight: "bold",
                            }}
                            onClick={() => detailedCollection(id)}
                            // onClick={() => {
                            //   handleItemClick(index);
                            // }}
                          >
                            {name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </Box>
                <Box item md={12} p={2} sx={{ marginTop: "auto" }}>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    style={{
                      background: "rgba(61, 190, 114, 0.3)",
                      borderRadius: 20,
                      textTransform: "none",
                      boxShadow: "none",
                      padding: "5px 20px",
                      border: "2px solid rgba(61, 190, 114, 1)",
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                    onClick={openCreateCollectionDialog}
                  >
                    Create new Collection
                  </Button>
                </Box>
              </Box>

              <Box item md={6} display={"flex"}>
                <Box
                  container
                  px={3}
                  pb={3}
                  sx={{
                    borderLeft: "3px solid black",
                    height: "90%",
                    marginRight: 1,
                    marginY: "auto",
                  }}
                >
                  <Box item md={12} display="flex" alignItems={"center"}>
                    <AvatarGroup
                      max={4}
                      total={selectedRowArr?.length}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* {selectedRowArr?.map((data) => (
                <Avatar
                  sx={{ width: 80, height: 80 }}
                  alt={data.name}
                  src={data.photo_link}
                />
              ))} */}
                      <Avatar
                        sx={{ width: 60, height: 60 }}
                        alt="Travis Howard"
                        src={TemptDP}
                      />
                      <Avatar
                        sx={{ width: 50, height: 50 }}
                        alt="Agnes Walker"
                        src={TemptDP}
                      />
                      <Avatar
                        sx={{ width: 50, height: 50 }}
                        alt="Trevor Henderson"
                        src={TemptDP}
                      />
                    </AvatarGroup>
                    <Box ml={2}>
                      <Typography fontSize="1.5rem" fontWeight="bold">
                        {aCollection?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box item md={12} mt={2}>
                    {aCollection?.topics?.map((topic) => {
                      return (
                        <Button
                          style={{
                            textTransform: "none",
                            // color: "#3DBE72",
                            color: "#424242",
                            borderColor: "#3DBE72",
                            marginRight: 10,
                            background: "rgba(61, 190, 114, 0.3)",
                          }}
                          size="small"
                          variant="contained"
                        >
                          {topic}
                        </Button>
                      );
                    })}
                  </Box>

                  <Box item md={12} mt={3}>
                    <Typography
                      fontSize={"1.3rem"}
                      mb={1}
                      sx={{ fontWeight: "bold" }}
                    >
                      Assigned to Collection
                    </Typography>
                    <Typography>{aCollection?.campaign?.name}</Typography>
                  </Box>

                  <Box item md={12} mt={3}>
                    <Typography
                      fontSize={"1.3rem"}
                      mb={1}
                      sx={{ fontWeight: "bold" }}
                    >
                      Created by
                    </Typography>
                    <Typography>
                      {createdBy ? createdBy.name : "not found"}
                    </Typography>
                  </Box>

                  <Box item md={12} mt={3}>
                    <Typography
                      fontSize={"1.3rem"}
                      mb={1}
                      sx={{ fontWeight: "bold" }}
                    >
                      Bio
                    </Typography>
                    <Typography>
                      {aCollection.description}
                      {/* Live life on your own tems #jointhemvmt Watches, Eyewear & Premium Accessories For her @mvmforher Shop our Site */}
                    </Typography>
                    <span>
                      #Live #life #jointhemvmt #Watches #Eyewear #Premium
                      #Accessories
                    </span>
                  </Box>
                  {isAddedSuccessful ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      mt={4}
                    >
                      <Typography
                        sx={{
                          fontWeight: "semibold",
                          fontSize: "1rem",
                          color: "#3dbe72ff",
                        }}
                      >
                        Collection Successfully Added.
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {/* <Button
                  size="small"
                  variant="contained"
                  sx={{
                    bgcolor: "#3dbe72ff",
                    // color : '#3dbe72ff',
                    fontWeight: "bold",
                    // border: '1px solid black',
                    boxShadow: "none",
                    textTransform: "none",
                    border: "1px solid #3dbe72ff",
                    marginRight: 1,
                    "&:hover": {
                      bgcolor: "#3dbe72ff",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  onClick={() => handleClose()}
                >
                  Close
                </Button> */}

                        <Link
                          to={`/dashboard/collection/${encodedNewCollectionId}`}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{
                              // bgcolor: '#3dbe72ff',
                              color: "#3dbe72ff",
                              fontWeight: "bold",
                              borderColor: "#3dbe72ff",
                              boxShadow: "none",
                              textTransform: "none",
                              marginRight: 1,
                              transition: ".4s",
                              "&:hover": {
                                bgcolor: "#3dbe72ff",
                                color: "white",
                                borderColor: "black",
                              },
                            }}
                          >
                            Go to Collection
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  ) : (
                    <Box item md={12} align="end" pt={3} p={3}>
                      <Button
                        size="medium"
                        variant="contained"
                        style={{
                          background: "#3DBE72",
                          borderRadius: 20,
                          textTransform: "none",
                          boxShadow: "none",
                          padding: "5px 20px",
                        }}
                        onClick={() => handleAddInfluencers()}
                      >
                        Add to Collection
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Dialog>

        {/* Create Collection Diaglog  */}
        <Dialog
          open={createCollectionDialog}
          onClose={handleClose}
          // 👇 Props passed to Paper (modal content)
          PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
        >
          {isSucessful ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 3,
              }}
            >
              <AddTaskOutlined
                sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }}
              />

              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  color: "#3dbe72ff",
                  marginTop: 2,
                }}
              >
                Collection Successfully Created.
              </Typography>

              <Box
                mt={4}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    bgcolor: "#3dbe72ff",
                    // color : '#3dbe72ff',
                    fontWeight: "bold",
                    // border: '1px solid black',
                    boxShadow: "none",
                    textTransform: "none",
                    border: "1px solid #3dbe72ff",
                    marginRight: 1,
                    "&:hover": {
                      bgcolor: "#3dbe72ff",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  onClick={() => handleClose()}
                >
                  Close
                </Button>

                <Link to={`/dashboard/collection/${encodedNewCollectionId}`}>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      // bgcolor: '#3dbe72ff',
                      color: "#3dbe72ff",
                      fontWeight: "bold",
                      borderColor: "#3dbe72ff",
                      boxShadow: "none",
                      textTransform: "none",
                      marginRight: 1,
                      transition: ".4s",
                      "&:hover": {
                        bgcolor: "#3dbe72ff",
                        color: "white",
                        borderColor: "black",
                      },
                    }}
                  >
                    Go to Collection
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : (
            <Grid container p={4}>
              <Grid
                item
                size={{ md: 11, xs: 11 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#4caf50",
                  }}
                >
                  Create new Collection
                </Typography>
                {error.status && (
                  <Box>
                    <p style={{ color: "#e81e61ff", fontSize: 13 }}>
                      {error.message}
                    </p>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                size={{ md: 1, xs: 1 }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Tooltip title="Close" arrow placement="left">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setCreateCollectionDialog(false);
                      handleClose();
                    }}
                  >
                    <CancelOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item size={12}>
                <form onFocus={() => getBrands()} onSubmit={handleSubmit}>
                  <Box mt={2}>
                    <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                    <Box>
                      <MyInput
                        type="text"
                        name="name"
                        id="collection"
                        placeholder="Enter the Name of collection"
                        onChange={(e) => handleNameChange(e)}
                        required
                        onFocus={() => setIsBrandFocus(false)}
                        value={collectionName}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Mylabel htmlFor="brand">Brand Name</Mylabel>
                    <Box position={"relative"}>
                      <MyInput
                        type="text"
                        name="brand_name"
                        id="brand"
                        placeholder="search the brand "
                        required
                        onChange={(e) => handleBrandChange(e)}
                        onFocus={() => {
                          setIsBrandFocus(true);
                        }}
                        // onFocusCapture={() => setIsBrandFocus(true)}
                        // onBlur={() => setIsBrandFocus(false)}
                        value={brandName}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={openAddBrandDialog}
                      >
                        <Tooltip title="Add a new Brand" arrow>
                          <Add />
                        </Tooltip>
                      </IconButton>
                      {isBrandFocus && (
                        <Box
                          sx={{
                            padding: "5px 10px",
                            position: "absolute",
                            width: "100%",
                            background: "white",
                            marginTop: 1,
                            borderRadius: 2,
                            maxHeight: "140px",
                            overflowY: "scroll",
                            zIndex: 10,
                          }}
                        >
                          {allBrands?.reverse()?.map(({ name, id }) => {
                            return (
                              <ListItem
                                key={id}
                                component="div"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleListItemClick(name, id);
                                  setIsBrandFocus(false);
                                }}
                              >
                                {name}
                              </ListItem>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Mylabel htmlFor="description">Description</Mylabel>
                    <Box>
                      <MyTextArea
                        rows={3}
                        type="text"
                        name="description"
                        id="description"
                        placeholder="Write short description"
                        onChange={(e) => handleDescChange(e)}
                        required
                        // onFocus={() => {
                        //   setIsBrandFocus(false);
                        // }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        textTransform: "none",
                        borderRadius: 10,
                        fontWeight: "bold",
                        marginRight: 1,
                        background: "#c5edd6ff",
                        borderWidth: "2px",
                        color: "black",
                        borderColor: "#3dbf73ff",
                        "&:hover": {
                          borderWidth: "2px",
                          borderColor: "#3dbf73ff",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      sx={{
                        bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                        boxShadow: "none",
                        fontWeight: "bold",
                        borderRadius: 10,
                        // padding: '10px 30px',
                        "&:hover": {
                          bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                        },
                      }}
                      endIcon={
                        loading ? (
                          <LoadingSpinner size={25} />
                        ) : isSucessful ? (
                          <AddTaskOutlined />
                        ) : (
                          error.status && <WarningAmberOutlined />
                        )
                      }
                      disabled={loading || isSucessful}
                    >
                      {isSucessful ? "Created" : "Create Collection"}
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          )}
        </Dialog>
        {/* <TextField
          size="small"
          outline="false"
          placeholder="Search Influencers..."
          value={searchTerm} // Bind the searchTerm state to the TextField
          onChange={handleSearchChange} // Handle changes in the TextField
          sx={{
            width: "50%",
            borderRadius: 8,
            borderColor: "black",
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
        {/* <Button
          sx={{
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            textTransform: "none",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "2px",
            width: "40%",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
        // onClick={handleOpen}
        >
          Add to campaign
        </Button> */}
        <Button
          sx={{
            paddingX: 8,
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "1px",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
          onClick={handleAddToCollectionDialogOpen}
        >
          Add to collection
        </Button>
        <Button
          variant="contained"
          sx={{
            paddingX: 4,
            background: "#1f2933ff",
            fontWeight: "bold",
            borderRadius: 8,
            boxShadow: "none",
            "&:hover": {
              background: "#1f2933ff",
            },
          }}
        >
          Edit
        </Button>
        <IconButton sx={{ color: "#3dbf73ff" }}>
          <ShareRounded
            onClick={handleClickOpen}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: { borderRadius: 5, border: "2px solid black" },
          }}
        >
          <ShareInfluencers sharePopUp={setOpen} clickedData={user} />
        </Dialog>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          width: "100%",
          flexDirection: {
            md: "row",
            xs: "column",
          },
        }}
      >
        <Box
          paddingX={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {user?.photo ? (
            <Avatar
              src={user?.photo}
              alt={user?.name}
              sx={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                border: 3,
                borderColor: "#3DBE72",
              }}
            />
          ) : (
            <Avatar
              src={user?.photo_link}
              alt={user?.name}
              sx={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                border: 3,
                borderColor: "#3DBE72",
              }}
            />
          )}
        </Box>
        <Box sx={{ padding: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                }}
              >
                {user?.name}
              </Typography>
              {user?.platform_verified && <VerifiedIcon color={"primary"} />}
              {user?.verified && <VerifiedIcon color={"primary"} />}
            </Box>
            <Box>
              <IconButton
                href={`https://www.youtube.com/channel/${user?.channel_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#FF0000" }}
              >
                <YouTube
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.3rem",
                color: "#3dbf73ff",
                fontWeight: "bold",
              }}
            >
              {user?.handle ? user.handle : ""}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Subscribers: </b>{" "}
              {user?.subscriber ? formatNumber(user.subscriber)?.trim() : 0}
              {/* Followers
                  Location
                  Account type of the profile, such as Personal, Creator or Business
                  Gender of the profile
                  Bio
                  Content Categories
                  Hashtags */}
            </Typography>
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Location:</b>{" "}
              {user
                ? user.city && user.state
                  ? user.city + " , " + user.state
                  : user.city
                  ? user.city
                  : user.state
                  ? user.state
                  : user.country &&
                    user.country ===
                      "Country not found for the given shortcode."
                  ? "N/A"
                  : user.country
                : "N/A"}
            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Account: {user.AccountType ? user.AccountType : "Account"}
            </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Gender:</b> {user?.gender ? user.gender?.trim() : ""}
            </Typography>
            {/* <Typography
                    sx={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    {user.Bio ? user.Bio : "Bio"}
                  </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Category:</b>{" "}
              {user.influencer_category === null
                ? JSON.parse(user?.topics?.replace(/'/g, '"'))[0]?.trim()
                : user.influencer_category}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Tags:</b>{" "}
              {user?.Hashtags?.split(", ")?.length > 0
                ? user?.Hashtags?.split(", ").join(", ")
                : ""}
              <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                {user?.keywords
                  ? JSON.parse(user?.keywords?.replace(/'/g, '"')).map(
                      (data) => {
                        return data + ", ";
                      }
                    )
                  : ""}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 3,

          marginTop: 4,
        }}
      >
        <Typography>
          {user?.description ? removeEmailsAndPhones(user.description) : "N/A"}
        </Typography>

        <Typography sx={{ fontWeight: "bold" }}>
          {user?.["List of top hashtags used by the creator"]
            ? user["List of top hashtags used by the creator"]
            : ""}
        </Typography>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 5,
          marginTop: 10,
          mx: "auto",
          overflow: "hidden",
          maxWidth: IsOpen ? "92vw" : "78vw",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {!IsOpen ? (
          <ProfileCarousel
            value={
              screenWidth > 1442
                ? 5
                : screenWidth > 1024
                ? 5
                : screenWidth > 768
                ? 3
                : 1
            }
          />
        ) : (
          <ProfileCarousel value={5} />
        )}
      </Box> */}
      <Grid item size={12}>
        <BasicTabs userdata={user} />
      </Grid>
    </Box>
  );
};

export default Profile;
