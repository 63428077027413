import React, { memo, useEffect, useMemo, useState } from "react";
import { Box, Button, Popover, InputLabel, FormControl } from "@mui/material";
import CustomMultiSelectWithTextfield from "../customInputComponents/multiSelectWithTextfield";

const MultiSelectAddFrontFilter = memo(
  ({
    title,
    label,
    placeholder,
    setDataMethod,
    data,
    clearIt,
    predefinedOptions = [],
  }) => {
    console.log("multiselect data: ", predefinedOptions);
    predefinedOptions = predefinedOptions.map((option) => ({
      label: option,
      value: option,
    }));

    console.log("multiselect data: ", predefinedOptions);

    const [selectedValues, setSelectedValues] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    useMemo(() => {
      if (data) {
        const updatedData = data.map((option) => ({
          label: option,
          value: option,
        }));
        setSelectedValues(updatedData);
      }
    }, [data]);

    const handleChange = (newValues) => {
      setSelectedValues(newValues);
    };

    useEffect(() => {
      if (selectedValues.length > 0) {
        setDataMethod(selectedValues.map((option) => option.value));
      } else {
        setDataMethod(null);
      }
    }, [selectedValues]);

    const clearMultiSelect = () => {
      setSelectedValues([]);
    };

    useEffect(() => {
      clearIt(() => clearMultiSelect);
    }, [clearIt]);

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "multi-select-popover" : undefined;

    return (
      <FormControl sx={{ m: 1, minWidth: 80, width: 120 }} size="small">
        <Button
          aria-describedby={id}
          onClick={handleOpen}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 20,
            paddingLeft: 2,
            border: "1px solid lightgrey",
            textTransform: "capitalize",
          }}
        >
          <InputLabel id="multi-select-label">{title}</InputLabel>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              width: 150,
              minWidth: 80,
            }}
          >
            <CustomMultiSelectWithTextfield
              options={predefinedOptions}
              value={selectedValues}
              onChange={handleChange}
              label={label}
              placeholder={placeholder}
              error={false}
              helperText=""
              required
              fullWidth
              size="small"
            />
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={clearMultiSelect}
            >
              Clear
            </Button>
          </Box>
        </Popover>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.setDataMethod === nextProps.setDataMethod &&
      prevProps.clearIt === nextProps.clearIt
    );
  }
);

export default MultiSelectAddFrontFilter;
