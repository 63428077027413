import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Card3 from "./Card3";
import "./CustomCarousel1.module.css";
import { Typography } from "@mui/material";

const CustomCarousel1 = ({ value, cards }) => {
  const sliderRef = React.useRef(null);

  console.log("card data: ", cards);

  // Slick settings
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: value,
    slidesToScroll: 4,
    arrows: false,
    initialSlide: 0,
    centerMode: false, // Disable centerMode to reduce extra padding
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ArrowBackIosIcon
          onClick={handlePrev}
          sx={{
            cursor: "pointer",
            position: "absolute",
            left: "0px",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            padding: "10px 30px",
            margin: 2,
            width: { lg: "500px", xs: "100%" },
          }}
        >
          {Array.isArray(cards) && cards.length > 0 ? (
            <Slider ref={sliderRef} {...settings}>
              {cards.map((cardData, i) => (
                <Box key={i} sx={{ padding: "0" /* Add slight gap */ }}>
                  <Card3
                    views={cardData.views}
                    likes={cardData.likes}
                    comments={cardData.comments}
                    date={cardData.date}
                    link={cardData.link}
                    media_id={cardData.media_id}
                    title={cardData.title}
                    duration={cardData.duration}
                  />
                </Box>
              ))}
            </Slider>
          ) : (
            <Typography> Not Found Any</Typography>
          )}
        </Box>
        <ArrowForwardIosIcon
          onClick={handleNext}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: "0px",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomCarousel1;
